import React, { useEffect, useState } from 'react'
import { getPartnerGuid } from '../../../../../lib/access.es6';
import AlertDialog from '../../../../../components/AlertDialog/index.es6';
import { CircularProgress } from '@material-ui/core';
import { Button } from '@mui/material';
import { find } from 'lodash';
import SelectPlan from './SelectPlan/index';
import SelectCoverType from './SelectCoverType';
import SelectRelationship from './SelectRelationship';
import NavButtons from './NavButtons';
import AddBenefits from './AddBenefits';
import ViewMembers from './ViewMembers';
import Adddependant from './AddDependant';
import { COMPONENT_TYPES, getClanPremiumRate } from '../../helpers';

const Createquote = (props) => {

  const [currentComponent, setCurrentComponent] = useState(COMPONENT_TYPES.SELECT_PLAN);
  const [coverType, setCoverType] = useState(null);
  const [selectedPlan, setPlan] = useState(null);
  const [parentsPlan, setParentsPlan] = useState(null);
  const [hasParentsPlan, setHasParentsPlan] = useState(null)

  const [currentRelationShip, setCurrentRelationShip] = useState(null)
  const [lastSelectedExtendedRelationship, setLastSelectedExtendedRelationship] = useState(null)
  const [memberRelationshipType, setMemberRelationshipType] = useState(null)

  const [openAddBenefitsDialog, setOpenAddBenefitsDialog] = useState(null)
  const [selectedExtendedBenefit, setSelectedExtendedBenefit] = useState(null)

  const [suspendDuplicateIdCheck, setSuspendDuplicateIdCheck] = useState(false)


  const [togglequoteanddependant, settogglequoteanddependant] = useState(true)
  const [dependants, setDependants] = useState([])
  const [depAlert, setDepAlert] = useState(false);

  const [additionalBenefits, setAdditionalBenefits] = useState({})

  useEffect(() => {



    if (props.quoteToReCreate) {
      console.log('props.quoteToReCreate: ', props.quoteToReCreate);
      const policyEnteries = props.quoteToReCreate.quotation.policy_schedule_entries

      const tempDep = policyEnteries.map(entry => {
        return {
          ...entry.dependant,
          benefits: entry.benefits.map(benefit => {
            return {
              ...benefit,
              uuid: benefit.additional_details.uuid,
              premium_amount: benefit.additional_details.premium_amount
            }
          }),
          premium_amount_in_cents: entry.premium_amount_in_cents,
          uuid: entry.dependant.additional_details.uuid,
          type: entry.dependant.additional_details.memberType,
        }
      })

      const plan1 = find(props.products, { code: props.quoteToReCreate.quotation.additional_details.primary_plan })
      const plan2 = find(props.products, { code: props.quoteToReCreate.quotation.additional_details.secondary_plan })
      const quoteCoverType = props.quoteToReCreate.quotation.additional_details.cover_type


      setPlan(plan1)

      setParentsPlan(plan2)
      setCoverType(quoteCoverType)
      setSelectedExtendedBenefit()

      console.log(tempDep);
      console.log(policyEnteries);
      setDependants(tempDep)
      setCurrentComponent(COMPONENT_TYPES.VIEW_MEMBERS)
      setSuspendDuplicateIdCheck(true)
    }
  }, [props.quoteToReCreate])


  const handleAdddependant = () => {
    settogglequoteanddependant(!togglequoteanddependant)
  }


  const removeDependant = (uuid) => {
    const dependantToRemove = find(dependants, { uuid: uuid })
    const updatedDependants = dependants.filter(item => item.uuid !== dependantToRemove.uuid);
    setDependants(updatedDependants);
  };



  const handleCreateQuote = () => {
    const totalMembers = dependants.length

    const coverAmount = selectedPlan.cover_amount

    const premiumRate = getClanPremiumRate(coverAmount, totalMembers) * 100

    const dependant2 = []

    for (let d of dependants) {
      d.premium_amount_in_cents = 0

      if (d.relationship === 'Principal') {
        d.premium_amount_in_cents = premiumRate
        const benefits = d.benefits;
        for (let b of benefits) {
          if (b.type === 'CLAN BENEFIT') {
            b.premium_amount = premiumRate
          }
        }
      }
      dependant2.push(d)
    }

    const payload = {
      "product_guid": 'Family Clan Cover',
      "product_name": props.productType,
      "product_code": selectedPlan ? selectedPlan.option : 'n/a',
      "branch": "string",
      "customer_guid": props.customer_guid,
      "partner": getPartnerGuid(),
      "lapse_period": 3,
      "premium_granularity": "month",
      "premium_cardinality": 1,
      "policy_schedule_entries": [
        ...dependant2
      ],
      additional_details: {


      }
    }
    props.createQuote(payload)
  }
  const handleQuoteCreatedSuccessfully = () => {
    props.resetAddQuoteSuccessAlert();
  }

  const handleQuoteCreateError = () => {
    props.resetAddQuoteErrorAlert();
  }

  const handlenextStep = () => {
    props.handleNext();
  }




  return (
    <>

      {currentComponent === COMPONENT_TYPES.SELECT_COVER_TYPE &&
        <SelectCoverType
          coverType={coverType}
          setCoverType={setCoverType}

        />
      }

      {currentComponent === COMPONENT_TYPES.SELECT_PLAN &&
        <SelectPlan
          coverType={coverType}
          hasParentsPlan={hasParentsPlan}
          plans={props.products}
          selectedPlan={selectedPlan}
          setParentsPlan={setParentsPlan}
          setPlan={setPlan}
          parentsPlan={parentsPlan}
          setCurrentComponent={setCurrentComponent}
          customerGuid={props.principal.guid}
        />
      }

      {currentComponent === COMPONENT_TYPES.SELECT_RELATIONSHIP &&
        <SelectRelationship
          hasParentsPlan={hasParentsPlan}
          setHasParentsPlan={setHasParentsPlan}
          selectedPlan={selectedPlan}
          parentsPlan={parentsPlan}
          currentRelationShip={currentRelationShip}
          setCurrentRelationShip={setCurrentRelationShip}
          dependants={dependants}
          setCurrentComponent={setCurrentComponent}
          COMPONENT_TYPES={COMPONENT_TYPES}
          viewMembers={() => setCurrentComponent(COMPONENT_TYPES.VIEW_MEMBERS)}
          coverType={coverType}
          lastSelectedExtendedRelationship={lastSelectedExtendedRelationship}
          setLastSelectedExtendedRelationship={setLastSelectedExtendedRelationship}
          memberRelationshipType={memberRelationshipType}
        />
      }

      {currentComponent === COMPONENT_TYPES.ADD_DEPENDANT &&
        <Adddependant
          coverType={coverType}
          agentUsersData={props.agentUsersData}
          dependant={props.dependant}
          handleCreateQuote={handleCreateQuote}
          handleAdddependant={handleAdddependant}
          createQuote={props.createQuote}
          setDependants={setDependants}
          dependants={dependants}
          createDependant={props.createDependant}
          viewMembers={() => setCurrentComponent(COMPONENT_TYPES.VIEW_MEMBERS)}

          setLastSelectedExtendedRelationship={setLastSelectedExtendedRelationship}

          currencyCode={'P'}
          removeDependant={removeDependant}
          depAlert={depAlert}
          setDepAlert={setDepAlert}
          principal={props.principal}
          autoFillPrincipal={props.autoFillPrincipal}
          toggleAutoFillPrincipal={props.toggleAutoFillPrincipal}
          selectedPlan={selectedPlan}
          parentsPlan={parentsPlan}
          //  relationships={relationships}
          currentRelationShip={currentRelationShip}
          selectedExtendedBenefit={selectedExtendedBenefit}
          setSelectedExtendedBenefit={setSelectedExtendedBenefit}

          memberRelationshipType={memberRelationshipType}
          suspendDuplicateIdCheck={suspendDuplicateIdCheck}

        />
      }

      {currentComponent === COMPONENT_TYPES.VIEW_MEMBERS &&
        <ViewMembers
          currencyCode={'P'}
          removeDependant={removeDependant}
          dependants={dependants}
          addAnotherMember={(relationshipType) => {
            setMemberRelationshipType(relationshipType)
            setCurrentComponent(COMPONENT_TYPES.ADD_DEPENDANT)
            setCurrentRelationShip(null)
          }}
          setOpenAddBenefitsDialog={setOpenAddBenefitsDialog}
          coverType={coverType}
          additionalBenefits={additionalBenefits}
          selectedPlan={selectedPlan}
          parentsPlan={parentsPlan}
          handleCreateQuote={handleCreateQuote}
        />
      }

      <AddBenefits
        currencyCode={'P'}
        setOpenAddBenefitsDialog={setOpenAddBenefitsDialog}
        openAddBenefitsDialog={openAddBenefitsDialog}
        selectedPlan={selectedPlan}
        parentsPlan={parentsPlan}
        dependants={dependants}
        setDependants={setDependants}
        additionalBenefits={additionalBenefits}
        setAdditionalBenefits={setAdditionalBenefits}
      />



      <NavButtons
        currentComponent={currentComponent}
        setCurrentComponent={setCurrentComponent}
        coverType={coverType}
        selectedPlan={selectedPlan}
        setPlan={setPlan}
        hasParentsPlan={hasParentsPlan}
        parentsPlan={parentsPlan}
        setParentsPlan={setParentsPlan}
        setProductType={props.setProductType}
      />


      <AlertDialog
        custom
        show={props.showAddQuoteProgressAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2> Creating Quote...</h2>
      </AlertDialog>

      <AlertDialog
        success
        show={props.showAddQuoteSuccessAlert}
        size="sm"
        title={'Quote  created'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handleQuoteCreatedSuccessfully() }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        confirmBtnCssClass
      >
        <div >
          <Button variant="contained" onClick={() => { handlenextStep() }} style={{ background: 'green', }}>View Quote Details</Button>
        </div>
      </AlertDialog>

      <AlertDialog
        show={props.showAddQuoteErrorAlert}
        danger
        title={'Error creating Quote'}
        onConfirm={() => handleQuoteCreateError()}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >

        {
          props.createQuoteError ? props.createQuoteError.message : ""
        }

      </AlertDialog>

    </>
  )
}

export default Createquote


