import React from "react";
import { connect } from "react-redux";
import LocalizedStrings from 'react-localization';
import PolicyHeader from './Components/PolicyHeader/PolicyHeader.js'
import NavigationWrapper from "./Components/NavigationWrapper/NavigationWrapper";
import { getPolicyProcessingDcpRequest, getPolicyAuditsDcpRequest, getPaymentsByPolicyGuidRequest } from "./Redux/actions";
import { getAllProductsRequest } from "../../../containers/CustomerScreen/actions.es6.js";
import { getCustomer } from "../../../containers/Customer/actions.es6.js";
import { localisedText } from "../../../lib/localisation.es6.js";

import { getAllPoliciesRequest } from "../CustomerStatus/Redux/actions.js"
import { getNGSGroupCustomerClaimsRequest } from "../CustomerStatus/Container/Claims/redux/actions.js";
class PoliciesProcessing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openRejectPolicyDialog: false,
      labels: new LocalizedStrings(localisedText),
      openCancelPolicyDialog: false,
      openPolicyNoteDialog: false,
      openPolicyDocumentDialog: false,
      openAddPaymentDialog: false,
      openAddDebitOrderDialog: false,
      openEditDebitOrderDialog: false,
      updateAction: null,
    };
  }

  componentDidMount() {
    const searchStr = '-processing/';
    const hashIndex = this.props.location.pathname.indexOf(searchStr) + searchStr.length;
    
    const policy_number = this.props.location.pathname.substring(hashIndex);
    const encodedPolicyNumber = encodeURIComponent(policy_number)

    this.props.dispatch(getPolicyProcessingDcpRequest({
      policy_number: encodedPolicyNumber,
    }));

  }

  componentWillMount() {
    this.props.dispatch(getAllProductsRequest())
  }


  getStatus() {
    const { updateAction } = this.state

    const updateActions = {
      Approve: 'Approve',
      Discard: 'Discard',
      ReConfirm: 'ReConfirm',
    }

    let status = "";

    switch (updateAction) {
      case updateActions.Approve:
        status = "Unpaid"
        break;

      case updateActions.Discard:
        status = "Discarded"
        break;

      case updateActions.ReConfirm:
        status = "Un_Confirmed"
        break;

      default:
        break;
    }

    return status

  }



  getAllPayments() {
    if (this.props.PolicyProcessingData.policyProcessingDcp) {

      const policyGuid = this.props.PolicyProcessingData.policyProcessingDcp.guid
      const policy_number = this.props.PolicyProcessingData.policyProcessingDcp.policy_number

      this.props.dispatch(getPaymentsByPolicyGuidRequest({ guid: policyGuid, policy_number: policy_number }));

    }
  }




  getAllPolicyAudits() {
    if (this.props.PolicyProcessingData.policyProcessingDcp) {

      const policy_guid = this.props.PolicyProcessingData.policyProcessingDcp.guid

      this.props.dispatch(getPolicyAuditsDcpRequest(policy_guid));

    }
  }





  getCustomerDetails() {
    const customer_guid = this.props.PolicyProcessingData.policyProcessingDcp.customer_guid;
    if (customer_guid) {
      this.props.dispatch(getCustomer(customer_guid))
    }
  }

  getClaims() {
    const currentCustomer = this.props.customerData.currentCustomer;
    if (currentCustomer) {
     this.props.dispatch(getNGSGroupCustomerClaimsRequest({ guid: currentCustomer.guid }));
    }
  }




  getCustomerPolicies() {
    const currentCustomer = this.props.customerData.currentCustomer;
    if (currentCustomer) {
      this.props.dispatch(getAllPoliciesRequest({ guid: currentCustomer.guid }))
    }
  }






  render() {

    if (this.props.PolicyProcessingData.policyProcessingDcp) {
      return (
        <>
          <PolicyHeader
          />



          <section style={{
            background: "#FFFFFF",
            borderRadius: "8px",
            height: "auto",
            marginTop: "30px",
            padding: "30px"
          }}>
            <NavigationWrapper
              policy={this.props.PolicyProcessingData.policyProcessingDcp}
              getAllPayments={this.getAllPayments.bind(this)}
              loader={this.props.PolicyProcessingData.get_PaymentsByPolicyGuid_loader}
              getCustomerDetails={this.getCustomerDetails.bind(this)}
              customer={this.props.customerData}



              getAllPolicyAudits={this.getAllPolicyAudits.bind(this)}
              audits={this.props.PolicyProcessingData.policyAuditsDcp}
              auditsLoader={this.props.PolicyProcessingData.get_PolicyAuditsDcp_loader}


              policyGuid={this.props.PolicyProcessingData.policyProcessingDcp.guid}


              getClaims={this.getClaims.bind(this)}
              claims={this.props.NGSGroupClaimsData.claims}
              claimsloader={this.props.NGSGroupClaimsData.get_claims_loader}


              policyActiveState={this.props.PolicyProcessingData.policyProcessingDcp.active}

              getCustomerPolicies={this.getCustomerPolicies.bind(this)}



            />
          </section>
        </>
      )
    } else {
      return "loading"
    }


  }

}
export default connect((state) => ({
  customerData: state.currentCustomer,
  NGSGroupClaimsData: state.NGSGroupClaimsData,
  PoliciesDashboardData: state.PoliciesDashboardData,
  PolicyProcessingData: state.PolicyProcessingData,
  customerScreenData: state.customerScreen,
  QuoteData: state.quoteData,
  globalData: state.global,
}))(PoliciesProcessing);
