import React from "react";
import { connect } from "react-redux";
import LocalizedStrings from 'react-localization';
import PolicyHeader from './Components/PolicyHeader/PolicyHeader.js'
import NavigationWrapper from "./Components/NavigationWrapper/NavigationWrapper";
import { getPolicyProcessingDcpRequest, getPolicyAuditsDcpRequest } from "./Redux/actions";
import { getCustomer } from "../../../containers/Customer/actions.es6.js";
import { localisedText } from "../../../lib/localisation.es6.js";
import { getAccessBankCustomerClaimsRequest } from "../CustomerStatus/Containers/Claims/redux/actions.js";
import { getAllPoliciesRequest } from "../CustomerStatus/Redux/actions.js"
class PoliciesProcessing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openRejectPolicyDialog: false,
      labels: new LocalizedStrings(localisedText),
      openCancelPolicyDialog: false,
      openPolicyNoteDialog: false,
      openPolicyDocumentDialog: false,
      openAddPaymentDialog: false,
      openAddDebitOrderDialog: false,
      updateAction: null,
    };
  }

  componentDidMount() {
    let policy_number = this.props.params.policy_number
    this.props.dispatch(getPolicyProcessingDcpRequest({
      policy_number: policy_number,
    }));

  }


  getAllPolicyAudits() {
    if (this.props.PolicyProcessingData.policyProcessingDcp) {

      const policy_guid = this.props.PolicyProcessingData.policyProcessingDcp.guid

      this.props.dispatch(getPolicyAuditsDcpRequest(policy_guid));

    }
  }

  getCustomerDetails() {
    const customer_guid = this.props.PolicyProcessingData.policyProcessingDcp.customer_guid;
    if (customer_guid) {
      this.props.dispatch(getCustomer(customer_guid))
    }
  }

  getClaims() {
    const currentCustomer = this.props.customerData.currentCustomer;
    if (currentCustomer) {
      this.props.dispatch(getAccessBankCustomerClaimsRequest({ guid: currentCustomer.guid }))
    }
  }

  getCustomerPolicies(){
    const currentCustomer = this.props.customerData.currentCustomer;
    if (currentCustomer) {
      this.props.dispatch(getAllPoliciesRequest({ guid: currentCustomer.guid }))
    }
  }

  render() {

    if (this.props.PolicyProcessingData.policyProcessingDcp) {
      return (
        <>
          <PolicyHeader
       
          />

          <section style={{
            background: "#FFFFFF",
            borderRadius: "8px",
            height: "auto",
            marginTop: "30px",
            padding: "30px"
          }}>
            <NavigationWrapper
              policy={this.props.PolicyProcessingData.policyProcessingDcp}
              getCustomerDetails={this.getCustomerDetails.bind(this)}
              customer={this.props.customerData}


              getAllPolicyAudits={this.getAllPolicyAudits.bind(this)}
              audits={this.props.PolicyProcessingData.policyAuditsDcp}
              auditsLoader={this.props.PolicyProcessingData.get_PolicyAuditsDcp_loader}

              policyGuid={this.props.PolicyProcessingData.policyProcessingDcp.guid}


              getClaims={this.getClaims.bind(this)}
              claims={this.props.AccessBankClaimsData.claims}
              claimsloader={this.props.AccessBankClaimsData.get_claims_loader}


              policyActiveState={this.props.PolicyProcessingData.policyProcessingDcp.active}

              getCustomerPolicies={this.getCustomerPolicies.bind(this)}

              customer_guid={this.props.PolicyProcessingData.policyProcessingDcp.customer_guid}

            />
          </section>
        </>
      )
    } else {
      return "loading..."
    }


  }

}
export default connect((state) => ({
  customerData: state.currentCustomer,
  AccessBankPremiumData: state.AccessBankPremiumData,
  AccessBankClaimsData: state.AccessBankClaimsData,
  documentGenerationData: state.documentGeneration,
  PoliciesDashboardData: state.PoliciesDashboardData,
  PolicyProcessingData: state.PolicyProcessingData,
  customerScreenData: state.customerScreen,
  newDebitOrderData: state.newDebitOrder,
  QuoteData: state.quoteData,
  globalData: state.global,
}))(PoliciesProcessing);
