import { makeStyles } from "@material-ui/styles";


export const useStyle = makeStyles(() => ({
  customerStatusWrapper: {
    background: "#FFFFFF",
    borderRadius: "8px",
    padding: "5px 10px 30px 10px",
    marginTop: "25px !important",
    height: "auto"
  },

  customerProfileData: {
    marginTop: "20px !important",
    "& h3": {
      fontFamily: 'Lato',
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "15px",
      color: "#000000",
      marginLeft: "20px"
    },
    "& p": {
      fontFamily: 'Open Sans',
      fontStyle: "normal",
      fontWeight: 100,
      fontSize: "14px",
      lineHeight: "16px",
      letterSpacing: "0.005em",
      color: " #060606",
      float: "right",
      marginRight: "20px",
      marginTop: "6px",
    }
  },
  customerProfileDataBorder: {
    borderBottom: "1px solid #ccc",
    height: "1px",
    width: "90%",
    margin: "auto"
  },
  showMoreBtn: {
    "& button": {
      background: "#FFFFFF",
      border: "1px solid #CCCCCC",
      borderRadius: "4px",
      fontFamily: 'Open Sans',
      fontStyle: "normal",
      fontWeight: 100,
      fontSize: "14px",
      lineHeight: "16px",
      letterSpacing: "0.005em",
      color: " #060606",
      margin: "20px auto",
      display: "flex",
      "&:hover":{
        border: "1px solid #CCCCCC",
      }
    }
  }

}));