import React from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

const PropertyClaimDetails = (props) => {
  const classes = useStyle();
  const [intrestRate, setIntrestRate] = React.useState('')


  React.useEffect(() => {
    props.setAdditionalState("monthly_installment_amount", props.eventData.additional_details.monthly_installment_amount)
    props.setAdditionalState("beneficiary_national_id", props.eventData.additional_details.beneficiary_national_id)
    props.setAdditionalState("loan_amount", props.eventData.additional_details.loan_amount)
    props.setAdditionalState("loan_disbursement_date", props.eventData.additional_details.loan_disbursement_date)
    props.setAdditionalState("loan_duration", props.eventData.additional_details.loan_duration)
    props.setAdditionalState("loan_maturity_date", props.eventData.additional_details.loan_maturity_date)
    props.setAdditionalState("loan_type", props.eventData.additional_details.loan_type)
    props.setAdditionalState("real_people_commission", props.eventData.additional_details.real_people_commission)
    props.setAdditionalState("loan_interest_rate", props.eventData.additional_details.loan_interest_rate)
    props.setAdditionalState("product_code", props.eventData.product_code)
  }, [props.member.date_of_birth]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "dateOfDamage") {
      handleSubmit(value)
    }

  }


  const handleSubmit = (value) => {

    const tempClaimInitiation = props.claimInitiationPayload;
    tempClaimInitiation["additional_details"] = {
      "device_number": props.eventData.additional_details.device_number
    };
    props.setClaimInitiationPayload(tempClaimInitiation);
  }


  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <div className={classes.headingclaim}>
            <h4> Property Owner Details</h4>
          </div>
        </Grid>
      </Grid>
      <ValidatorForm onSubmit={() => { }}>
        <Grid container spacing={2} className={classes.selectpolicyWrapper}>
          <Grid item xs={12} md={4} sm={4}>
            <TextValidator
              id="name"
              style={{ width: "100%" }}
              label="first_name"
              name='first_name'
              value={props.member.first_name}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} md={4} sm={4}>
            <TextValidator
              id="middle_name"
              style={{ width: "100%" }}
              label="middle_name"
              name='middle_name'
              value={props.member.middle_name}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} md={4} sm={4}>
            <TextValidator
              id="last_name"
              style={{ width: "100%" }}
              label="last_name"
              name='last_name'
              value={props.member.last_name}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} md={4} sm={4}>
            <TextValidator
              id="relationship"
              style={{ width: "100%" }}
              label="relationship"
              name='relationship'
              value={props.member.relationship}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} md={4} sm={4}>
            <TextValidator
              id="gender"
              style={{ width: "100%" }}
              label="gender"
              name='gender'
              value={props.member.gender}
              variant="outlined"
            />
          </Grid>



          <Grid item xs={12} md={4} sm={4}>
            <TextValidator
              id="date_of_birth"
              style={{ width: "100%" }}
              label="date_of_birth"
              name='date_of_birth'
              value={props.member.date_of_birth}
              variant="outlined"
            />
          </Grid>


        </Grid>

        <hr />
        <Grid container spacing={2} className={classes.selectpolicyWrapper}>


        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <div className={classes.headingclaim}>
            <h4>Intrest Rate </h4>
            </div>
          </Grid>
        </Grid>


        <Grid item xs={12} sm={6} md={6}>
          <TextValidator
            id="intrestRate"
            label="Intrest Rate"
            name='intrestRate'
            value={intrestRate}
            type="number"
            onChange={(e) => {
              setIntrestRate(e.target.value)
              props.setAdditionalState("monthly_interest_rate", e.target.value)
            }}
            style={{ width: "100%" }}
            fullWidth
            variant="outlined"
          />
        </Grid>
        </Grid>

      </ValidatorForm>
    </>
  )
}

export default PropertyClaimDetails

