import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import { canViewDashboard, hasAuthourity } from '../../lib/access.es6';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import SlideshowIcon from '@mui/icons-material/Slideshow';


export const BboxxKeBankMenu = (labels) => {

  return [
    {
      id: 'application',
      title: 'Home',
      icon: HomeOutlinedIcon,
      type: 'group',
      permisions: [],
      children: [
        {
          id: 'admin/home',
          title: labels.home,
          type: 'item',
          url: 'admin/home',
          icon: HomeOutlinedIcon,
          permisions: []
        },
        {
          id: 'admin/dashboards',
          title: labels.dashboard,
          type: 'item',
          url: 'admin/dashboards',
          icon: DashboardCustomizeOutlinedIcon,
          permisions: [canViewDashboard()]
        },
        // {
        //   id: 'admin/internal_dashboards',
        //   title: labels.internalDashboard,
        //   type: 'item',
        //   url: 'admin/internal_dashboards',
        //   icon: DashboardIcon,
        //   permisions: [hasAuthourity('INTERNAL_DASHBOARD')]
        // },
        {
          title: 'Bulk Onboard',
          label: 'Bulk Onboard',
          type: 'item',
          url: 'admin/bulk-policy-onboarding',
          id: 'admin/bulk-policy-onboarding',
          style: '20px',
          icon: UploadFileOutlinedIcon,
          permisions: [hasAuthourity('BULK_ONBOARD_CUSTOMERS')]
        },
        {
          id: 'Claims',
          title: 'Claims',
          type: 'collapse',
          icon: InventoryOutlinedIcon,
          permisions: [hasAuthourity('VIEW_CLAIMS')],
          children: [
            {
              id: 'admin/Death_claims',
              title: 'Death Claims',
              type: 'item',
              url: 'admin/Death_claims',
              permisions: []
            },
            {
              id: 'admin/Hospital_claims',
              title: 'Hospital Claims',
              type: 'item',
              url: 'admin/Hospital_claims',
              permisions: []
            },
            // {
            //   id: 'admin/funeral_dashboard',
            //   title: 'Funeral Claims',
            //   type: 'item',
            //   url: 'admin/bboxx_funeral_claims',
            //   permisions: []
            // },
            {
              id: 'admin/Device_Damage_claims',
              title: 'Device Damage Claims',
              type: 'item',
              url: 'admin/Device_Damage_claims',
              permisions: []
            },
            {
              id: 'admin/Disability_Claims',
              title: ' Disability Claims',
              type: 'item',
              url: 'admin/Disability_Claims',
              permisions: []
            },
            {
              id: 'admin/Device_Theft_claims',
              title: 'Device Theft Claims',
              type: 'item',
              url: 'admin/Device_Theft_claims',
              permisions: []
            },


          ]
        },
        {
          id: 'admin/reports',
          title: 'Reports',
          type: 'item',
          url: 'admin/reports',
          icon: DashboardIcon,
          permisions: [hasAuthourity('VIEW_BUSINESS_REPORT')]
        },
        {
          title: 'Knowledge Base',
          label: labels.partners,
          icon: SlideshowIcon,
          type: 'item',
          url: 'admin/knowledge_base',
          id: 'admin/knowledge_base',
          permisions: []
        }
      ]
    },
    {
      id: 'application',
      title: 'Configs',
      icon: HomeOutlinedIcon,
      type: 'group',
      permisions: [hasAuthourity('VIEW_ALL_TEMPLATES')],
      children: [
        {
          title: 'Configurations',
          id: 'Configurations',
          label: labels.Configurations,
          type: 'collapse',
          url: 'configs',
          icon: SettingsOutlinedIcon,
          permisions: [hasAuthourity('VIEW_ALL_TEMPLATES')],
          children:
            [{
              title: 'Products',
              label: labels.products,
              type: 'item',
              url: 'admin/products',
              id: 'admin/products',
              permisions: [hasAuthourity('VIEW_ALL_PRODUCTS')]
            },
            {
              title: 'SMS Templates',
              label: labels.smsTemplates,
              type: 'item',
              url: 'admin/view_sms_templates',
              id: 'admin/view_sms_templates',
              permisions: [hasAuthourity('VIEW_ALL_SMS_TEMPLATES')]
            },
            {
              title: 'Document Templates',
              label: labels.Templates,
              type: 'item',
              url: 'admin/view_doc_templates',
              id: 'admin/view_doc_templates',
              permisions: [hasAuthourity('VIEW_ALL_TEMPLATES')]
            },
            {
              title: 'Email Templates',
              label: labels.emailTemplates,
              type: 'item',
              url: 'admin/view_email_templates',
              id: 'admin/view_email_templates',
              permisions: [hasAuthourity('VIEW_ALL_TEMPLATES')]
            },
            {
              title: 'Partner',
              label: labels.partners,
              type: 'item',
              url: 'admin/partners/view/partner',
              id: 'admin/partners/view/partner',
              permisions: [hasAuthourity('VIEW_ALL_PARTNERS')]
            }
            ]
        },
        {
          title: labels.InternalConfigurations,
          id: 'Internal Configurations',
          label: labels.InternalConfigurations,
          type: 'collapse',
          url: 'Internal Configurations',
          icon: TuneOutlinedIcon,
          permisions: [hasAuthourity('VIEW_ALL_PARTNERS')],
          children:
            [
              {
                title: 'Reports Configs',
                label: 'Reports Configs',
                type: 'item',
                url: 'admin/view_reports_configs',
                id: 'admin/view_reports_configs',
                permisions: []
              },
              {
              title: 'Partners',
              label: labels.partners,
              type: 'item',
              url: 'admin/partners',
              id: 'admin/partners',
              permisions: [hasAuthourity('VIEW_ALL_PARTNERS')]
            },
            {
              title: 'User Administration',
              label: labels.userAdministration,
              type: 'item',
              url: 'admin/users',
              id: 'admin/users',
              permisions: [hasAuthourity('VIEW_ALL_USER')]
            },
            {
              title: 'Country Administration',
              label: labels.countryAdministration,
              type: 'item',
              url: 'admin/country',
              id: 'admin/country',
              permisions: [hasAuthourity('VIEW_COUNTRIES')]
            },
            // {
            //   title: 'Languages',
            //   label: labels.languages,
            //   type: 'item',
            //   url: 'admin/languages',
            //   id: 'admin/languages',
            //   permisions: []
            // },
            // {
            //   title: 'Feature Flags',
            //   label: 'Feature Flags',
            //   type: 'item',
            //   url: 'admin/feature-flags',
            //   id: 'admin/feature-flags',
            //   permisions: []
            // },
            ]
        },
      ]
    },


  ]
}

