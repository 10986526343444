import React, { useEffect, useState } from 'react'
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useStyle } from "./Style";
import ButtonInc from '../../../shared-ui/ButtonInc';

import MenuItem from '@mui/material/MenuItem';
import { availableFormat } from '../../../lib/reportUtils.es6';
import SkeletonWrapper from '../../NewProductList/components/SkeletonWrapper/SkeletonWrapper';
import AlertDialog from '../../../components/AlertDialog/index.es6';
import { CircularProgress } from 'material-ui';
import { downloadBlobReports, formatDateTime, reloadPage } from '../../../lib/utils.es6';
import { Button } from '@mui/material';
import { getUsers } from '../../../lib/requests.es6';

const GenerateReports = (props) => {

  if (props.loader) {
    return <SkeletonWrapper loading={props.loader} />

  }
  const classes = useStyle();

  const [labels, ] = useState(props.labels);
  const [selectedReportTemplate, setSelectedReportTemplate] = useState(null);
  const [reportId, setReportId] = useState('');
  const [reportTitle, setReportTitle] = useState('')
  const [dateValues, setDateValues] = useState({});
  const [numberValues, setNumberValues] = useState([])
  const [booleanValue, setBooleanValue] = useState(false)
  const [dropDownValue, setDropdownValue] = useState('');
  const [formatTypeValue, setFormatTypeValue] = useState('')
  const [booleanValueError, setBooleanValueError] = useState(false)
  const [textValue, setTextValue] = useState('')
  const [agents, setAgents] = useState([]);
  const [getUsersLoader, setGetUsersLoader] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState('');

  const handleDateChange = (fieldName, date) => {
    setDateValues((prevValues) => ({
      ...prevValues,
      [fieldName]: date,
    }));
  };
  const isAgent = (user) => {
    return user.attributes && user.attributes.isAgent && user.attributes.isAgent.includes("true");
  }

  const fetchUsers = async () => {
    try {
      setGetUsersLoader(true);
      const response = await getUsers();
      const users = response.data || [];
      const filteredAgents = users.filter(user => isAgent(user));
      const all_agents = {
        "id": "all_agents",
        "firstName": "all",
        "lastName": "agents",
      }
      setAgents([all_agents, ...filteredAgents]);
      setGetUsersLoader(false);
    } catch (error) {
      console.error('Error fetching users:', error);
      setGetUsersLoader(false);
    }
  }


  useEffect(() => {
    if (selectedReportTemplate && selectedReportTemplate.fields.some(field => field.name === 'agent')) {
      fetchUsers();
    } else {
      console.log('nothing');
    }
  }, [selectedReportTemplate])




  const handleNumberChange = (fieldName, number) => {
    setNumberValues((prevValues) => ({
      ...prevValues,
      [fieldName]: number,
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target
    if (name === "booleanValue") {
      setBooleanValue(true)
      setBooleanValueError(false)
    }
    if (name === "dropdown") {
      setDropdownValue(value)
    }
    if (name === "formatType") {
      setFormatTypeValue(value)
    }
    if (name === "textValue") {
      setTextValue(value)
    }

    if (name === "agent") {
      setSelectedAgent(value)
    }

  }

  const handleSelectChange = (event) => {
    const selectedTemplate = props.templates.find(template => template.title === event.target.value);
    console.log("selectedTemplate", selectedTemplate)
    setReportId(selectedTemplate.id)
    setReportTitle(selectedTemplate.title)
    setSelectedReportTemplate(selectedTemplate);
  };

  const handleSubmit = () => {
    if (!booleanValue) {
      setBooleanValueError(true);
    }
    const payload = {

    };

    // Update the payload properties based on the field types
    selectedReportTemplate && selectedReportTemplate.fields.forEach((field) => {
      switch (field.type) {
        case 'date':
          payload[field.name] = dateValues[field.name] || null;
          break;

        case 'boolean':
          payload[field.name] = booleanValue;
          break;

        case 'drop-down':
          payload[field.name] = dropDownValue || "N/A";
          break;

        case 'number':
          payload[field.name] = numberValues[field.name] || "N/A";
          break;

        case 'text':
          payload[field.name] = textValue || "N/A";
          break;

        case 'AGENTS':
        case 'USER_AGENTS':
          payload[field.name] = selectedAgent.id || "N/A";
          payload['agent_first_name'] = selectedAgent.firstName || "N/A";
          payload['agent_last_name'] = selectedAgent.lastName || "N/A";
          payload['agent_email'] = selectedAgent.email || "N/A";
          break;

        default:
          break;
      }
    });

    payload["id"] = reportId;
    payload["format"] = formatTypeValue || "N/A";

    console.log("Generated payload:", payload);
    props.generateReport(payload);

  }

  const handleRefresh = () => {
    props.resetGenerateReport()
    reloadPage()
  }
  const fileName = `${reportTitle}-${formatDateTime(new Date())}.${formatTypeValue}`;
  if (props.templates.length > 0) {

    return (
      <>
        <AlertDialog
          custom
          show={props.showGenerateReportProgressAlert}
          size="sm"
          style={{ marginTop: '0', top: '30vh' }}
          confirmBtnText={labels.ok}
          showCancel={false}
          showConfirm={false}
          cancelBtnText={labels.cancel}
          showTitle={false}
          confirmBtnCssClass
        >
          <CircularProgress />
          <h2>{labels.preparingYourReportFile}</h2>
        </AlertDialog>

        <AlertDialog
          success
          show={props.showGenerateReportSuccessAlert}
          size="sm"
          title={labels.reportGenerated}
          style={{ marginTop: '0', top: '30vh' }}
          confirmBtnText={labels.ok}
          showCancel={false}
          showConfirm={false}
          cancelBtnText={labels.cancel}
          confirmBtnCssClass
        >
          <div >

            <Button variant="contained" onClick={() => downloadBlobReports(
              props.reportBlob,
              fileName,
            )}
              style={{
                background: 'green',
                color: " #fff!important",
                padding: "10px;",
                fontSize: "12px",
                boxShadow: "none",
                fontStyle: "normal",
                fontFamily: "Open Sans",
                fontWeight: 400,
                lineHeight: "15px",
                bordeRadius: "8px",
                textTransform: "capitalize"
              }}>{labels.clickHereToDownload}</Button>


            <Button variant="outlined" style={{
              borderColor: "green",
              marginLeft: "30px",
              color: "#000",
              padding: "10px;",
              fontSize: "12px",
              boxShadow: "none",
              fontStyle: "normal",
              fontFamily: "Open Sans",
              fontWeight: 400,
              lineHeight: "15px",
              bordeRadius: "8px",
              textTransform: "capitalize"
            }} onClick={handleRefresh} >{labels.cancel}</Button>

          </div>
        </AlertDialog >

        <AlertDialog
          show={props.showGenerateReportErrorAlert}
          danger
          title={labels.errorGeneratingReport}
          confirmBtnText={labels.tryAgain}
          confirmBtnCssClass
          showCancel={false}
          showConfirm={false}
          style={{ marginTop: '0', top: '30vh' }}
        >
          {props.generateReportError ? props.generateReportError.message : ''}

          <div >
            <ButtonInc variant="contained" onClick={handleRefresh} style={{ background: 'green', }}>{labels.ok}</ButtonInc>
          </div>
        </AlertDialog>
        <div style={{ padding: '1%', display: "inline-block;", textAlign: "center", verticalAlign: "middle" }} className={classes.reportwrapper}>
          <ValidatorForm onSubmit={() => handleSubmit()} >

            <SelectValidator
              label={labels.chooseReportType}
              onChange={handleSelectChange}
              variant="outlined"
              style={{ marginBottom: "10px", width: "50%" }}
              sx={{
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "12px",
                lineHeight: "16px",
                letterSpacing: "0.005em",
                color: "#000000",
              }}
            >
              {props.templates.map((template) => (
                <MenuItem sx={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 300,
                  fontSize: "12px",
                  lineHeight: "16px",
                  letterSpacing: "0.005em",
                  color: "#000000",
                }} value={template.title}>{template.title}</MenuItem>
              ))}
            </SelectValidator>

            {selectedReportTemplate && selectedReportTemplate.fields.map((field) => {
              switch (field.type) {
                case 'date':
                  return <div style={{ marginTop: '2%' }}>
                    <TextValidator
                      label={field.display_name}
                      type="date"
                      name={field.name}
                      value={dateValues[field.name] || ''}
                      onChange={(event) => handleDateChange(field.name, event.target.value)}
                      style={{ width: "50%" }}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      validators={['required']}
                      errorMessages={[`Please select   ${field.name}`]}
                      sx={{
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        fontWeight: 300,
                        fontSize: "12px",
                        lineHeight: "16px",
                        letterSpacing: "0.005em",
                        color: "#000000",
                      }}
                    />
                  </div>;
                case 'boolean':
                  return <div style={{ marginTop: '2%', width: "32%" }}>
                    <FormGroup>
                      <FormControlLabel
                        labelPlacement="start"
                        control={<Switch onChange={handleChange} name='booleanValue' checked={booleanValue} color="primary" />}
                        label={field.display_name} />
                    </FormGroup>
                    {booleanValueError && <p style={{ color: 'red' }}>This field is required</p>}
                  </div>;
                case 'drop-down':
                  return (

                    <>
                      <SelectValidator
                        label={field.display_name}
                        onChange={handleChange}
                        value={dropDownValue}
                        name='dropdown'
                        variant="outlined"
                        validators={['required']}
                        errorMessages={[`Please select ${field.name}`]}
                        style={{ marginTop: "20px", width: "50%" }}
                        sx={{
                          fontFamily: "Open Sans",
                          fontStyle: "normal",
                          fontWeight: 300,
                          fontSize: "12px",
                          lineHeight: "16px",
                          letterSpacing: "0.005em",
                          color: "#000000",
                        }}
                      >
                        {Array.isArray(field.options) && field.options.map((option) => (
                          <MenuItem sx={{
                            fontFamily: "Open Sans",
                            fontStyle: "normal",
                            fontWeight: 300,
                            fontSize: "12px",
                            lineHeight: "16px",
                            letterSpacing: "0.005em",
                            color: "#000000",
                          }} value={option}>{option}</MenuItem>
                        ))}
                      </SelectValidator>
                    </>
                  );
                case 'AGENTS':
                case 'USER_AGENTS':
                  return (
                    <>
                      {getUsersLoader ? (
                        <CircularProgress style={{ marginTop: "20px" }} />
                      ) : (
                        <SelectValidator
                          label={field.display_name}
                          onChange={handleChange}
                          value={selectedAgent}
                          name="agent"
                          variant="outlined"
                          validators={["required"]}
                          errorMessages={[`Please select ${field.name}`]}
                          style={{ marginTop: "20px", width: "50%" }}
                          sx={{
                            fontFamily: "Open Sans",
                            fontStyle: "normal",
                            fontWeight: 300,
                            fontSize: "12px",
                            lineHeight: "16px",
                            letterSpacing: "0.005em",
                            color: "#000000",
                          }}
                        >
                          {agents.map((agent) => (
                            <MenuItem
                              sx={{
                                fontFamily: "Open Sans",
                                fontStyle: "normal",
                                fontWeight: 300,
                                fontSize: "12px",
                                lineHeight: "16px",
                                letterSpacing: "0.005em",
                                color: "#000000",
                              }}
                              value={agent}
                            >
                              {agent.firstName + " " + agent.lastName}
                            </MenuItem>
                          ))}
                        </SelectValidator>
                      )}
                    </>
                  );
                case 'number':
                  return <div style={{ marginTop: '2%' }}>
                    <TextValidator
                      label={field.display_name}
                      variant="outlined"
                      //onChange={handleNumberChange}
                      onChange={(event) => handleNumberChange(field.name, event.target.value)}
                      //value={numberValue}
                      value={numberValues[field.name] || ''}
                      //name='numberValue'
                      name={field.name}
                      type='number'
                      style={{ width: "50%" }}
                      validators={['required']}
                      errorMessages={[`Please add ${field.name}`]}
                    />

                  </div>;

                case 'text':
                  return <div style={{ marginTop: '2%' }}>
                    <TextValidator
                      label={field.display_name}
                      variant="outlined"
                      onChange={handleChange}
                      name='textValue'
                      value={textValue}
                      style={{ width: "50%" }}
                      validators={['required']}
                      errorMessages={[`Please add ${field.name}`]}
                    />

                  </div>;

                default:
                  return <div style={{ marginTop: '2%' }}>
                    <TextValidator
                      label={field.display_name}
                      variant="outlined"
                      style={{ width: "50%" }}
                      validators={['required']}
                      errorMessages={[`Please select ${field.name}`]}
                    />

                  </div>;
              }
            })}
            {
              selectedReportTemplate && <SelectValidator
                label="Format Type"
                onChange={handleChange}
                name='formatType'
                value={formatTypeValue}
                variant="outlined"
                validators={['required']}
                errorMessages={[labels.pleaseSelectReportType]}
                style={{ marginTop: "20px", width: "50%" }}
                sx={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 300,
                  fontSize: "12px",
                  lineHeight: "16px",
                  letterSpacing: "0.005em",
                  color: "#000000",
                }}
              >
                {availableFormat().map((format, index) => (
                  <MenuItem
                    key={index}
                    sx={{
                      fontFamily: "Open Sans",
                      fontStyle: "normal",
                      fontWeight: 300,
                      fontSize: "12px",
                      lineHeight: "16px",
                      letterSpacing: "0.005em",
                      color: "#000000",
                    }} value={format}>{format.toUpperCase()}</MenuItem>
                ))}
              </SelectValidator>
            }



            {
              selectedReportTemplate && <ButtonInc type='submit' style={{ marginTop: '2%' }}>{labels.generateReport}</ButtonInc>

            }
          </ValidatorForm>


        </div>
      </>
    )
  } else {
    return "No reports found"
  }

}

export default GenerateReports