import React, { useState } from 'react'
import { hashHistory } from "react-router";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { Grid } from "@mui/material";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { useStyle } from "./Style";
import './MediaQueries.css'
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { isComplexPartner } from '../../../../lib/access.es6';
import ButtonInc from '../../../../shared-ui/ButtonInc';
const MobileResults = (props) => {
  const classes = useStyle();
  const [showFilterOptions, setShowFilterOptions] = useState(false)

  const handleShowFilterOptions = () => {
    setShowFilterOptions(prevState => !prevState)
  }
  const handleChange = (event) => {
    props.filterByDocType(event.target.value);
    console.log("event.target.value", event.target.value)
  };

  const hasData =
    props.searchResults && Object.keys(props.searchResults).length > 0;

  const handleViewCustomer = (customer) => {
    hashHistory.push("admin/customer_status/" + customer.guid);
  };

  const handleViewPolicy = (policy) => {
    const isComplex = isComplexPartner();
    if (isComplex) {
      hashHistory.push("admin/policy-processing/" + policy.policy_number);
    } else {
      hashHistory.push("admin/customer_status/" + policy.customer_guid);
    }
  };

  const handleViewClaim = (claim) => {
    const isComplex = isComplexPartner();
    if (isComplex) {
      hashHistory.push("admin/claim-processing-v2/" + claim.guid);
    } else {
      hashHistory.push(`admin/claims_processing/${claim.guid}`);
    }
  };
  const handleChangePagination = (event, value) => {
    props.getResultsByPage(value)
  };
  const renderItem = (item) => {
    switch (item._doc_type) {
      case "Customer":
        return (
          // <Customer
          //   customer={item}
          //   handleViewCustomer={handleViewCustomer}
          // />
          <div className={classes.searchWrapper} >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <h2> {item.full_name}</h2>
              </Grid>
              <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <button
                  onClick={() => handleViewCustomer(item)}
                  style={{
                    background: "#298c83",
                    border: 0,
                    padding: "10px",
                    color: "#fff"
                  }}>View Customer</button>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <h3>Phone </h3>
                <p>{item.msisdn || "N/A"}</p>
              </Grid>
              <Grid item xs={4}>
                <h3>National ID.</h3>
                <p>{item.national_id || "N/A"}</p>
              </Grid>
              <Grid item xs={4}>
                <h3>Surname</h3>
                <p>{item.surname || "N/A"}</p>
              </Grid>
            </Grid>
          </div>
        );
      case "Policy":
        return (
          <>
            <div className={classes.searchWrapper} >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <h2> {item.full_name}</h2>
                </Grid>
                <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <button
                    onClick={() => handleViewPolicy(item)}
                    style={{
                      background: "#FBA92D",
                      border: 0,
                      padding: "10px",
                      color: "#fff"
                    }}>View Policy</button>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <h3>Policy Number </h3>
                  <p>{item.policy_number || "N/A"}</p>
                </Grid>
                <Grid item xs={4}>
                  <h3>Status</h3>
                  <p>{item.status || "N/A"}</p>
                </Grid>
                <Grid item xs={4}>
                  <h3>Surname</h3>
                  <p>{item.surname || "N/A"}</p>
                </Grid>
              </Grid>
            </div>
          </>
        );
      case "Claim":
        return (
          <>
            <div className={classes.searchWrapper} >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <h2> {item.full_name}</h2>
                </Grid>
                <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <button
                    onClick={() => handleViewClaim(item)}
                    style={{
                      background: "#D56239",
                      border: 0,
                      padding: "10px",
                      color: "#fff"
                    }}>View Claim</button>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <h3>Claim Number  </h3>
                  <p>{item.claim_number || "N/A"}</p>
                </Grid>
                <Grid item xs={4}>
                  <h3>Status</h3>
                  <p>{item.status || "N/A"}</p>
                </Grid>
                <Grid item xs={4}>
                  <h3>Phone Number</h3>
                  <p>{item.msisdn || "N/A"}</p>
                </Grid>
              </Grid>
            </div>

          </>
        );
      default:
        return null;
    }
  };
  return (
    <div className='mobile'>
      <Grid container >
        <Grid xs={6}>
          <h2 className={classes.totalResults}>{props.resultsShowing} results </h2>
        </Grid>
        <Grid xs={6}>
          <ul className={classes.filterWrapper} onClick={handleShowFilterOptions}>
            <li>Filter By: </li>
            <li style={{ paddingTop: "3px" }}><FilterAltIcon /></li>
          </ul>

        </Grid>
        {
          showFilterOptions && (<>
            <Grid xs={12}>
              <div className={classes.filterOption}>
                <FormControl>
                  <RadioGroup
                    row

                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={props.document_type}
                    onChange={handleChange}
                  >
                    <FormControlLabel value="Customer" control={<Radio />} label="Customer" sx={{ marginLeft: "2px" }} />
                    <FormControlLabel value="Policy" control={<Radio />} label="Policy" sx={{ marginLeft: "2px" }} />
                    <FormControlLabel value="Claim" control={<Radio />} label="Claim" sx={{ marginLeft: "2px" }} />
                  </RadioGroup>
                </FormControl>
                <ButtonInc style={{
              // marginLeft: "40px",
              // marginTop: "10px"
            }}
            onClick={() => props.clearFilter()}
            >Clear Filter</ButtonInc>
              </div>
            </Grid>
            <Grid xs={12}>
           
            </Grid>
          </>)
        }

      </Grid>

      {hasData && props.searchResults.data && props.searchResults.data.length > 0 ? (
        props.searchResults.data.map((item, index) => (
          <React.Fragment key={index}>
            {renderItem(item)}
          </React.Fragment>
        ))

      ) : (
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            className={classes.noResultsFound}
          >
            <h1>No results found !!</h1>
          </Grid>
        </Grid>
      )}

      {
        hasData && props.searchResults.data && props.searchResults.data.length > 0 && (<>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} sm={12}>

              <div className={classes.filterOption} style={{marginTop: "20px"}}>
                <Stack spacing={2}>
                  <Pagination count={props.total_pages} page={props.page} onChange={handleChangePagination} />
                </Stack>
              </div>
            </Grid>
          </Grid>

        </>)
      }
    </div>
  )
}

export default MobileResults