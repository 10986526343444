import React, { useState, useEffect } from 'react'
import { CircularProgress, Grid, InputAdornment } from '@mui/material';
import { useStyle } from "./Style";
import MenuItem from '@mui/material/MenuItem';
import { TextValidator, SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
import ButtonInc from '../../../../../shared-ui/ButtonInc';
import { BankData, SouthAfricaBanks } from '../../../lib/BankData';
import { capturePosthogEvent, getPartner, getPartnerGuid } from '../../../../../lib/access.es6';
import AlertDialog from '../../../../../components/AlertDialog/index.es6';

const BankDetails = (props) => {
  // console.log("policy details", props.policy)
  // console.log("customerData", props.customerData)
  const classes = useStyle();
  const [accountHolder, setAccountHolder] = useState('')
  const [bankdetails] = useState(getPartnerGuid() === 'aspinplus' ? SouthAfricaBanks : BankData);
  const [banks, setBanks] = useState('')
  const [branchDetails, setBranchDetails] = useState([]);
  const [branches, setBranches] = useState('')
  const [branchCode, setBranchCode] = useState('');
  const [AccountNumber, setAccountNumber] = useState('');
  const [debitOrderStartDate, setDebitOrderStartDate] = useState('')
  const [debitOrderDay, setDebitOrderDay] = useState('')
  const [policyNumber, setPolicyNumber] = useState(props.policy.policy_number)
  const [amount, setAmount] = useState(0);
  const [acountNumberError, setAcountNumberError] = useState(null)

  const currencyCode = getPartner().country.currency_code

  useEffect(() => {
    if (props.createdQuote) {
      const entries = props.createdQuote.policy_schedule_entries

      const totalPremium = entries.reduce((accumulator, member) => {
        return accumulator + member.premium_amount_in_cents;
      }, 0)
      setAmount(totalPremium / 100)
      // console.log("success props.policy",props.policy)
    } else {
      console.log("props.policy", props.policy)
    }

  }, [props.policy])

  useEffect(() => {

    ValidatorForm.addValidationRule('isValidAccountNumber', value => {
      if (!banks) {
        setAcountNumberError('please choose a bank first')
        return false
      }

      const acountNumberLength = banks.digit_account
      if (value.length === acountNumberLength) {
        return true
      } else {
        setAcountNumberError(`Please enter a ${acountNumberLength} digit account number`)
      }


    });

    return function cleanup() {
      ValidatorForm.removeValidationRule('isValidAccountNumber');
    };
  }, [AccountNumber, banks])


  const handleChange = (event) => {

    const { name, value } = event.target
    if (name === "accountHolder") {
      setAccountHolder(value)
    }
    if (name === "bankName") {
      setBanks(value);
      if (value) {
        setBranchDetails(value.branch_details)
      }
    }
    if (name === "branchName") {
      setBranches(value)
      if (value) {
        setBranchCode(value.code)
      }
    }
    if (name === "branchCode") {
      setBranchCode(value)
    }
    if (name === "AccountNumber") {
      setAccountNumber(value)
    }
    if (name === "debitOrderStartDate") {
      setDebitOrderStartDate(value)
      if (value) {
        const getDebitDay = value.slice(-2)
        setDebitOrderDay(parseInt(getDebitDay, 10))
      }
    }
    if (name === "policy") {
      setPolicyNumber(value)
    }
    if (name === "debitOrderDay") {
      setDebitOrderDay(value)
    }
    if (name === "amount") {
      setAmount(value)
    }
  }
  const reset = () => {
    setAccountHolder('');
    setBanks('');
    setBranches('');
    setAccountNumber('');
    setDebitOrderStartDate('');
    setDebitOrderDay('');
    setAmount('');
  }
  const addBankDetails = () => {

    const payload = {
      "start_date": debitOrderStartDate,
      "msisdn": props.customerData.msisdn,
      "bank_account_number": AccountNumber,
      "bank_name": banks.bank_name,
     // "branch_code": branchCode,
      "branch_code": banks.bank_name === 'ACCESS BANK' ? branchCode.substring(1,4) : branchCode,
      "policy_guid": props.policy.guid,
      "granularity": "Month",
      "at": Number(debitOrderDay),
      "mno_reference": props.customerData.msisdn + AccountNumber,
      "amount_in_cents": amount*100,
      "account_holder_name": accountHolder,
      "branch_name": branches.name,
      "additional_details": {},
      "payment_type": "bank_transfer",
      "channel": "ApiClient"
    }
    props.addDebitOrder(payload);
    capturePosthogEvent('save_bank_details')
    reset();
  }

  const handleSaveBankDetailsSuccess = () => {
    props.handleNext()
    capturePosthogEvent('save_bank_details_success')
  }

  const handleSaveBankDetailsError = () => {
    props.handleNext()
    capturePosthogEvent('save_bank_details_error')
  }

  return (
    <>
      <AlertDialog
        custom
        show={props.showAddDebitOrderProgressAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2>Adding debitOrder</h2>
      </AlertDialog>

      <AlertDialog
        success
        show={props.showAddDebitOrderSuccessAlert}
        size="sm"
        title={'DebitOrder added'}
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        confirmBtnCssClass
      >
        <div >
          <ButtonInc variant="contained" onClick={() => handleSaveBankDetailsSuccess()} style={{ background: 'green', }}>Upload Document</ButtonInc>
        </div>
      </AlertDialog>

      <AlertDialog
        show={props.showAddDebitOrderErrorAlert}
        danger
        title={'Error adding debitOrder'}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        showConfirm={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {props.createDebitOrderError ? props.createDebitOrderError.message : ''}
        <div >
          <ButtonInc variant="contained" onClick={() => handleSaveBankDetailsError()} style={{ background: 'green', }}>Try Again</ButtonInc>
        </div>
      </AlertDialog>
      <ValidatorForm onSubmit={() => addBankDetails()} >

        <Grid container spacing={2}>
          <Grid item xs={12} md={4} sm={4}>
            <TextValidator
              id="accountHolder"
              fullWidth
              label="Account Holder"
              name='accountHolder'
              value={accountHolder}
              onChange={handleChange}
              className={classes.quoteinputs}
              variant="outlined"
              style={{ width: "100%" }}
              validators={['required']}
              errorMessages={['Please add account holder name']}
            />
          </Grid>
          <Grid item xs={12} md={4} sm={4}>
            <SelectValidator
              labelId="select-bank-name"
              id="select-bank-name"
              value={banks}
              name='bankName'
              type="text"
              label="Bank Name"
              onChange={handleChange}
              variant="outlined"
              validators={['required']}
              errorMessages={['Please select bank name']}
              style={{ width: "100%" }}
              sx={{
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "12px",
                lineHeight: "16px",
                letterSpacing: "0.005em",
                color: "#000000",
              }}
            >
              {
                bankdetails.map((bank, index) => (
                  <MenuItem
                    key={index}
                    sx={{
                      fontFamily: "Open Sans",
                      fontStyle: "normal",
                      fontWeight: 300,
                      fontSize: "12px",
                      lineHeight: "16px",
                      letterSpacing: "0.005em",
                      color: "#000000",
                    }} value={bank}>{bank.bank_name}</MenuItem>
                ))
              }
            </SelectValidator>
          </Grid>
          <Grid item xs={12} md={4} sm={4}>
            <SelectValidator
              labelId="select-branch-name"
              id="select-branch-name"
              value={branches}
              name='branchName'
              type="text"
              label="Branch Name"
              onChange={handleChange}
              variant="outlined"
              validators={['required']}
              errorMessages={['Please select branch name']}
              style={{ width: "100%" }}
              sx={{
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "12px",
                lineHeight: "16px",
                letterSpacing: "0.005em",
                color: "#000000",
              }}
            >
              {
                bankdetails && branchDetails.map((branch, index) => (
                  <MenuItem
                    key={index}
                    sx={{
                      fontFamily: "Open Sans",
                      fontStyle: "normal",
                      fontWeight: 300,
                      fontSize: "12px",
                      lineHeight: "16px",
                      letterSpacing: "0.005em",
                      color: "#000000",
                    }}
                    value={branch}
                  >
                    {branch.name}
                  </MenuItem>
                ))
              }


            </SelectValidator>
          </Grid>
          <Grid item xs={12} md={4} sm={4}>
            <TextValidator
              id="branchCode"
              fullWidth
              label="Branch Code"
              name='branchCode'
              value={branchCode}
              disabled
              onChange={handleChange}
              className={classes.quoteinputs}
              variant="outlined"
              style={{ width: "100%" }}
              validators={['required']}
              errorMessages={['Please add branch code']}
            />
          </Grid>
          <Grid item xs={12} md={4} sm={4}>
            <TextValidator
              id="AccountNumber"
              fullWidth
              label="Account Number"
              name='AccountNumber'
              type='number'
              value={AccountNumber}
              onChange={handleChange}
              className={classes.quoteinputs}
              variant="outlined"
              style={{ width: "100%" }}
              validators={['isValidAccountNumber']}
              errorMessages={[acountNumberError]}
            />


          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <TextValidator
              id="debitOrderStartDate"
              label="Debit Order Start Date"
              name='debitOrderStartDate'
              value={debitOrderStartDate}
              onChange={handleChange}
              fullWidth
              type='date'
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              validators={["required"]}
              errorMessages={["Debit order start date is required",]}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} md={4} sm={4}>
            <TextValidator
              id="policy"
              fullWidth
              label="Policy"
              name='policy'
              type='text'
              value={policyNumber}
              disabled
              onChange={handleChange}
              className={classes.quoteinputs}
              variant="outlined"
              style={{ width: "100%" }}
              validators={['required']}
              errorMessages={['This field is required']}
            />
          </Grid>
          <Grid item xs={12} md={4} sm={4}>
            <TextValidator
              id="debitOrderDay"
              fullWidth
              label="Day"
              name='debitOrderDay'
              disabled
              type='number'
              value={debitOrderDay}
              onChange={handleChange}
              className={classes.quoteinputs}
              variant="outlined"
              style={{ width: "100%" }}
              validators={['required', 'minNumber:1', 'maxNumber:31']}
              errorMessages={['This field is required', 'Debit Day must be between 1 to 31', 'Debit Day must be between 1 to 31']}
            />
          </Grid>

          <Grid item xs={12} md={4} sm={4}>
            <TextValidator
              id="amount"
              fullWidth
              label="Amount"
              name='amount'
              type='number'
              value={amount}
              InputProps={{
                startAdornment: <InputAdornment

                  position="start"
                >{currencyCode ? currencyCode : ''}</InputAdornment>,

              }}
              disabled
              onChange={handleChange}
              className={classes.quoteinputs}
              variant="outlined"
              style={{ width: "100%" }}
              validators={['required']}
              errorMessages={['This field is required']}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} sm={6}>
            <ButtonInc type='submit'>Save Bank Details</ButtonInc>
          </Grid>
          <Grid item xs={12} md={6} sm={6}>

          </Grid>
        </Grid>
      </ValidatorForm>
    </>
  )
}

export default BankDetails