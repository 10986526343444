import { ClaimStatusEnum } from "./enum";

export const updateUrlWithStatus = (newValue, type) => {
  const status = {
    0: 'docs_pending',
    1: 'awaiting_confirmation',
    2: 'failed_confirmation',
    3: 'decision_pending',
    4: 'approved'
  };

  const types = {
    'hospital': 'Hospital_claims',
    'reimbursement': 'Reimbursement_claims',
    'death': 'Death_claims'
  }
  const queryParams = new URLSearchParams(window.location.search);
  queryParams.set('status', status[newValue]);
  const newUrl = `${window.location.origin}/#/admin/${types[type]}?${queryParams}`;
  window.history.replaceState({}, '', newUrl);
}

export const getQueryParams = (queryParamsStatus) => {
  const queryParams = {
    docs_pending: 0,
    awaiting_confirmation: 1,
    failed_confirmation: 2,
    decision_pending: 3,
    approved: 4
  }

  if (queryParams[queryParamsStatus]) {
    return queryParams[queryParamsStatus]
  }
  return 0
}

export const getInitialStatus = (status) => {
  switch (status) {
    case 'docs_pending':
      return ClaimStatusEnum.docs_pending;
    case 'awaiting_confirmation':
      return ClaimStatusEnum.awaiting_confirmation;
    case 'failed_confirmation':
      return ClaimStatusEnum.failed_confirmation;
    case 'decision_pending':
      return ClaimStatusEnum.verification;
    case 'approved':
      return ClaimStatusEnum.Approved;
    default:
      return ClaimStatusEnum.docs_pending;
  }
};