import { makeStyles } from "@material-ui/styles";

export const useStyle = makeStyles(() => ({

  ResultsBarWrapper: {
    width: "auto",
    height: "auto",
    background: "#032A37",
    boxShadow: "0px 2px 8px rgba(99, 99, 99, 0.2)",
    textAlign: "center",
   // margin: "-4% -5% -3% -7%",
    paddingTop: "6px",
    paddingBottom: "6px",
    borderRadius: "8px",
    "& h2": {
      fontFamily: 'Lato',
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "24px",
      letterSpacing: "0.0015em",
      color: "#FFFFFF;"
    }
  }

}));