import React from 'react'
import './MediaQueries.css'

const DesktopCustomerStatusSimple = (props) => {
  return (
    <div className='desktop'>{
      props.children
    }</div>
  )
}

export default DesktopCustomerStatusSimple