import React from "react";
import { connect } from "react-redux";
import ClaimHeader from './Components/ClaimHeader/ClaimHeader'
import ClaimDetails from './Components/ClaimDetails/ClaimDetails'
import ClaimDocuments from './Components/ClaimDocuments/ClaimDocuments'
import ClaimExpenses from './Components/ClaimExpenses/ClaimExpenses'
import ClaimActionButtons from './Components/ClaimActionButtons'
import * as actions from "./Redux/actions"
import BoxInc from "../../../shared-ui/BoxInc/Index";
import ButtonInc from '../../../shared-ui/ButtonInc';
import AddClaimNotes from "./Components/ClaimDetails/AddClaimNotes";
import AddClaimDocuments from "./Components/ClaimDocuments/AddClaimDocuments";
import AddClaimExpense from "./Components/ClaimExpenses/AddClaimExpense";
import DeleteClaimExpense from "./Components/ClaimExpenses/DeleteClaimExpense";
import EditClaimDetails from "./Components/ClaimExpenses/EditClaimDetails";
import DeleteClaimDocument from "./Components/ClaimDocuments/DeleteClaimDocument";
import AddClaimFacilityNotes from "./Components/ClaimDetails/AddClaimFacilityNotes";
import find from 'lodash/find';
import { hasAIClaimAssessor, hasAuthourity } from "../../../lib/access.es6";
import { ClaimTypeEnum } from "../lib/enum";
import { hashHistory } from 'react-router';
import { ViewAssessment } from "../../../containers/ClaimsProcessing/components/ViewAssessment";
import { getClaimAssessments } from "../../../lib/requests.es6";

class ClaimsProcessing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openClaimNotesDialog: false,
      openClaimFacilityNotesDialog: false,
      openClaimDocumentsDialog: false,
      openClaimExpenseDialog: false,
      openEditClaimExpenseDialog: false,
      description: "",
      expanded: false,
      missingDocs: false,
      openAssessmentDialog: false,
      assessments: [],
    };
  }

  fetchClaimAssessments = async (claim_guid) => {
    this.setState({ assessments: [] });
    try {
      const response = await getClaimAssessments(claim_guid);
      this.setState({ assessments: response.data });
      console.log('Assessments:', response.data);
    } catch (error) {
      console.error('Error fetching assesments:', error);
      this.setState({ assessments: [] });
    }
  };


  componentWillMount() {
    let claim_guid = this.props.params.claim_guid
    this.fetchClaimAssessments(claim_guid)
    this.props.dispatch(actions.getClaimProcessingDcpRequest({
      guid: claim_guid,
    }));
  

  }


  toggleClaimNotesDialog() {
    this.setState({ openClaimNotesDialog: !this.state.openClaimNotesDialog })
  }

  toggleClaimFacilityNotesDialog() {
    this.setState({ openClaimFacilityNotesDialog: !this.state.openClaimFacilityNotesDialog })
  }

  toggleClaimDocumentsDialog() {
    this.setState({ openClaimDocumentsDialog: !this.state.openClaimDocumentsDialog })
  }
  
  toggleClaimExpenseDialog() {
    this.setState({ openClaimExpenseDialog: !this.state.openClaimExpenseDialog, expanded: true })

  }

  toggleEditClaimExpenseDialog() {
    this.setState({ openEditClaimExpenseDialog: !this.state.openEditClaimExpenseDialog })
  }

  handleClaimExpense(payload) {
    this.props.dispatch(actions.createExpenseRequest(payload))
  }

  setClaimExpenseToDelete(expense) {
    this.props.dispatch(actions.setExpenseToDelete(expense));
  }
  deleteClaimExpense(expense) {
    this.props.dispatch(actions.deleteExpenseRequest({
      claim_guid: this.props.claimProcessingv2Data.claimsProcessingDcp.guid,
      expense_guid: expense.guid
    }));
  }
  resetExpenseToDelete(expense) {
    this.props.dispatch(actions.resetExpenseToDelete(expense))
  }

  setClaimExpenseToEdit(expense) {
    console.log("set claim to edit", expense)
    this.props.dispatch(actions.setExpenseToEdit(expense));
  }

  editClaimExpense(payload) {
    this.props.dispatch(actions.expenseEditRequest(
      payload
    ));
  }
  resetExpenseToEdit(expense) {
    this.props.dispatch(actions.resetExpenseEdit(expense))
  }
  handleClaimNotes(payload) {
    this.props.dispatch(actions.claimNotesRequest(
      payload));
  }

  handleClaimFacilityNotes(payload) {
    this.props.dispatch(actions.claimFacilityNotesRequest(
      payload));
  }

  deleteClaimDocument(document) {
    this.props.dispatch(actions.deleteclaimDocumentsRequest({
      claim_guid: this.props.claimProcessingv2Data.claimsProcessingDcp.guid,
      claim_doc_guid: document.guid
    }));
  }
  setClaimDocumentToDelete(document) {
    console.log("set claimdocument to delete", document)
    this.props.dispatch(actions.setDocumentsToDelete(document));
  }

  resetDocumentToDelete(document) {
    this.props.dispatch(actions.resetDocumentToDelete(document))
  }
  getPreauthDetails(guid) {
    this.props.dispatch(actions.getPreauthDetailsRequest(guid))
  }

  isDeathClaim(claim) {
    const hasDeathBenefit = !!find(claim.benefits, { type: "DEATH BENEFIT" })
    const hasNoLou = claim.preauthorization_number === null;

    return hasDeathBenefit && hasNoLou
  }

  isReimbusermentClaim(claim) {
    const hasDeathBenefit = !!find(claim.benefits, { type: "DEATH BENEFIT" })
    const hasNoLou = claim.preauthorization_number === null;

    return !hasDeathBenefit && hasNoLou
  }

  isHospitalClaim(claim) {
    return  claim.preauthorization_number !== null
  }
  getClaimType(claim){
    if(!claim) return ""
    if(this.isDeathClaim(claim)) return ClaimTypeEnum.death
    if(this.isReimbusermentClaim(claim)) return ClaimTypeEnum.reimbursement
    if(this.isHospitalClaim(claim)) return ClaimTypeEnum.hospital
    return ""
  }
  viewCustomerProfile(){
    const customer_guid = this.props.claimProcessingv2Data.claimsProcessingDcp.customer_guid
    if (customer_guid) {
      hashHistory.push('admin/customer_status/' + customer_guid);
    } 
  }
  render() {
    const type = this.getClaimType(this.props.claimProcessingv2Data.claimsProcessingDcp);
    const benefits = this.props.claimProcessingv2Data.claimsProcessingDcp ? this.props.claimProcessingv2Data.claimsProcessingDcp.benefits : []
    const hasDeathBenefit = !!find(benefits, {type: "DEATH BENEFIT"})
    const deathBenefit = find(benefits, {type: "DEATH BENEFIT"})

    const showAssessmentButton = hasAIClaimAssessor()
    && hasAuthourity('VIEW_ASPIN_AI_ASSESSMENTS')
    && this.state.assessments.length > 0;


    if (!hasAuthourity('VIEW_CLAIM')) {
      return <p>You are unauthorized to view claim.</p>
    }

    if (this.props.claimProcessingv2Data.get_claimsProcessingDcp_loader) {
      return <>
        <p>loading....</p>
      </>
    }
    if (!this.props.claimProcessingv2Data.claimsProcessingDcp) {
      return <>
        <p>nothing found </p>
      </>
    }
    return (
      <>
        <ClaimHeader
          claimData={this.props.claimProcessingv2Data.claimsProcessingDcp}
          showAssessmentButton={showAssessmentButton}
          toggleAssesmentDialog={() => this.setState({ openAssessmentDialog: !this.state.openAssessmentDialog })}
        />
        <ViewAssessment
            open={this.state.openAssessmentDialog}
            assessments={this.state.assessments}
            onClose={() => this.setState({ openAssessmentDialog: false })}
          />
        <BoxInc
          title="Claims Details"
          expanded
          style={{ marginTop: "40px" }}
          actionButtons={[<ButtonInc
            variant="contained"
            color="primary"
            size="large"
            onClick={this.viewCustomerProfile.bind(this)}
          >
           View Customer Profile    
          </ButtonInc>]}
        >

          <ClaimDetails
            claim={this.props.claimProcessingv2Data.claimsProcessingDcp}
            toggleClaimNotesDialog={this.toggleClaimNotesDialog.bind(this)}
            openClaimNotesDialog={this.state.openClaimNotesDialog}
            getPreauthDetails={this.getPreauthDetails.bind(this)}
            preauthDeatils={this.props.claimProcessingv2Data.preauthDetails}
            isDeathClaim={this.isDeathClaim.bind(this)}
            isReimbusermentClaim={this.isReimbusermentClaim.bind(this)}
          />
          <AddClaimFacilityNotes
            toggleClaimFacilityNotesDialog={this.toggleClaimFacilityNotesDialog.bind(this)}
            openClaimFacilityNotesDialog={this.state.openClaimFacilityNotesDialog}

            handleClaimFacilityNotes={this.handleClaimFacilityNotes.bind(this)}
            claimguid={this.props.claimProcessingv2Data.claimsProcessingDcp.guid}

            showCreateFacilityNoteProgressAlert={this.props.claimProcessingv2Data.ClaimFacilityNotesLoader}
            showCreateFacilityNotesSuccessAlert={this.props.claimProcessingv2Data.ClaimFacilityNotesSuccess}
            showCreateFacilityNotesErrorAlert={!!this.props.claimProcessingv2Data.ClaimFacilityNotesError}
            CreateFacilityNotesError={this.props.claimProcessingv2Data.ClaimFacilityNotesError}
            resetCreateFacilityNotesSuccessAlert={() => { this.props.dispatch(actions.resetFacilityClaimNotesSuccess()) }}
            resetCreateFacilityNotesErrorAlert={() => { this.props.dispatch(actions.resetCreateExpenseError()) }}
          />
          <AddClaimNotes

            toggleClaimNotesDialog={this.toggleClaimNotesDialog.bind(this)}
            openClaimNotesDialog={this.state.openClaimNotesDialog}

            handleClaimNotes={this.handleClaimNotes.bind(this)}
            claimguid={this.props.claimProcessingv2Data.claimsProcessingDcp.guid}

            showCreateNoteProgressAlert={this.props.claimProcessingv2Data.ClaimNotesLoader}
            showCreateNotesSuccessAlert={this.props.claimProcessingv2Data.ClaimNotesSuccess}
            showCreateNotesErrorAlert={!!this.props.claimProcessingv2Data.ClaimNotesError}
            CreateNotesError={this.props.claimProcessingv2Data.ClaimNotesError}
            resetCreateNotesSuccessAlert={() => { this.props.dispatch(actions.resetClaimActionSuccess()) }}
            resetCreateNotesErrorAlert={() => { this.props.dispatch(actions.resetFacilityClaimNotesError()) }}
          />


        </BoxInc>
        <BoxInc
          title="Claims Documents "
          style={{ marginTop: "30px" }}

        >
          <AddClaimDocuments
            description={this.state.description}
            toggleClaimDocumentsDialog={this.toggleClaimDocumentsDialog.bind(this)}
            openClaimDocumentsDialog={this.state.openClaimDocumentsDialog}
            claim_guid={this.props.claimProcessingv2Data.claimsProcessingDcp.guid}
            saveClaimDocument={(doc) => this.props.dispatch(actions.saveClaimDcpDocumentRequest(doc))}
            error ={this.props.claimProcessingv2Data.saveClaimDcpDocumentError}
         
          />
          <ClaimDocuments
            setDescription={(description) => this.setState({ description })}
            claimDocuments={this.props.claimProcessingv2Data.claimsProcessingDcp.claim_documents}
            setClaimDocumentToDelete={this.setClaimDocumentToDelete.bind(this)}
            toggleMissingDocs={() => this.setState({missingDocs: true})}
            missingDocs={this.state.missingDocs}
            openUploadDialog={this.toggleClaimDocumentsDialog.bind(this)}
            type={type}
          />
          <DeleteClaimDocument
            DocumentToDelete={this.props.claimProcessingv2Data.documentsToDelete}
            deleteClaimDocument={this.deleteClaimDocument.bind(this)}


            resetDocumentToDelete={this.resetDocumentToDelete.bind(this)}
            showDeleteDocumentProgressAlert={this.props.claimProcessingv2Data.hardDeleteExpenseLoader}
            showDeleteDocumentSuccessAlert={this.props.claimProcessingv2Data.hardDeleteDocumentSuccess}
            showDeleteDocumentErrorAlert={!!this.props.claimProcessingv2Data.deleteClaimDocumentError}
            deleteDocumentError={this.props.claimProcessingv2Data.deleteClaimDocumentError}

            resetDeleteDocumentSuccessAlert={() => {
              this.props.dispatch(actions.resetHardDeleteDocumentSuccess())
            }}
            resetDeleteDocumentErrorAlert={() => { this.props.dispatch(actions.resetHardDeleteDocumentError()) }}

          />
        </BoxInc>


        <BoxInc
          title={type === ClaimTypeEnum.death ? "Claim Benefits " : " Claim Invoices "} 
          expanded={this.state.expanded}
          style={{ marginTop: "30px" }}
          actionButtons={[<ButtonInc
            variant="contained"
            color="primary"
            size="large"
            onClick={this.toggleClaimExpenseDialog.bind(this)}
            hasPermision={hasAuthourity('UPDATE_CLAIM')}
          >
            {
              type === ClaimTypeEnum.death ? "Benefit Amount" : " Add  invoice details"
            }
           
          </ButtonInc>]}
        >
          <ClaimExpenses
            claimExpense={this.props.claimProcessingv2Data.claimsProcessingDcp}
            setClaimExpenseToDelete={this.setClaimExpenseToDelete.bind(this)}
            setClaimExpenseToEdit={this.setClaimExpenseToEdit.bind(this)}

            toggleEditClaimExpenseDialog={this.toggleEditClaimExpenseDialog.bind(this)}
            openEditClaimExpenseDialog={this.state.openEditClaimExpenseDialog}
            type={type}
            hasDeathBenefit={hasDeathBenefit}
            deathBenefit={deathBenefit}
          />

          <AddClaimExpense
            toggleClaimExpenseDialog={this.toggleClaimExpenseDialog.bind(this)}
            openClaimExpenseDialog={this.state.openClaimExpenseDialog}
            claim={this.props.claimProcessingv2Data.claimsProcessingDcp}
            handleClaimExpense={this.handleClaimExpense.bind(this)}

            showCreateExpenseProgressAlert={this.props.claimProcessingv2Data.createdClaimExpenseLoader}
            showCreateExpenseSuccessAlert={this.props.claimProcessingv2Data.createdClaimExpenseSuccess}
            showCreateExpenseErrorAlert={!!this.props.claimProcessingv2Data.createdClaimExpenseError}
            CreateExpenseError={this.props.claimProcessingv2Data.createdClaimExpenseError}
            resetCreateExpenseSuccessAlert={() => { this.props.dispatch(actions.resetCreateExpenseSuccess()) }}
            resetCreateExpenseErrorAlert={() => { this.props.dispatch(actions.resetCreateExpenseError()) }}
            type={type}
          />
          <DeleteClaimExpense
            expenseToDelete={this.props.claimProcessingv2Data.expenseToDelete}
            deleteClaimExpense={this.deleteClaimExpense.bind(this)}

            resetExpenseToDelete={this.resetExpenseToDelete.bind(this)}
            showDeleteExpenseProgressAlert={this.props.claimProcessingv2Data.hardDeleteExpenseLoader}
            showDeleteExpenseSuccessAlert={this.props.claimProcessingv2Data.hardDeleteExpenseSuccess}
            showDeleteExpenseErrorAlert={!!this.props.claimProcessingv2Data.deleteExpenseErrors}
            deleteExpenseError={this.props.claimProcessingv2Data.deleteExpenseErrors}

            resetDeleteExpenseSuccessAlert={() => {
              this.props.dispatch(actions.resetHardDeleteExpenseSuccess())
            }}
            resetDeleteExpenseErrorAlert={() => { this.props.dispatch(actions.resetHardDeleteExpenseError()) }}
          />

          <EditClaimDetails
            toggleEditClaimExpenseDialog={this.toggleEditClaimExpenseDialog.bind(this)}
            openEditClaimExpenseDialog={this.state.openEditClaimExpenseDialog}
            expenseToEdit={this.props.claimProcessingv2Data.ExpenseToEdit}
            editClaimExpense={this.editClaimExpense.bind(this)}
            resetExpenseToEdit={this.resetExpenseToEdit.bind(this)}

            showEditExpenseProgressAlert={this.props.claimProcessingv2Data.loader}
            showEditExpenseSuccessAlert={this.props.claimProcessingv2Data.editExpenseSuccess}
            showEditExpenseErrorAlert={!!this.props.claimProcessingv2Data.editExpenseErrors}
            editExpenseError={this.props.claimProcessingv2Data.editExpenseErrors}
            resetEditExpenseSuccessAlert={() => {
              this.props.dispatch(actions.resetExpenseEditSuccess())
            }}
            resetEditExpenseErrorAlert={() => { this.props.dispatch(actions.resetExpenseEditError()) }}
            claimguid={this.props.claimProcessingv2Data.claimsProcessingDcp.guid}
          />

        </BoxInc>

        <ClaimActionButtons
          claim={this.props.claimProcessingv2Data.claimsProcessingDcp}
          submitClaimAction={(claimActionPayload) => this.props.dispatch(actions.claimActionRequest(claimActionPayload))}
          loading={this.props.claimProcessingv2Data.ClaimActionLoader}
          error={this.props.claimProcessingv2Data.ClaimActionError}
          missingDocs={this.state.missingDocs}
        />
      </>
    )
  }

}
export default connect((state) => ({
  customerData: state.currentCustomer,
  claimProcessingv2Data: state.claimProcessingv2Data,
  claimDashboardData: state.claimDashboardData,
  globalData: state.global,
}))(ClaimsProcessing);