import React from 'react'
import './MediaQueries.css'
import MobileCustomerProfileInc from '../../shared-ui/MobileCustomerProfileInc';

const MobileCustomerStatus = (props) => {

  return (
    <div className='mobile' >

      <MobileCustomerProfileInc  {...props}/>
    </div>
  )
}

export default MobileCustomerStatus