export const localisedText = {
  en: {
    chooseReportType: 'Choose Report Type',
    pleaseSelectReportType: 'Please select a report type',
    errorGeneratingReport: 'Error generating report',
    clickHereToDownload: 'Click here to download',
    reportGenerated: 'Report Generated',
    errorDownloadingDocument: 'Error downloading document',
    imDone: 'I\'m done!',
    downloadDocument: 'Download Document',
    documentDownloaded: "Document Downloaded",
    requestedDownload: "The requested document will be generated",
    selectTemplateToDownload: 'Select which template to use',
    download: 'Download',
    uploadDocumentTemplate: 'Upload Template',
    address: 'Address',
    addDependant: 'Add dependant',
    editDependant: 'Edit dependant',
    Sister: 'Sister',
    Brother: 'Brother',
    Cousin: 'Cousin',
    Nephew: 'Nephew',
    Extended: 'Extended',
    Brother_In_Law: 'Brother in law',
    Niece: 'Niece',
    Grandmother: 'Grandmother',
    Grandfather: 'Grandfather',
    Grandson: 'Grandson',
    Granddaughter: 'Granddaughter',
    Grandchild: 'Grandchild',
    Sister_In_Law: 'Sister in law',
    Grand_Child: 'Grand child',
    Daughter_In_Law: 'Daughter in law',
    Fiance: 'Fiance',
    Step_Father: 'Step father',
    Step_Child: 'Step child',
    Step_Mother: 'Step mother',
    Son_In_Law: 'Son in law',
    Secondary_Spouse: 'Secondary spouse',
    Next_Of_Kin: 'Next of kin',
    Wider_Child: 'Wider child',
    updateUser: 'Update User',
    Uncle: 'Uncle',
    Aunt: 'Aunt',
    male: 'Male',
    female: 'Female',
    Male: 'Male',
    Female: 'Female',
    Principal: 'Principal',
    Spouse: 'Spouse',
    Child: 'Child',
    Parent: 'Parent',
    Parent_In_Law: 'Parent in Law',
    Beneficiary_Adult: 'Beneficiary Adult',
    Husband: 'Husband',
    Wife: 'Wife',
    Daughter: 'Daughter',
    Son: 'Son',
    Mother: 'Mother',
    Father: 'Father',
    Mother_In_Law: 'Mother in Law',
    Father_In_Law: 'Father in Law',
    errorMissingPropertyLossAmount: 'Total Claim Amount Missing',
    benefits: 'Benefits',
    sendRecurringPaymentRecommendationSms:
      'Send Recurring Payment Recommendation Sms',
    smsSent: 'Sms Sent',
    sendSms: 'Send Sms',
    couldNotSendSms: 'could not send sms, something went wrong',
    maximumCharactersOfSmsExceeded:
      'Maximum number of characters for an sms is exceeded',
    totalCharacters: 'Total Characters',
    sent: 'Sent',
    stopThisBlast: 'Stop This Blast',
    stopBlast: 'Stop Blast',
    areYouSureYouWantThisBlast: 'Are you sure you want to stop this Blast',
    onGoingBlastSummary:
      'Note: some messages have already been processed for sending.' +
      ' {alreadySent} number of messages will be sent to customers.' +
      ' {notSent} number of messages will be stopped',
    blastSuccessfullyStopped: 'Blast Successfully Stopped',
    hangTightWhileBlastStopped: 'Hang tight while the blast is being stopped',
    couldNotStopBlast: 'Could not stop blast, something went wrong',
    deleteUser: 'delete user',
    couldNotDeleteUser: 'Could not delete user',
    userDeleted: 'User deleted successfully',
    deleted: 'Deleted',
    theUser: 'The user',
    willBe: 'Will be',
    successfullyUpdatedRolesPartners:
      'Sucessfully updated user roles and partners',
    couldNotUpdateRolesPartners: 'Could not update user roles and partners',
    preparingYourReportFile: 'Preparing your report file, Hang tight.',
    created: 'Created',
    userCreatedSuccessMessage: 'User Created Successfully',
    plsAssignRole: 'Please asign at least one role',
    noRoleAssigned: 'No role assigned',
    plsAssignPartner: 'Please asign at least one partner',
    noPartnerAssigned: 'No partner assigned',
    claimsFound: 'Claims Found',
    customersFound: 'Customers Found',
    tryAgain: 'Try Again',
    added: 'Added',
    uploaded: 'Uploaded!',
    notSetYet: 'Not set yet',
    pleaseSelectEndDate: 'Please select end date',
    pleaseSelectStartDate: 'Please select start date ',
    codeMustNotBeEmpty: 'Code must not be empty',
    templateMustNotBeEmpty: 'template must not be empty',
    pleaseSelectLanguage: 'Please select language',
    paramsDoNotMatch:
      'parameters do not match. selected Parameters and the number of parameters specified in the template do not match',
    smsTemplatePlaceholder:
      'write your sms template here. Use %s to indicate a parameter. \n e.g. Dear %s, your balance is %s . Thank you.',
    createSmsTemplate: 'Create SMS Template',
    systemGenerated: 'system generated',
    selectedParameters: 'Selected Parameters',
    clickToRemoveParameter: 'Click to remove a parameter',
    clickToSelectParameter: 'Click to select a parameter',
    supportedParameters: 'Supported Parameters',
    updateSmsTemplate: 'Update SMS Template',
    editTemplate: 'Edit Template',
    deleteTemplate: 'Delete Template',
    successfullyCreatedSmsTemplate: 'Successfully created sms template',
    deleteTemplateWarningMessage:
      'Are you sure you want to delete this sms template?',
    successfullyDeletedSmsTemplate: 'Successfully deleted the sms template',
    successfullyUpdatedSmsTemplate: 'Successfully Updated sms template',
    smsTemplates: 'SMS Templates',
    viewSmsTemplates: 'View Sms Templates',
    flash: 'flash',
    weRecommendChromeBrowser: 'We recommend Chrome browser.',
    confirmResetPaymentResults:
      "Would you like to download payments upload results before it's reset?",
    confirmDownloadResults:
      "Would you like to download upload results before it's reset?",
    reset: 'Reset',
    hereIsASampleNumber: 'Here is a sample number: ',
    phoneNumberDigitsMustBe: 'Phone number digits must be: ',
    downloadCSVTemplate: 'Dowload CSV template',
    paymentAddedSuccessfully: 'Payment Added Successfully',
    fileUploadedSuccessfully: 'file uploaded successfully, download results',
    pleaseSelectFileToUpload: 'Please choose a file to upload',
    downloadPaymentUploadResults: 'Download Upload Results',
    importSmsBlast: 'Import Sms Blast',
    pleaseSelectBundlePaymentType: 'Please Select Bundle Payment Type',
    pleaseSelectPaymentType: 'Please Select Payment Type',
    voiceBundle: 'MOBILE_BUNDLE_VOICE',
    dataBundle: 'MOBILE_BUNDLE_DATA',
    addSinglePayment: 'Add Single Payment',
    addMultiplePayment: 'Add Multiple Payments',
    selectPaymentType: 'Select Payment Type',
    selectBundlePaymentType: 'Select Bundle Payment Type',
    bundlePayment: 'Bundle Payment',
    normalPayment: 'Normal Payment',
    customerMsisdnShouldNotBeEmpty: 'Customer Msisdn should not be empty',
    uploadPaymentsCsv: 'Upload Payments CSV',
    addBulkMissingPayments: 'ADD BULK MISSING PAYMENTS',
    addPayment: 'Add Payments',
    addPaymentSubHeading: 'Fill the below details to add a payment',
    ignoreMaxDaysAllowedAfterDischarge:
      'Ignore max days allowed after discharge',
    ignoreMaxDaysAllowedAfterDeath: 'Ignore max days allowed after Death',
    createCountry: 'Create Country',
    countries: 'countries',
    updateCountry: 'Update Country',
    deleteCountry: 'Delete Country',
    deleteCountryWarning: 'Are you sure want to delete country: ',
    confirmDeteleCountry: 'Confirm Delete Country',
    countryAdministration: 'Country Administration',
    resetPassword: 'Reset Password',
    deleteUserWarning: 'Are you sure you want to delete ',
    resetPasswordMessage: 'Are you sure you want to reset password for ',
    resetPasswordSuccessTitle: 'Password Successfully Reset',
    checkYourEmail: 'Check Your Email',
    couldNotResetPassword: 'Could not reset passowrd',
    missingPaymentAmountRequiredError: 'Amount is required',
    missingPaymentMinAmountError: 'Amount should be greater than 100',
    missingVaryingPaymentMinAmountError: 'Amount should be greater than ',
    missingPaymentEffectedDateError: 'Effected date is required',
    missingPaymentMnoReferenceError: 'MNO reference is required',
    missingPaymentPolicyError: 'Policy is required',
    error: "Error",
    selectVerificationMethod: 'Select verification methods',
    addMissingPayment: 'Add missing payment',
    switchCustomer: 'switch customer',
    phoneNumber: 'Phone Number',
    find: 'Find',
    inActive: 'Inactive',
    advancedSearch: 'Advanced Search',
    customerManagement: 'Customer Management',
    summary: 'Summary',
    registerCustomer: 'Register Customer',
    fillToRegisterCustomer: 'Fill the below details to register a customer',
    existingCustomer: 'Existing Customer',
    policyHistory: 'Policy History',
    paymentHistory: 'Payment History',
    claimsHistory: 'Claims History',
    newPolicy: 'Buy New Policy',
    makeClaim: 'Make Claim',
    claimsDashboard: 'Claims Dashboard',
    openClaims: 'Open Claims',
    openClaimsReport: 'Open Claims',
    claimsPayable: 'Claims Payable',
    closedClaims: 'Closed Claims',
    claimsProcessing: 'Claims Processing',
    sessionDialogTitle: 'Session Expired',
    sessionDialogText: 'Your current session has expired',
    anonymous: 'Anonymous',
    endSession: 'End session',
    refresh_session: 'Refresh',
    search: 'Search',
    searchCustomer: 'Search customers',
    searchClaim: 'search claims',
    titleRegister: 'Register New Customer',
    subtitleRegister: 'Enter Customer Details',
    fullName: 'Full Name',
    dateOfBirth: 'Date Of Birth',
    beneficiaryFullname: 'Beneficiary Full Name',
    beneficiaryFullnameError: 'Please enter beneficiary Full Name',
    beneficiaryPhoneNumber: 'Beneficiary Phone Number',
    cancel: 'Cancel',
    cancelled: 'Cancelled',
    submitRegister: 'Register',
    AddingPayment: "Adding payment",
    purchaseSuccessfullyMade: "Purchase  Successfuly Made",
    purchaseError: "Error occured while making payment",
    titleClaims: 'Claims',
    titleNoCustomer:
      'No customer is selected, please search and select a customer',
    feedBack: "Feedback",
    receivedAt: 'Received at',
    status: 'Status',
    moreClaims: 'More Claim History',
    pageNotFound: 'Page not found, return',
    pageNotFoundButtonText: 'Home',
    moreSameClaims: 'More Claims',
    titlePolicies: 'Policies',
    editUser: 'Edit User',
    smsActivity: 'SMS Activity',
    productName: 'Product Name',
    spouseName: 'Spouse Name',
    spouseNameError: 'Please enter Spouse Name',
    spouseMsisdn: 'Spouse Msisdn',
    spouseId: 'Spouse Nid',
    selectCoverError: "Please select a cover",
    SelectAcover: "Select a cover",
    spouseIdError: 'Please enter nid',
    Areyousureyouwanttocancelthispolicy: "Are you sure you want to cancel this policy?",
    Confirmcancellation: 'Confirm cancellation',
    active: 'Active',
    startDate: 'Start Date',
    validUntil: 'Valid Until',
    channel: 'Channel',
    action: 'Action',
    morePolicies: 'More Policy History',
    titlePremiums: 'Premiums',
    titlePayments: 'Payments',
    mnoReference: 'Payment reference',
    morePremiums: 'More Premium History',
    morePayments: 'More Payment History',
    valid: 'Valid',
    endDate: 'End Date',
    amount: 'Amount',
    titleBuyPolicy: 'Buy Policy',
    PolicyTittle: 'Policy',
    makePolicyPayment: 'Make Payment',
    PolicyError: 'Please select a policy',
    subtitleBuyPolicy: 'fill below fields to buy a policy',
    purchasingPolicy: 'purchasing policy',
    PolicyPurchaseRequestSubmitted: 'Policy purchase request submitted',
    ErrorPurchasingPolicy: 'Error purchasing policy',
    selectProductTitle: 'Select a paid product first',
    selectPremium: 'Select premium',
    selectPremiumError: 'Please select premium',
    defaultErrorMessage: 'An error occurred, this could be due to invalid data',
    successMessage: 'Policy purchase successful',
    product: 'Product',
    selectProduct: 'Select a product',
    selectLanguage: 'Select a language',
    selectProductError: 'Please select a product',
    cover: 'Cover',
    submitBuyPolicy: 'Buy',
    titleCustomerDetails: 'Customer Details',
    customerProfile: ' Profile',
    refresh: 'Refresh',
    edit: 'Edit',
    editPolicy: 'Edit entity',
    ShowLessPolicies: "Show Less Policies",
    ShowMorePremiums: "Show More Premiums",
    ShowLessPremius: "Show Less Premiums",
    noPaymentFound: "No payments are available please add payment",
    noPremiumsFound: "No premiums available please add payment",
    ShowLessPayments: "Show Less Payments",
    ShowMorePayments: "Show More Payments",
    ShowMorePolicies: "Show More Policies",
    noPoliciesFound: "No policies found please buy a  policy",
    nothingToDisplay: 'Nothing to display',
    dialogTitleCustomer: 'Edit Customer Information',
    titleLogin: 'Login Form',
    username: 'Username',
    password: 'Password',
    submitLogin: 'Log in',
    forgotPassword: 'Forgot password?',
    errorLoginNoCredentials: 'username or password cannot be empty',
    results: 'Search Results',
    spouseDateOfBirth: 'Spouse date of birth',
    spouseDateOfBirthError: 'Please enter Spouse Date Of Birth',
    editingpolicy: "editing policy",
    Policyeditrequestsubmitted: "Policy edit request submitted",
    Erroreditingpolicy: "Error editing policy",
    Goback: " Go back",
    cancellingpolicy: "cancelling policy.. ",
    Errorcancellingpolicy: "Error cancelling policy",
    Policycancelledsuccessfully: "Policy cancelled successfully",
    select: 'Select',
    searchByMsisdn: "Enter Customer's Phone Number",
    searchByName: 'Search by Name',
    searchByNamePlaceholder: 'Enter part of name to search',
    searchByMsisdnPlaceholder: 'Enter full or partial phone number',
    titleSelect: 'What would you like to do?',
    reports: 'Reports',
    claimSearch: 'Search Claims',
    subtitleClaimSearch: 'Search criteria',
    claimGuid: 'Claim reference',
    claimType: 'Type',
    reportType: 'Type',
    delete: 'Delete',
    deletePremium: 'Refund',
    confirmDeletePolicy: 'Confirm cancellation',
    confirmDeleteDependant: 'Confirm delete of dependant',
    confirmDeleteDependantText: 'Are you sure you want to delete a dependant?',
    confirmDeletePolicyText: 'Are you sure you want to cancel this entity?',
    confirmDeletePremium: 'Confirm refund',
    confirmDeletePremiumText: 'Are you sure you want to refund this premium?',
    confirmDeleteDocument: 'Confirm delete of document',
    confirmDeleteDocumentText: 'Are you sure you want to delete a document?',
    customer: 'Customer',
    msisdn: 'MSISDN',
    telephone: 'Telephone',
    claim: 'Claim',
    claims: 'Claims',
    timeLeft: 'Time Left',
    pickSearchCriteria: 'Please select search criteria first',
    searchMoreThanTwoChars:
      'Please enter at least 3 characters as the claim reference',
    view: 'view',
    noStatusText: 'No status provided',
    noClaimText: 'No claim found',
    errorMissingClaimType: 'Please select the claim type',
    errorMissingClaimRelation: 'Please select the relation to the main member',
    errorMissingAffectedPerson: 'Please enter the name of the affected person',
    errorMissingHospitalAdmission: 'Please enter the hospital admission date',
    errorMissingHospitalDischarge: 'Please enter the hospital discharge date',
    errorMissingStartDate: 'Please enter the start date',
    errorMissingEndDate: 'Please enter the end date',
    errorMissingDeathDate: 'Please enter date of death',
    errorMissingEventDate: 'Please enter date of event',
    successClaimSubmit: 'Claim successfully submitted',
    submitMakeClaims: 'Submit',
    nextMakeClaims: 'Next',
    backMakeClaims: 'Back',
    resetMakeClaims: 'Click here to reset',
    claimDetailsTitle: 'Enter Claims Details',
    claimSummaryTitle: 'Summary',
    claimResultsTitle: 'Results',
    personAffected: 'Person affected',
    personAffectedDob: 'Person affected Date of Birth',
    relationToMainMember: 'Relation to main member',
    CustomerFullName: "Customer Full Name",
    hospitalAdmissionDate: 'Hospital Admission Date',
    hospitalDischargeDate: 'Hospital Discharge Date',
    deathDate: 'Date of death',
    eventDate: 'Date of event',
    claimDateUINotFound: 'Nothing found to display date for this claim type',
    benefit: 'Benefit',
    nights: 'Nights',
    benefitPercent: 'Benefits (%)',
    nightlyBenefit: 'Nightly Benefit',
    nightlyBenefitPercent: 'Nightly Benefit (%)',
    fixedBenefit: 'Death Benefit',
    fixedBenefitPercent: 'Death Benefit (%)',
    propertyAssistanceBenefit: 'Property Assistance Benefit',
    propertyAssistanceBenefitPercent: 'Property Assistance Benefit (%)',
    incomeLossBenefit: 'Income Loss Benefit',
    incomeLossBenefitPercent: 'Income Loss Benefit (%)',
    total: 'Total',
    claimReference: 'Claim reference',
    receivedTime: 'Received Time',
    claimStatus: 'Claim status',
    defaultSelection: 'Please select',
    errorValidBirthCertificate: 'The birth certificate must be valid',
    errorMissingHospitalizationReason: 'Please provide hospitalization reason',
    errorMissingHospitalName: 'Please provide hospital name',
    errorMissingDocChannel:
      'Please select how supporting documents were submitted',
    errorMissingVerification: 'Please select verification means',
    errorMissingDocReceivedTime:
      'Please select when supporting documentation was submitted',
    errorMissingAffectedPersonDob:
      'Please select the date of birth of the person affected',
    errorMissingCondition: 'Please provide condition',
    errorMissingAccident: 'Please select if its an accident claim',
    hospital: 'Hospital',
    hospitalClaimDetails: 'Hospital Claim Details',
    funeralClaimDetails: 'Funeral Claim Details',
    incomeLossClaimDetails: 'Loss of Income Claim Details',
    propertyClaimDetails: 'Property Details',
    patientDateOfBirth: 'Patient date of birth',
    deceasedDateOfBirth: 'Deceased date of birth',
    hospitalisationReason: 'Reason for hospitalisation',
    conditionValue: 'Condition Value',
    deathCause: 'Causes of Death (if available)',
    highRisk: 'High risk',
    documentReceived: 'Document Received',
    documentReceivedVia: 'Document Received Via',
    birthCertificateValid: 'Birth Certificate Valid',
    documentationReceivedTime: 'All Documentation Complete',
    verificationReceived: 'Verification Received',
    approveClaimBtn: 'Approve Claim',
    markAsPaidBtn: 'Mark as paid',
    markAsUnpaidBtn: 'Mark as unpaid',
    payClaimBtn: 'Pay Claim',
    revertRejection: 'Revert Rejection',
    revertApproval: 'Reopen ExGratia',
    rejectClaimBtn: 'Reject Claim',
    reverseClaimBtn: 'Reverse Decision',
    confirmRejectionBtn: 'Confirm Rejection',
    claimValidPolicies: 'Valid policies',
    claimsOverview: 'Claims Overview',
    claimAmount: 'Claim amount',
    saveForLater: 'Save for Later',
    noClaimSelected: 'Please select a claim first',
    loyaltyOpOut: 'Opt out of loyalty',
    loyaltyOpIn: 'Opt in of loyalty',
    acceptsLoyalty: 'Opted in for loyalty',
    nationalId: 'National ID',
    errorNationalIdMissing: 'Please provide a national ID',
    createdAt: 'Created at',
    paymentDashboard: 'Payment Dashboard',
    RequestType: "Request Type",
    Message: "Message",
    MNOStatus: "MNO  Status",
    MNOMessage: " MNO Message",
    ShowLessSMS: "Show Less SMS",
    ShowMoreSMS: "Show More SMS",
    Nosmsavailable: "No sms available ",
    DeletingDocument: "Deleting Document...",
    Areyousureyouwanttodeletethisdocument: "Are you sure you want to delete this  document?",
    Documentdeleted: "Documentdeleted",
    Errordeletingdocument: "Error deleting document",
    Tryagain: "Try again",
    Couldnotdeletedocument: "Could not delete  document",
    or: "OR",
    Clicktoupload: "Click to upload",
    Uploadingfile: "Uploading file",
    Successfullyuploadedfile: "Successfully uploaded file",
    errorswithupload: "errors with upload",
    Draganddropfileshere: "Drag and drop files here",
    UploadDocuments: "Upload  Documents",
    uploadDocumentSubheading: "Fill the below fields to add  documents",
    DocumentType: "Document Type",
    docTypeError: "Please Choose document type",
    ImDone: " I'm  Done",
    viewDocument: "View Document",
    DeleteDocument: "Delete Document",
    FileName: "File Name",
    showLessClaims: 'Show Less Claims',
    showMoreClaims: 'Show More Claims',
    noClaimsFound: "No claims available",
    showMoreDocuments: "Show More Documents",
    showLessDocuments: "Show Less Documents ",
    noDocsFound: "Documents not found please upload",
    updateCustomer: 'Update Customer',
    editCustomerDetailsHeading: 'Edit Customer Details',
    editCustomerInformation: 'Edit Customer Information',
    customerRegistrationRequest: 'Customer registeration request submitted',
    customerRegistrationError: 'Error registering customer',
    registeringCustomer: 'registering customer',
    viewClaims: 'View Claims',
    viewReports: 'View Reports',
    profileDetails: 'Profile Details',
    clickToChangePassword: 'Click Here To Reset Password ',
    fullNameErrorMessage: 'Please enter full name',
    dateOfBirthErrorMessage: 'Please enter date of birth',
    languageSelectError: 'Please select language',
    pleaseEnterValid: 'Please enter a valid',
    digitPhoneNumber: 'digit phone number',
    passwordResetLink: 'Password reset link has been sent to the following email:',
    hello: 'Hello',
    viewAnalytics: 'View Analytics',
    editDetails: 'Edit Details',
    welcomeBack: 'welcome back',
    customerStatus: 'Customer Status',
    registrationChannel: 'Registration Channel',
    retryClaimPayment: 'Retry',
    retryClaimPaymentBtnLabel: 'Retry',
    markClaimPaymentAsFailedBtnLabel: 'Mark as Failed',
    markClaimPaymentAsSucceededBtnLabel: 'Mark as Succeeded',
    passwordConfirmNotMatching: 'passwords do not match',
    createUser: 'Create a User',
    requiredInput: 'this field is required, and no beginning with spaces',
    requiredNoSpace: 'this field is required, and no spaces allowed',
    passwordConstraints:
      'password must have length of 8-30, at least a lowercase, uppercase, a digit and a special character',
    changePassword: 'Change Password',
    firstName: 'First Name',
    lastName: 'Last Name',
    surname: 'Surname',
    email: 'Email',
    roles: 'Roles',
    partners: 'Partners',
    partner: 'Partner',
    confirmPassword: 'Confirm Password',
    newPassword: 'New Password',
    usersList: 'List of users',
    userAdministration: 'User Administration',
    viewUsers: 'View users',
    errorMissingFirstName: 'First name must not be empty',
    errorMissingSurname: 'Surname must not be empty',
    errorMissingLastName: 'Last name must not be empty',
    errorMissingFullName: 'Full name must not be empty',
    errorMissingDateOfBirth: 'Date of birth must not be empty',
    errorIncorrectID: 'Incorrect national Id',
    hasWallet: 'Has bank account',
    agent: 'Agent',
    isAgent: 'Is Agent',
    BeneficiaryNumber: 'Beneficiary  Number',
    BeneficiaryName: 'Beneficiary Name',
    registeredBy: 'Registered by',
    claimCallback: 'Claim Callback',
    yes: 'Yes',
    no: 'No',
    lang: 'Language',
    rejectionReason: 'Rejection Reason',
    rejectionReasonHint: 'Select a Reason',
    hours: 'h',
    days: 'd',
    timeSinceCreation: 'Since creation',
    timeSinceDocReceived: 'Since Doc Received',
    claimComment: 'Comment',
    uploadDocumentReceived: 'Upload Document Received',
    UploadDocument: 'Upload Document ',
    debit: 'Standing Order',
    getKyc: 'Get customer data',
    claimRejectionReason: 'Rejection Reason',
    file: 'Files',
    paymentAttempt: 'Payment attempt',
    paymentAttemptTitle: 'Payment attempts',
    titleNoFiles: 'No Files Uploaded',
    titleNoPayments: 'No Payments Attempted',
    claimFinalisation: 'Claim Finalisation',
    loyaltyEligibility: 'Loyalty Eligibility',
    generateReport: 'Generate Report',
    downloadReport: 'Download Report',
    date: 'Date',
    registrationReport: 'Registrations',
    claimFinalisationReport: 'Claim Finalisation',
    premiumReport: 'Premium',
    premiumReportDurationText: 'Report is only available for previous 3 months',
    dailyReport: "Daily Report",
    loyaltyRenewalButton: 'Renew Loyalty',
    loyaltyRenewalSubmit: 'Renew',
    loyaltyRenewalConfirm:
      'I understand and want to renew loyalty for the current month.',
    loyaltyRenewal: 'Loyalty Renewal',
    loyaltyUpload: 'Loyalty Upload',
    updateProfileTitle: 'Edit Profile Information',
    updateProfileSubmit: 'Submit',
    updatePasswordSubmit: 'Change password',
    outcome: 'Outcome',
    notes: 'Notes',
    index: 'No',
    filename: 'Filename',
    profileTitle: 'User profile',
    currentOpenClaims: 'Current open claims',
    debitOrder: 'Debit Orders',
    removeDebitOrder: 'Remove Debit Order',
    removeDebitOrderConfirmation: 'Confirm Debit order removal',
    removeDebitOrderMessage:
      'Are you sure you want to refund this debit order?',
    debitOrderSummary: 'Debit Order Summary',
    policy: 'Policy',
    reference: 'Reference',
    bankAccount: 'Bank Account',
    day: 'Day',
    bankAccountHint: 'Enter bank account number',
    bankAccountFloating: 'Bank Account Number',
    amountHint: 'Enter amount in cents',
    amountFloatingText: 'Amount in cents',
    debitOrderHint: 'Enter a debit order day (1-28)',
    debitOrderFloating: 'Debit order day',
    startDateHint: 'Select a start date',
    startDateFloat: 'Start Date',
    createDebitOrder: 'Create Debit Order',
    msisdnHint: 'Enter customer msisdn',
    debitOrderManagement: 'Debit Order management',
    debitOrderTransactions: 'Debit Order transactions',
    dailyDebitOrder: 'Daily Debit Order Transactions',
    markSuccess: 'Mark as success',
    markFailed: 'Mark as failed',
    downloadCsv: 'Download as csv',
    retries: 'Retries',
    policiesReport: 'Policies',
    mobileMoneyConfirmation:
      'A mobile money payment has been initiated, the customer will be prompted to enter their pin to proceed with the transaction.',
    cardPaymentConfirmation:
      'A card payment has been requested, Customer will receive an SMS with a link to enter their card payment details to process payment.',
    pay: 'Pay',
    initiatePayment: 'Initiate Payment',
    mnoStatus: 'MNO Status',
    moreDebitOrder: 'More Debit Order History',
    dragAndDrop: 'Drag and drop files here',
    Or: 'Or',
    clickUpload: 'Click to upload',
    uploadProgress: 'Upload in progress, do not interrupt',
    uploadMore: 'Click to upload more',
    uploadSuccess: 'Upload successfully completed',
    uploadFailure: 'Unfortunately something went wrong during upload :(',
    uploadStatus: 'Upload status',
    refundReport: 'Refund report',
    refund: 'Refund',
    vasReport: 'VAS Agent',
    reportAgentWeb: 'Web Agent',
    count: 'Count',
    reportPayableClaims: 'Payable Claims',
    reportSuspendedPolicies: 'Suspended Policies',
    dashboard: 'Dashboard',
    enterUsername: 'Enter username',
    enterPassword: 'Enter password',
    invalidUsername: 'invalid username',
    invalidPassword: 'enter password',
    kyc_success_edit_message:
      'Request Successful, the user KYC should be updated in 2 minutes',
    recoveryPasswordMsg:
      'Recovery email has sent successfully. Check your email and follow instructions to reset. Come back to the application to sign in once finished.',
    recoverPassword: 'Recover Password',
    buyPolicy: 'Buy a Policy',
    makeAClaim: 'Make a Claim',
    nothingFound: 'Nothing found :(',
    loyalty: 'Loyalty',
    partialPaymentPlaceHolder: 'Payment Amount',
    partialPaymentPlaceHolderError: 'Please enter a valid payment amount',
    paymentReference: 'Payment Reference',
    paymentReferenceError: 'Please enter payment reference',
    strikeInitiatedTitle: 'Mobile Money Collection',
    cardPaymentInitiatedTitle: 'Card Payment Initiated',
    strikeInitiatedButton: 'Ok',
    strikeInitiatedButtonConfirm: 'Confirm',
    balance: 'Balance',
    oldSession: 'Your session has expired. Please login again.',
    approvedClaimWarning:
      'As this claim is being paid for a Waiting Period, please confirm that the cause is due to accidental reasons',
    approvedClaimWarningButtonAccept: 'I confirm this was due to an accident',
    approvedClaimWarningButtonReject: 'Cancel and go back to claim',
    highRiskWarningButtonAccept: 'I confirm',
    highRiskWarningButtonReject: 'Cancel and go back to claim',
    approveClaimWithHighRiskHospital:
      ' is marked as High Risk. Please confirm or reject to proceed',
    bulkSms: 'Bulk Sms',
    bulkPayments: 'Bulk Payments',
    claimEditor: 'Assessor:',
    internalDashboard: 'Internal Dashboard',
    selectHospital: 'Select hospital',
    claims_paid_report: 'Claims Paid',
    claims_notification_report: 'Claims Notification',
    claims_register_report: 'Claims Register',
    premiums_register_report: 'Premiums Register',
    effectedAt: 'Effected at',
    effectedAtError: 'Please enter effected date',
    claim_condition: 'Condition',
    highRiskCustomer:
      'As this claim is being paid for High-Risk Customer, please confirm that the case has been double-checked and is not fraudulent',
    highRiskCustomerApproveBtn: 'I confirm this claim is not fraudulent',
    highRiskCustomerRejectBtn: 'Cancel and go back to claim',
    claimHistory: "Customer's Claim History",
    premiumDueReport: 'Premium Due',
    preAuthorizationReport: 'Pre-Authorization',
    claimDeathReprt: 'Death Claims',
    hospitalClaimReport: 'Hospital Claims',
    claimReimbursementReport: 'Reimbursement Claims',
    claimFinalizationDeathReprt: 'Finalization Death Claims', // claimDeathFinalizationReprt
    hospitalFinalizationClaimReport: 'Finalization Hospital Claims',
    claimFinalizationReimbursementReport: 'Finalization Reimbursement Claims',
    paginationNextText: 'next',
    paginationPreviousText: 'previous',
    updateClaimDetails: 'Update Claim Details',
    smsBatchBeingProcessed: 'SMS Batch is being processed: #',
    noDelimiter: 'Delimiter not set.',
    noMsisdnColumn: 'MSISDN column not set.',
    noTextColumnOrDefault: 'Text Message or Column not set.',
    msisdnColumnHelp:
      'Type the column number corresponding to the MSISDN cells.\r\nIf the column name is alphabetic, type the corresponding alphabet number, e.g: for A type 1.',
    textColumnHelp:
      'Type the column number corresponding to the SMS text cells.\r\nIf the column name is alphabetic, type the corresponding alphabet number, e.g: for A type 1.',
    cellContentHelp:
      'Each cell value in CSV / TXT file needs to wrap around with "".\r\n',
    batchNumber: 'Batch Number',
    totalSms: 'Total SMSs',
    invalidSms: 'Invalid SMS',
    completeAt: 'Complete at',
    getBatchSumary: 'Get Summary',
    getProgress: 'Get Progress',
    delimiter: 'Delimiter',
    useDefaultText: 'Use Default Text',
    textColumn: 'Text Column',
    textContent: 'Text Content',
    import: 'Import',
    importSms: 'Import SMS',
    previousBatches: 'Previous Blasts',
    revertExpiredClaim: 'Reinstate Expired',
    languages: 'Languages',
    code: 'Code',
    name: 'Name',
    country: 'Country',
    partnerName: 'Name',
    createPartner: 'Create a partner',
    partnerCode: 'Code',
    selectCountry: 'Select Country',
    defaultLanguage: 'Default Language',
    insurer: 'Insurer',
    serviceName: 'Service Name',
    ussdShortCode: 'USSD Code',
    whatsAppNumber: 'WhatsApp Number',
    callCentreNumber: 'Call Centre Number',
    ok: 'Ok',
    productCode: 'Product Code',
    productType: 'Product Type',
    productCoverType: 'Cover Type',
    amountHospitalBenefit: 'Hospital Benefit Amount',
    fixedIndemnityAmountBenefit: 'Fixed Indemnity Amount',
    minimumAge: 'Minimum Age',
    maximumAge: 'Maximum Age',
    coolOffPeriodHours: 'Cool Off Period (Hour)',
    waitingPeriodDays: 'Waiting Period (Day)',
    maximumHospitalBenefitDays: 'Maximum Hospital Benefit Days',
    minimumHospitalBenefitDays: 'Minimum Hospital Benefit Days',
    eligibilityThreshold: 'Eligibility Threshold',
    debitGraceDays: 'Grace Days',
    createProduct: 'Create Product',
    selectProductType: 'Select Product Type',
    selectProductCoverType: 'Select Cover Type',
    selectGranularity: 'Select Product Granularity',
    cardinality: 'Cardinality',
    paymentMethod: 'Payment Method',
    selectPaymentMethod: 'Select Payment Method',
    products: 'Products',
    selectPartner: 'Select Partner',
    premiumCount: 'Premium Count',
    viewProductPremium: 'View Premium',
    granularity: 'Granularity',
    to: 'to',
    coolOffPremium: 'Premium in cool off period',
    coolOffPremiumText: 'A premium can only be refunded after',
    full_hours: 'hours',
    skipFirstRow: 'Skip first row',
    scheduledFor: 'To be dispatched at',
    canReplyTo: 'Expect a reply',
    dispatchAt: 'To be dispatched at',
    addRemittances: 'Add Remittances',
    uploadRemittancesCsv: 'Upload Remittances CSV',
    titleRemittances: 'Remittances',
    fromFile: 'From a file',
    allRegisteredCustomer: 'Registered Customers',
    activeCustomersWithLoyalty: 'Loyalty Customers',
    activeCustomersWithUpSell: 'UpSell Customers',
    customersWithNotTakenUp: 'Not taken up entity holders',
    suspendedCustomers: 'Suspended Customers',
    currentUnregisteredLoyalty:
      'This month unregistered customers qualified for loyalty',
    confirmImportSmsBlast:
      'You are about to initiate an SMS blast. Are you sure you want to continue?',
    selectBlastSource: 'Select blast source',
    cashback: 'Cashback',
    providedLoyalties: 'Provided Loyalties',
    duplicatedMsisdns: 'Duplicated MSISDNs',
    representingDuplicatedMsisdns: 'Representing Duplicated MSISDNs',
    uniqueCustomersProvided: 'Unique Customers Provided',
    loyaltiesCreated: 'Loyalties Created',
    reading: 'Reading...',
    formatting: 'Formatting...',
    dropduplicate: 'Dropping duplicates...',
    consolidating: 'Consolidating...',
    allocating: 'Allocating...',
    backup: 'Backing up...',
    reporting: 'Reporting...',
    complete: 'Complete',
    failed: 'Failed',
    previousLoyalties: 'Previous Loyalty Status Report',
    loyalties: 'Loyalties',
    uploadLoyaltyData: 'Upload Loyalties',
    mbanking: 'M-Banking',
    telephony: 'Telephony',
    loyaltyWarning:
      'You are about to allocate loyalties for the current month period. Are you sure you want to continue?',
    errorLoyaltyField:
      'Please enter a valid number corresponding to the {field} column from the uploaded file.',
    helperLoyaltyField:
      'Enter a number corresponding to the {field} column from the uploaded file.',
    mapColumnsToFields: 'Enter Column Numbers',
    uploadFile: 'Upload File',
    paymentStatus: 'Status',
    paid: 'Paid',
    notPaid: 'Not Paid',
    propertyTotalAmount: 'Claim Total Amount',
    personalAccidentTotalAmount: 'Claim Total Amount',
    medicalExpenseTotalAmount: 'Medical Expense Total Amount',
    fixedIndemnityGenericTotalAmount: 'Cover Total Amount',
    paymentFailureReason: 'Payment Failure Reason',
    policyNumber: 'Policy Number',
    personalAccident: 'Personal Accident',
    personalAccidentClaimDetails: 'Personal Accident Claim Details',
    medicalExpense: 'Medical Expense',
    medicalExpenseClaimDetails: 'Medical Expense Claim Details',
    dependants: 'Dependants',
    relationship: 'Relationship',
    gender: 'Gender',
    town: 'Town',
    home: 'Home',
    save: 'Save',
    documents: 'Documents',
    BirthCertificate: 'Birth Certificate',
    NationalIdentity: 'National Identity',
    Templates: 'Document Templates',
    emailTemplates: 'Email Templates',
    PolicyTemplate: 'Policy Template',
    ClaimTemplate: 'Claim Template',
    CustomerTemplate: 'Customer Template',
    LOUTemplate: 'LOU Template',
    addDocumentTemplate: 'Add Template',
    Other: 'Other',
    documentType: 'Document Type',
    welcomeToAspin: 'Welcome back to ASPin',
    groupmaNagement: 'Group Management',
    groupRegistration: 'Group Registration',
    myGroups: 'My Groups',
    allGroups: 'All Groups',
    Claims: 'Claims',
    ClaimsDashboard: 'Claims Dashboard',
    claimPayable: 'Claims Payable',
    payments: 'Payments',
    ClaimFinalisation: 'Claim Finalisation',
    registrations: 'Registrations',
    Configurations: 'Configurations',
    InternalConfigurations: 'Internal Configs',
    poweredby: 'Powered by',
    editPolicyDetails: 'Edit Policy Details',
    downloadPolicyDocument: 'Download Policy Document',
    cancelPolicy: 'Cancel Policy',
    editPolicyDetailsSubtitle: 'Fill the below field to edit policy details',
    inpatient: 'Inpatient',
    outpatient: 'Outpatient',
    maternity: 'Maternity',
    inpatientClaimDetails: 'Inpatient Claim Details',
    maternityClaimDetails: 'Maternity Claim Details',
    outpatientClaimDetails: 'Outpatient Claim Details',
  },
  fr: {
    chooseReportType: 'Choisir le type de rapport',
    pleaseSelectReportType: 'Veuillez sélectionner un type de rapport',
    errorGeneratingReport: 'Erreur lors de la génération du rapport',
    clickHereToDownload: 'Cliquez ici pour télécharger',
    reportGenerated: 'Rapport généré',
    inpatient: 'Inpatient',
    outpatient: 'Outpatient',
    maternity: 'Maternity',
    inpatientClaimDetails: 'Inpatient Claim Details',
    maternityClaimDetails: 'Maternity Claim Details',
    outpatientClaimDetails: 'Outpatient Claim Details',
    editPolicyDetailsSubtitle: "Remplissez le champ ci-dessous pour modifier les détails de la politique",
    ShowLessPolicies: "Afficher moins de politiques",
    ShowLessPremius: " Afficher moins de primes",
    ShowMorePremiums: "Afficher plus de primes",
    noPaymentFound: "Aucun paiement n'est disponible, veuillez ajouter un paiement",
    noPremiumsFound: "Aucune prime disponible, veuillez ajouter le paiement",
    ShowMorePayments: "Afficher plus de paiements",
    ShowLessPayments: "Afficher moins de paiements ",
    ShowMorePolicies: "Afficher plus de politiques",
    inActive: 'Inactif',
    editPolicyDetails: 'Modifier les détails de la politique',
    noPoliciesFound: "Aucune police trouvée, veuillez acheter une police",
    downloadPolicyDocument: 'Télécharger le document de politique',
    cancelPolicy: "Politique d'annulation",
    errorDownloadingDocument: 'Error downloading document',
    imDone: 'j\'ai fini!',
    downloadDocument: 'Telecharger le Document',
    documentDownloaded: "Document telecharge",
    requestedDownload: "The requested document will be generated",
    selectTemplateToDownload: 'Selectionnez quel document il faut telecharger',
    download: 'Telecharger',
    uploadDocumentTemplate: 'Telecharger le template',
    addDocumentTemplate: 'Ajouter Template',
    Templates: 'Document Templates',
    address: 'Addresse',
    addDependant: 'Add dependant',
    editDependant: 'Edit dependant',
    Sister: 'Soeur',
    Brother: 'Frere',
    Cousin: 'Cousin/Cousine',
    Nephew: 'Neveu/Niece',
    Extended: 'Etendu',
    Brother_In_Law: 'Beau-frere',
    Niece: 'Niece',
    Grandmother: 'Grand-mere',
    Grandfather: 'Grand-pere',
    Grandson: 'Petit fils',
    Granddaughter: 'Petite fille',
    Grandchild: 'Petit enfant',
    Sister_In_Law: 'Belle-soeur',
    Grand_Child: 'Petit enfant',
    Daughter_In_Law: 'Belle-fille',
    Fiance: 'Fiance',
    Step_Father: 'Beau-pere',
    Step_Child: 'Beau-enfant',
    Step_Mother: 'Belle-mere',
    Son_In_Law: 'Beau-fils',
    Secondary_Spouse: 'Deuxieme femme',
    Next_Of_Kin: 'Next of kin',
    Wider_Child: 'Wider child',
    updateUser: "mettre à jour l'utilisateur",
    groupmaNagement: 'Gestion De Groupe',
    groupRegistration: 'Inscription de groupe',
    myGroups: 'Mes groupes',
    allGroups: 'Tous les groupes',
    Claims: 'Réclamations',
    ClaimsDashboard: 'Tableau de bord des réclamations',
    searchClaim: 'Rechercher des réclamations',
    claimCallback: 'Demander un rappel',
    openClaims: 'Réclamations ouvertes',
    claimPayable: 'réclamation à payer',
    payments: 'Paiements',
    ClaimFinalisation: 'Finalisation de la réclamation',
    registrations: 'Inscriptions',
    premiumDueReport: 'Prime due',
    loyaltyEligibility: 'Admissibilité à la fidélité',
    debitOrder: 'Ordres de débit',
    debitOrderManagement: 'Gestion des ordres de débit',
    debitOrderTransactions: "'Opérations d'ordre de débit' ",
    Configurations: 'Configurations',
    InternalConfigurations: 'Configurations internes',
    poweredby: 'Alimenté par',
    Uncle: 'Oncle',
    Aunt: 'Tante',
    telephone: 'Téléphone',
    registeredBy: 'Enrégistré par',
    confirmDeleteDocument: 'Supprimer un document',
    confirmDeleteDocumentText:
      'Etes-vous certain de vouloir supprimer un document?',
    documentType: 'Type de document',
    BirthCertificate: 'Acte de naissance',
    NationalIdentity: "Carte d'identite",
    PolicyTemplate: 'Policy Template',
    ClaimTemplate: 'Claim Template',
    CustomerTemplate: 'Customer Template',
    LOUTemplate: 'LOU Template',
    Other: 'Other',
    documents: 'Documents',
    confirmDeleteDependantText:
      'Etes-vous certain de vouloir supprimer un dependant?',
    confirmDeleteDependant: 'Supprimer un dependant',
    Male: 'Male',
    Female: 'Femelle',
    save: 'Sauvegarder',
    Principal: 'Principal',
    Spouse: 'Epouse',
    Child: 'Enfant',
    Parent: 'Parent',
    Parent_In_Law: 'Beau-parent',
    Beneficiary_Adult: 'Beneficiaire Adulte',
    Husband: 'Epoux',
    Wife: 'Epouse',
    Daughter: 'Fille',
    Son: 'Fils',
    Mother: 'Mere',
    Father: 'Pere',
    Mother_In_Law: 'Belle-mere',
    Father_In_Law: 'Beau-pere',
    male: 'Male',
    female: 'Femele',
    town: 'Ville',
    gender: 'le genre',
    relationship: 'Relation',
    dependants: 'Personnes à charge',
    medicalExpense: 'Remboursement des frais médicaux',
    personalAccident: 'Personal Accident personmel',
    medicalExpenseClaimDetails:
      'Détails de la demande de remboursement des frais médicaux',
    personalAccidentClaimDetails:
      'Détails de la réclamation pour accident personnel',
    medicalExpenseTotalAmount: 'Montant total des frais médicaux',
    fixedIndemnityGenericTotalAmount: 'Montant total de la couverture obsèques',
    personalAccidentTotalAmount: 'Montant total de la réclamation',
    policyNumber: 'Numéro de police',
    propertyTotalAmount: 'Montant total de la réclamation',
    errorMissingPropertyLossAmount: 'Montant total de la réclamation manquant',
    uploadFile: 'Télécharger le fichier',
    mapColumnsToFields: 'Saisir les numéros de colonne',
    errorLoyaltyField:
      'Veuillez saisir un numéro valide correspondant à la colonne {field} du fichier téléchargé.',
    helperLoyaltyField:
      'Veuillez saisir un numéro valide correspondant à la colonne {field} du fichier téléchargé.',
    loyaltyWarning:
      "Vous êtes sur le point d'allouer des gratuitées pour la période du mois en cours. Etes vous sur de vouloir continuer?",
    telephony: 'Téléphonie',
    mbanking: 'M-Banking',
    uploadLoyaltyData: 'Télécharger les fidélités',
    loyalties: 'Fidélités',
    previousLoyalties: 'Fidélités précédentes',
    reading: 'Fichier en lecture...',
    formatting: 'Mise en page...',
    dropduplicate: 'Suppression des dupliqués...',
    consolidating: 'Consolider...',
    allocating: 'Allouer...',
    backup: 'Sauvegarde...',
    reporting: 'Rapports...',
    complete: 'Finie',
    failed: 'Echouée',
    providedLoyalties: 'Fidélités soumises',
    duplicatedMsisdns: 'MSISDN dupliqués',
    representingDuplicatedMsisdns: 'Représentation des MSISDN dupliqués',
    uniqueCustomersProvided: 'Clients uniques fournis',
    loyaltiesCreated: 'Gratuitées Crées',
    selectBlastSource: "Selectionner l'origine des numeros",
    sendRecurringPaymentRecommendationSms:
      'Envoyer des SMS de recommandation de paiement récurrent',
    smsSent: 'sms envoyé',
    sendSms: 'envoyer un SMS',
    couldNotSendSms: "impossible d'envoyer des sms, un problème est survenu",
    maximumCharactersOfSmsExceeded:
      'Le nombre maximum de caractères pour un sms est dépassé',
    totalCharacters: 'nombre total de caractères',
    stopThisBlast: 'arrêter cette diffusion',
    stopBlast: 'Arrêter la diffusion',
    areYouSureYouWantThisBlast: 'Voulez-vous vraiment arrêter cette diffusion',
    blastSuccessfullyStopped: 'Diffusion interrompue avec succès',
    hangTightWhileBlastStopped: 'Tenez bon alors que la diffusion est arrêtée',
    couldNotStopBlast:
      "Impossible de stopper la diffusion, quelque chose s'est mal passé",
    deleteUser: "Supprimer l'utilisateur",
    couldNotDeleteUser: "Impossible de supprimer l'utilisateur",
    userDeleted: "L'utilisateur a bien été supprimé",
    deleted: 'supprimé',
    theUser: "l'utilisateur",
    willBe: 'sera',
    couldNotUpdateRolesPartners:
      "Impossible de mettre à jour les rôles d'utilisateur et les partenaires",
    successfullyUpdatedRolesPartners:
      "Rôles d'utilisateurs et partenaires mis à jour avec succès",
    preparingYourReportFile:
      'Préparez votre fichier de rapport, Accrochez-vous.',
    created: 'Créé',
    userCreatedSuccessMessage: 'Utilisateur créé avec succès',
    plsAssignRole: 'Veuillez attribuer au moins un rôle',
    noRoleAssigned: 'Aucun rôle attribué',
    plsAssignPartner: 'Veuillez affecter au moins un partenaire',
    noPartnerAssigned: 'Aucun partenaire assigné',
    claimsFound: 'Sinistres trouvées',
    customersFound: 'Clients trouvés',
    tryAgain: 'Réessayer',
    added: 'Ajouté',
    uploaded: 'Téléchargé!',
    notSetYet: 'Pas encore défini',
    pleaseSelectEndDate: 'Veuillez sélectionner la date de fin',
    pleaseSelectStartDate: 'Veuillez sélectionner la date de début',
    codeMustNotBeEmpty: 'Le code ne doit pas être vide',
    templateMustNotBeEmpty: 'Le template ne doit pas être vide',
    pleaseSelectLanguage: 'Veuillez sélectionner la langue',
    paramsDoNotMatch:
      'les paramètres ne correspondent pas. les paramètres sélectionnés et le nombre de paramètres spécifiés dans le template ne correspondent pas',
    smsTemplatePlaceholder:
      'écrivez votre sms template ici. Utilisez% s pour indiquer un paramètre. \n par exemple, Cher% s, votre solde est de% s. Je vous remercie.',
    createSmsTemplate: 'Créer SMS Template',
    systemGenerated: 'généré par le système',
    selectedParameters: 'Paramètres sélectionnés',
    clickToRemoveParameter: 'Cliquez pour supprimer un paramètre',
    clickToSelectParameter: 'Cliquez pour sélectionner un paramètre',
    supportedParameters: 'Paramètres pris en charge',
    updateSmsTemplate: 'Mettre à jour le modèle SMS',
    editTemplate: 'Modifier le modèle',
    deleteTemplate: 'Supprimer le modèle',
    successfullyCreatedSmsTemplate: 'Modèle de sms créé avec succès',
    deleteTemplateWarningMessage:
      'Voulez-vous vraiment supprimer ce modèle de sms?',
    successfullyDeletedSmsTemplate: 'Suppression du modèle de SMS réussie',
    successfullyUpdatedSmsTemplate: 'Modèle de SMS mis à jour avec succès',
    smsTemplates: 'Modèles sms',
    emailTemplates: "Modèles d'e-mails",
    flash: 'éclat',
    viewSmsTemplates: 'Afficher les modèles de SMS',
    weRecommendChromeBrowser: 'Nous recommandons le navigateur Chrome',
    confirmResetPaymentResults:
      'Souhaitez-vous télécharger les résultats de téléchargement des paiements avant sa réinitialisation?',
    confirmDownloadResults:
      'Souhaitez-vous télécharger les résultats de téléchargement?',
    reset: 'réinitialiser',
    hereIsASampleNumber: "Voici un numéro d'échantillon: ",
    phoneNumberDigitsMustBe: 'Le numéro de téléphone doit être composé de: ',
    downloadCSVTemplate: 'Télécharger le modèle CSV',
    paymentAddedSuccessfully: 'Paiement ajouté avec succès',
    fileUploadedSuccessfully:
      'fichier chargé avec succès, télécharger les résultats',
    pleaseSelectFileToUpload: 'Veuillez choisir un fichier à télécharger',
    downloadPaymentUploadResults: 'Télécharger les résultats',
    pleaseSelectPaymentType: 'Veuillez sélectionner le type de paiement',
    addSinglePayment: 'Ajouter un paiement unique',
    addMultiplePayment: 'Ajouter plusieurs paiements',
    selectPaymentType: 'Sélectionnez le type de paiement',
    normalPayment: 'Paiement Normal',
    bundlePayment: 'Paiement Bundle',
    customerMsisdnShouldNotBeEmpty: 'Client Msisdn ne doit pas être vide',
    uploadPaymentsCsv: 'Télécharger le fichier CSV',
    addBulkMissingPayments: 'AJOUTER DES PAIEMENTS MANQUANTS',
    addPayment: 'ajouter des paiements',
    addPaymentSubHeading: 'Remplissez les détails ci-dessous pour ajouter un paiement',
    ignoreMaxDaysAllowedAfterDischarge:
      "Ignorer le nombre maximal de jours autorisés après la sortie de l'hôpital",
    ignoreMaxDaysAllowedAfterDeath:
      'Ignorer le nombre maximal de jours autorisés après le décès',
    createCountry: 'Créer un pays',
    countries: 'des pays',
    updateCountry: 'Mettre à jour le pays',
    deleteCountry: 'Supprimer le pays',
    deleteCountryWarning: 'Êtes-vous sûr de vouloir supprimer le pays: ',
    confirmDeteleCountry: 'Confirmer supprimer le pays',
    countryAdministration: 'Administration du pays',
    resetPassword: 'Réinitialiser le mot de passe',
    deleteUserWarning: 'Etes-vous sûr que vous voulez supprimer ',
    resetPasswordMessage:
      'Êtes-vous sûr de vouloir réinitialiser le mot de passe pour ',
    resetPasswordSuccessTitle: 'Réinitialisation du mot de passe réussie',
    checkYourEmail: 'jetez un oeil à votre email',
    couldNotResetPassword: 'Impossible de réinitialiser le mot de passe',
    switchCustomer: 'Changer de client',
    selectVerificationMethod: 'sélectionner les méthodes de vérification',
    phoneNumber: 'Numéro de téléphone',
    find: 'Chercher',
    advancedSearch: 'Recherche Avancée',
    premiumReportDurationText:
      "Le rapport n'est disponible que pour les 3 derniers mois",
    customerManagement: 'Gestion de la clientèle',
    summary: 'Résumé',
    registerCustomer: 'Inscrire Nouveau Client',
    fillToRegisterCustomer: 'Remplissez les détails ci-dessous pour enregistrer un client',
    existingCustomer: 'Client Existant',
    policyHistory: 'Historique de Couvertures',
    paymentHistory: 'Historique de Paiements',
    claimsHistory: 'Historique de Sinistres',
    newPolicy: 'Acheter une Couverture',
    makeClaim: 'Déclarer un Sinistre',
    claimsDashboard: 'Tableau de bord des Sinistres',
    openClaimsReport: 'Sinistres à Traiter',
    claimsPayable: 'Sinistres Valides',
    closedClaims: 'Sinistres Traités',
    claimsProcessing: 'Sinistres en Traitement',
    sessionDialogTitle: 'Session Expirée',
    sessionDialogText: 'Votre session actuelle a expiré',
    anonymous: 'Anonyme',
    endSession: 'Terminer session',
    search: 'Recherche',
    searchCustomer: 'Chercher clients',
    titleRegister: 'Inscrire Nouveau Client',
    subtitleRegister: 'Entrer les détails du client',
    fullName: 'Nom Complet',
    pageNotFound: 'Page non trouvée, retour',
    pageNotFoundButtonText: 'Accueil',
    dateOfBirth: 'Date de Naissance',
    beneficiaryFullname: 'Nom complet du Bénéficiaire',
    beneficiaryFullnameError: 'Veuillez saisir le nom complet du bénéficiaire',
    beneficiaryPhoneNumber: 'Numéro de téléphone du Bénéficiaire',
    cancel: 'Annuler',
    cancelled: 'Annulé',
    submitRegister: 'Inscrire',
    AddingPayment: "Ajout d'un paiement",
    purchaseSuccessfullyMade: "Achat effectué avec succès",
    purchaseError: "Une erreur s'est produite lors du paiement",
    titleClaims: 'Sinistres',
    titleNoCustomer:
      "Aucun client est sélectionné, s'il vous plaît rechercher et sélectionner un client",
    feedBack: "Retour",
    receivedAt: 'Date de Réception',
    status: 'Statut',
    moreClaims: "Plus d'historique",
    moreSameClaims: 'Plus de Sinistres',
    titlePolicies: 'Couvertures',
    editUser: 'Modifier l utilisateur',
    smsActivity: 'Activité SMS',
    productName: 'Nom du Produit',
    spouseName: 'Nom du conjoint',
    spouseNameError: 'Veuillez entrer le nom du conjoint',
    spouseMsisdn: 'Numéro  du conjoint',
    spouseId: 'ID du conjoint',
    SelectAcover: "Sélectionnez une couverture",
    selectCoverError: "Veuillez sélectionner une couverture",
    spouseIdError: 'Veuillez saisir l identifiant du conjoint',
    Areyousureyouwanttocancelthispolicy: "Êtes-vous sûr de vouloir annuler cette politique?",
    Confirmcancellation: "Confirmer l'annulation",
    active: 'Actif',
    startDate: 'Date de Début',
    validUntil: 'Valable jusque',
    channel: 'Canal',
    action: 'Action',
    morePolicies: "Plus d'historique",
    titlePremiums: 'Primes',
    titlePayments: 'Paiements',
    mnoReference: 'Référence de paiement',
    morePremiums: "Plus d'historique",
    morePayments: "Plus d'historique",
    valid: 'Valide',
    endDate: 'Date de Fin',
    amount: 'Montant',
    titleBuyPolicy: 'Acheter une Couverture',
    PolicyTittle: 'Politique',
    makePolicyPayment: 'Effectuer le paiement',
    PolicyError: "Veuillez sélectionner une politique",
    subtitleBuyPolicy: 'remplissez les champs ci-dessous pour acheter une police',
    PolicyPurchaseRequestSubmitted: 'Demande d achat de police soumise',
    ErrorPurchasingPolicy: " Erreur dans la politique d'achat ",
    purchasingPolicy: 'politique d achat',
    selectProductTitle: "Sélectionner une couverture d'abord",
    selectPremium: 'Sélectionner une prime',
    selectPremiumError: 'Sélectionnez une prime',
    defaultErrorMessage: "Une erreur s'est produite",
    successMessage: 'Achat réussi',
    product: 'Couverture',
    selectProduct: 'Choisir une couverture',
    selectLanguage: 'Choisir une langue',
    selectProductError: 'Choisissez une couverture',
    cover: 'Prime',
    submitBuyPolicy: 'Acheter',
    titleCustomerDetails: 'Détails du client',
    customerProfile: 'Profil',
    refresh: 'Mettre à jour',
    edit: 'Modifier',
    editPolicy: 'Modifier couverture',
    nothingToDisplay: 'Rien à afficher',
    dialogTitleCustomer: 'Modifier Client',
    titleLogin: 'Connexion',
    username: 'Utilisateur',
    password: 'Mot de passe',
    submitLogin: 'Connexion',
    forgotPassword: 'Mot de passe oublié ?',
    errorLoginNoCredentials:
      'utilisateur ou mot de passe ne peut pas être vide',
    results: 'Résultats',
    spouseDateOfBirth: 'Date de naissance du conjoint',
    spouseDateOfBirthError: 'Veuillez saisir la date de naissance du conjoint',
    Goback: "Retourner",
    cancellingpolicy: "politique d'annulation.. ",
    Errorcancellingpolicy: "Erreur lors de l'annulation de la politique",
    Policycancelledsuccessfully: "Politique annulée avec succès",
    editingpolicy: "politique de modification",
    Policyeditrequestsubmitted: "Demande de modification de politique soumise",
    Erroreditingpolicy: "Erreur lors de la modification de la règle",
    select: 'Choisir',
    searchByMsisdn: 'Entrez le numéro de téléphone du client',
    searchByName: 'Rechercher par nom',
    searchByNamePlaceholder: 'Saisir une partie du nom à rechercher',
    searchByMsisdnPlaceholder:
      'Saisir le numéro de téléphone complet ou partiel',
    titleSelect: "Qu'est-ce que vous aimeriez faire ?",
    reports: 'Rapports',
    claimSearch: 'Chercher Sinistres',
    subtitleClaimSearch: 'Paramètres',
    claimGuid: 'Numéro de Sinistre',
    claimType: 'Type',
    reportType: 'Type',
    delete: 'Supprimer',
    deletePremium: 'Supprimer',
    confirmDeletePolicy: 'Confirmer suppression',
    confirmDeletePolicyText:
      'Etes-vous certain de vouloir supprimer cette couverture?',
    confirmDeletePremium: 'Confirmer suppression',
    confirmDeletePremiumText:
      'Etes-vous certain de vouloir supprimer cette prime?',
    customer: 'Client',
    msisdn: 'Numéro',
    claim: 'Sinistre',
    claims: 'Sinistre',
    timeLeft: 'Temps restant',
    pickSearchCriteria: "Choisissez les paramètres de recherche d'abord",
    searchMoreThanTwoChars:
      'Veuillez saisir au moins 3 caractères comme référence de la revendication.',
    view: 'voir',
    noStatusText: 'Pas de statut défini',
    noClaimText: 'Aucun sinitre trouvé',
    errorMissingClaimType: 'Choisissez le type de sinistre',
    errorMissingClaimRelation: "Sélectionnez l'affiliation à l'assuré",
    errorMissingAffectedPerson: 'Entrez le nom de la personne concernée',
    errorMissingHospitalAdmission: "Entrez la date d'entrée à l'hôpital",
    errorMissingHospitalDischarge: "Entrez la date de sortie de l'hôpital",
    errorMissingStartDate: 'Entrez la date de début',
    errorMissingEndDate: 'Entrez la date de sortie',
    errorMissingDeathDate: 'Entrez la date du décès',
    errorMissingEventDate: 'Entrez la date du sinistre',
    successClaimSubmit: 'Sinistre soumis avec succès',
    submitMakeClaims: 'Reporter',
    nextMakeClaims: 'Suivant',
    backMakeClaims: 'Précédent',
    resetMakeClaims: 'Cliquez ici pour réinitialiser',
    claimDetailsTitle: 'Détails du sinistre',
    claimSummaryTitle: 'Résumé',
    claimResultsTitle: 'Résultats',
    personAffected: 'Personne concernée',
    personAffectedDob: 'Date de naissance de la personne concernée',
    relationToMainMember: "Affiliation à l'assuré",
    CustomerFullName: "Nom complet du client ",
    hospitalAdmissionDate: "Date d'entrée à l'hôpital",
    hospitalDischargeDate: "Date de sortie de l'hôpital",
    deathDate: 'Date du décès',
    eventDate: 'Date du sinistre',
    claimDateUINotFound:
      'Rien de trouvé pour afficher la date pour ce type de sinistre',
    benefits: 'Avantages',
    benefit: 'Avantage',
    nights: 'Nuits',
    nightlyBenefit: 'Avantage par Nuit',
    nightlyBenefitPercent: 'Avantage par Nuit (%)',
    fixedBenefit: 'Avantage de Décès',
    fixedBenefitPercent: 'Avantage de Décès (%)',
    propertyAssistanceBenefit: 'Assistance de propriété',
    propertyAssistanceBenefitPercent: 'Assistance de propriété (%)',
    incomeLossBenefit: 'Assurance gain',
    incomeLossBenefitPercent: 'Assurance gain (%)',
    total: 'Total',
    claimReference: 'Référence de sinistre',
    receivedTime: "Heure de réception",
    claimStatus: 'Statut du sinistre',
    defaultSelection: 'Sélectionnez',
    errorValidBirthCertificate: "L'acte de naissance doit être valide",
    errorMissingHospitalizationReason:
      "Veuillez fournir une raison d'hospitalisation",
    errorMissingHospitalName: "Veuillez fournir le nom de l'hôpital",
    errorMissingDocChannel:
      'Sélectionnez la façon dont les pièces justificatives ont été soumises',
    errorMissingVerification: 'Sélectionnez le moyen de vérification',
    errorMissingDocReceivedTime:
      "Sélectionnez lorsque la documentation à l'appui a été soumise",
    errorMissingAffectedPersonDob:
      'Sélectionnez la date de naissance de la personne concernée',
    errorMissingCondition: 'Veuillez fournir la condition du sinistre',
    errorMissingAccident:
      "Veuillez choisir s'il s'agit d'une réclamation pour accident",
    hospital: 'Hospital',
    hospitalClaimDetails: "Détails du sinistre d'hospitalisation",
    funeralClaimDetails: 'Détails du sinistre funéraires',
    incomeLossClaimDetails: 'Détails du sinistre perte de revenue',
    propertyClaimDetails: 'Détails du sinistre bien immobilier',
    patientDateOfBirth: 'Date de naissance du patient',
    deceasedDateOfBirth: 'Date de naissance du décédé',
    hospitalisationReason: "Motif de l'hospitalisation",
    conditionValue: 'Raison détails',
    deathCause: 'Causes du décès (si disponibles)',
    highRisk: 'Risque élevé',
    documentReceived: 'Document reçu',
    documentReceivedVia: 'Document reçu via',
    birthCertificateValid: 'Acte de naissance valide',
    documentationReceivedTime: 'Documentation requise reçue',
    verificationReceived: 'Vérification reçue',
    approveClaimBtn: 'Approuver le sinistre',
    markAsPaidBtn: 'Marquer comme payé',
    markAsUnpaidBtn: 'Marquer comme impayé',
    payClaimBtn: 'Payer le sinistre',
    revertRejection: 'Annuler le rejet',
    revertApproval: 'Annuler',
    rejectClaimBtn: 'Rejeter le sinistre',
    reverseClaimBtn: 'Annuler Decision',
    confirmRejectionBtn: 'Confirmer le rejet',
    claimValidPolicies: 'Couverture correspondante',
    claimsOverview: 'Aperçu de Sinistres',
    claimAmount: 'Montant du sinistre',
    saveForLater: 'Enregistrer',
    noClaimSelected: "Sélectionnez un sinistre d'abord",
    loyaltyOpOut: 'Désactiver le produit gratuit',
    loyaltyOpIn: 'Opter pour le produit gratuit',
    acceptsLoyalty: 'A opter pour le produit gratuit',
    nationalId: "Carte d'identité",
    errorNationalIdMissing: "Veuillez fourning une carte d'identité",
    createdAt: 'Reçu à',
    paymentDashboard: 'Tableau de bord de paiement',
    RequestType: "type de demande ",
    Message: " Message",
    MNOStatus: "MNO  Statut ",
    MNOMessage: " MNO Message",
    ShowLessSMS: "Afficher moins de SMS",
    ShowMoreSMS: "Afficher plus de SMS",
    Nosmsavailable: "Aucun sms disponible ",
    DeletingDocument: "Suppression d'un document...",
    Areyousureyouwanttodeletethisdocument: "Êtes-vous sûr de vouloir supprimer ce document? ",
    Documentdeleted: "Document supprimé",
    Errordeletingdocument: "Erreur lors de la suppression du document ",
    Tryagain: "Essayer à nouveau",
    Couldnotdeletedocument: "Impossible de supprimer le document",
    Draganddropfileshere: "Glissez et déposez les fichiers ici",
    or: "OU",
    Clicktoupload: "Cliquez pour télécharger",
    Uploadingfile: "Téléchargement du fichier",
    Successfullyuploadedfile: "Fichier téléchargé avec succès",
    errorswithupload: "erreurs lors du téléchargement",
    UploadDocuments: "Télécharger des documents",
    uploadDocumentSubheading: "Remplissez les champs ci-dessous pour ajouter des documents ",
    DocumentType: "Type de document",
    docTypeError: "Veuillez choisir le type de document",
    ImDone: "J'ai fini",
    DeleteDocument: "Supprimer un document",
    viewDocument: "Afficher le document",
    FileName: "Nom de fichier",
    showLessClaims: "Afficher moins de réclamations",
    showMoreClaims: " Afficher plus de réclamations",
    noClaimsFound: "Aucune réclamation disponible",
    showMoreDocuments: "Afficher plus de documents",
    showLessDocuments: "Afficher moins de documents",
    noDocsFound: "Documents introuvables, veuillez télécharger",
    updateCustomer: 'Mettre à jour le client',
    editCustomerDetailsHeading: 'Modifier les détails du client',
    editCustomerInformation: 'Modifier les informations client',
    customerRegistrationError: 'Erreur lors de l enregistrement du client',
    customerRegistrationRequest: 'Demande d inscription client soumise',
    registeringCustomer: 'enregistrement du client',
    clickToChangePassword: 'Cliquez ici pour réinitialiser le mot de passe',
    passwordResetLink: 'Le lien de réinitialisation du mot de passe a été envoyé à l e-mail suivant:',
    fullNameErrorMessage: 'Veuillez entrer le nom complet',
    dateOfBirthErrorMessage: 'Veuillez entrer la date de naissance',
    languageSelectError: 'Veuillez sélectionner la langue',
    pleaseEnterValid: 'Veuillez entrer un valide',
    editDetails: 'Modifier les détails',
    profileDetails: 'Détails du profil',
    viewAnalytics: 'Afficher les analyses',
    viewClaims: 'Afficher les réclamations',
    viewReports: 'Afficher les rapports',
    hello: 'Bonjour',
    welcomeBack: 'content de te revoir',
    customerStatus: 'Statut du client',
    registrationChannel: 'Canal d inscription',
    passwordConfirmNotMatching: 'Les mots de passe ne correspondent pas',
    createUser: 'Créer un utilisateur',
    requiredInput: 'obligatoire et ne peut débuter avec un espace',
    requiredNoSpace: 'obligatoire et ne peut inclure un espace',
    passwordConstraints:
      'Le mot de passe doit avoir une longueur de 8 à 30, au moins une minuscule, une majuscule, un chiffre et un caractère spécial',
    changePassword: 'Modifier le mot de passe',
    firstName: 'Prenom',
    lastName: 'Nom',
    surname: 'Nom',
    email: 'Email',
    roles: 'Rôles',
    partners: 'Partenaires',
    partner: 'Partenaire',
    confirmPassword: 'Confirmez le mot de passe',
    newPassword: 'Nouveau mot de passe',
    usersList: 'Liste des utilisateurs',
    userAdministration: 'Administration des utilisateurs',
    viewUsers: 'Afficher les utilisateurs',
    errorMissingFirstName: 'Prénom ne doit pas être vide',
    errorMissingLastName: 'Nom ne doit pas être vide',
    errorMissingSurname: 'Nom ne doit pas être vide',
    errorMissingFullName: 'Nom entier ne doit pas être vide',
    errorMissingDateOfBirth: 'Date de naissance ne doit pas être vide',
    errorIncorrectID: "Carte d'identité incorrecte",
    hasWallet: 'A compte bancaire',
    agent: 'Agent',
    isAgent: 'est un agent',
    BeneficiaryNumber: 'Numéro de bénéficiaire',
    BeneficiaryName: ' Nom du bénéficiaire',
    yes: 'Oui',
    no: 'Non',
    lang: 'Langue',
    rejectionReason: 'Raison du rejet',
    rejectionReasonHint: 'Choisir une raison',
    hours: 'h',
    days: 'j',
    timeSinceCreation: 'Depuis creation',
    timeSinceDocReceived: 'Dernier Document reçu',
    claimComment: 'Commentaire',
    uploadDocumentReceived: 'Télécharger document reçu',
    UploadDocument: "Télécharger un document",
    debit: 'Debit',
    getKyc: 'Obtenir des données client',
    claimRejectionReason: 'Raison de rejet',
    file: 'Fichiers',
    paymentAttempt: 'Payment essayé',
    paymentAttemptTitle: 'Payment essayé',
    titleNoFiles: 'Aucun fichier',
    titleNoPayments: 'Aucun paiments essayés',
    claimFinalisation: 'Sinistres Conclusion',
    generateReport: 'Générer rapport',
    downloadReport: 'Télécharger le rapport',
    date: 'Date',
    registrationReport: 'Inscriptions',
    claimFinalisationReport: 'Sinistres consolidés',
    premiumReport: 'Primes',
    dailyReport: "Rapports quotidiens",
    loyaltyRenewalButton: 'Renouveller la fidélisation',
    loyaltyRenewalSubmit: 'Renouveller',
    loyaltyRenewalConfirm:
      'Je comprends et confirme vouloir renouveler la fidélisation.',
    loyaltyRenewal: 'Renouvellement de fidélisation',
    loyaltyUpload: 'Téléchargement de fidélisation',
    updateProfileTitle: 'Modifier le profil',
    updateProfileSubmit: 'Mettre a jour',
    updatePasswordSubmit: 'Changer le mot de passe',
    outcome: 'Résultat',
    notes: 'Remarques',
    index: 'No',
    filename: 'Fichier',
    profileTitle: 'Details',
    currentOpenClaims: 'Réclamations ouvertes actuelles',
    claimHistory: 'Historique des réclamations du client',
    removeDebitOrder: "Supprimer l'ordre de débit",
    removeDebitOrderConfirmation:
      "Confirmer le retrait de l'ordre de prélèvement",
    removeDebitOrderMessage:
      'Êtes-vous sûr de vouloir rembourser cet ordre de débit?',
    debitOrderSummary: 'Récapitulatif de la commande de débit',
    policy: 'Politique',
    reference: 'Référence',
    bankAccount: 'Compte bancaire',
    day: 'journée',
    bankAccountHint: 'Entrez le numéro de compte bancaire',
    bankAccountFloating: 'Numéro de compte bancaire',
    amountHint: 'Entrez le montant en cents',
    amountFloatingText: 'Montant en cents',
    debitOrderHint: "Entrez un jour de l'ordre de débit (1-28)",
    debitOrderFloating: 'Jour de commande de débit',
    startDateHint: 'Sélectionnez une date de début',
    startDateFloat: 'Date de début',
    getProgress: 'Obtenir le progrès',
    createDebitOrder: 'Créer un ordre de débit',
    msisdnHint: 'Entrez le client msisdn',
    dailyDebitOrder: 'Transactions quotidiennes des ordres de débit',
    markSuccess: 'Marquer comme un succès',
    markFailed: 'Marquer comme échoué',
    downloadCsv: 'Télécharger en tant que csv',
    retries: 'Réessais',
    policiesReport: 'Politiques',
    mobileMoneyConfirmation:
      'Un paiement a été initié, le client sera invité à entrer le code pour completer la transaction.',
    cardPaymentConfirmation:
      'Un paiement par carte a été demandé, le client recevra un SMS avec un lien pour entrer ses détails de paiement par carte pour traiter le paiement.',
    pay: 'Payer',
    initiatePayment: 'Lancer le paiement',
    mnoStatus: 'MNO Statut',
    moreDebitOrder: "Plus d'historique de commandes de débit",
    dragAndDrop: 'Glissez et déposez les fichiers ici',
    Or: 'Ou',
    clickUpload: 'Cliquez pour télécharger',
    uploadProgress: "Téléchargement en cours, n'interrompez pas",
    uploadMore: 'Cliquez pour télécharger plus',
    uploadSuccess: 'Téléchargement terminé avec succès',
    uploadFailure:
      "Malheureusement, quelque chose s'est mal passé pendant le téléchargement :(",
    uploadStatus: 'Statut de téléchargement',
    refundReport: 'Rapport de remboursement',
    refund: 'Rembourser',
    vasReport: 'Agents VAS',
    reportAgentWeb: 'Agents Web',
    count: 'Nombre',
    reportPayableClaims: 'Sinistres Valides',
    reportSuspendedPolicies: 'Couvertures Suspendues',
    refresh_token: 'Rafraîchir',
    dashboard: 'Tableau de bord',
    enterUsername: "Saisissez votre nom d'utilisateur",
    enterPassword: 'Entrer le mot de passe',
    invalidUsername: "nom d'utilisateur invalide",
    invalidPassword: 'entrer le mot de passe',
    kyc_success_edit_message:
      "Demande réussie, l'utilisateur KYC devrait être mis à jour en 2 minutes",
    recoveryPasswordMsg:
      "L'e-mail de récupération a été envoyé avec succès. Vérifiez vos e-mails et suivez les instructions pour réinitialiser. Revenez à l'application pour vous connecter une fois terminé.",
    recoverPassword: 'Récupérer mot de passe',
    buyPolicy: 'Acheter une police',
    makeAClaim: 'Faire une demande',
    nothingFound: "Rien n'a été trouvé :(",
    loyalty: 'Fidélité',
    partialPaymentPlaceHolder: 'Montant du Paiement',
    partialPaymentPlaceHolderError: 'Veuillez saisir un montant de paiement valide',
    paymentReference: "Référence de paiement",
    paymentReferenceError: "Veuillez saisir la référence de paiement",
    strikeInitiatedTitle: 'Collection de Prime',
    cardPaymentInitiatedTitle: 'Paiement par carte initié',
    strikeInitiatedButton: 'Ok',
    strikeInitiatedButtonConfirm: 'Confirmer',
    balance: 'Balance',
    oldSession: 'Votre session a expiré. Veuillez vous reconnecter.',
    retryClaimPayment: 'Réessayer',
    retryClaimPaymentBtnLabel: 'Réessayer',
    markClaimPaymentAsFailedBtnLabel: 'Marquer comme échoué',
    markClaimPaymentAsSucceededBtnLabel: 'Marquer comme réussi',
    approvedClaimWarning:
      "Puisque ce sinistre est pendant une période d'attente, veuillez confirmer que la cause est accidentelle",
    approvedClaimWarningButtonAccept:
      "Je confirme que c'était dû à un accident",
    approvedClaimWarningButtonReject: 'Annuler et revenir au sinistre',
    highRiskWarningButtonAccept: 'je confirme',
    highRiskWarningButtonReject: 'Annuler et revenir à la revendication',
    approveClaimWithHighRiskHospital:
      ' est marqué comme étant à haut risque. Veuillez confirmer ou refuser de continuer',
    bulkSms: 'Sms en vrac',
    bulkPayments: 'Paiements groupés',
    claimEditor: 'Assesseur:',
    internalDashboard: 'Tableau de bord interne',
    selectHospital: 'Sélectionnez un hôpital',
    claims_paid_report: 'Sinistres payé',
    claims_notification_report: 'Notifications de sinistre',
    claims_register_report: 'Inscription de sinistre',
    premiums_register_report: 'Inscription de prime',
    effectedAt: 'Reçu le',
    effectedAtError: "Veuillez entrer la date d'effet",
    addMissingPayment: 'Ajouter le paiement manquant',
    missingPaymentAmountRequiredError: 'Le montant est requis',
    missingPaymentMinAmountError: 'Le montant doit être supérieur à 100',
    missingVaryingPaymentMinAmountError: 'Le montant doit être supérieur à ',
    missingPaymentEffectedDateError: "Date d'effet requise",
    missingPaymentMnoReferenceError: 'Référence MNO requise',
    missingPaymentPolicyError: 'Politique est nécessaire',
    error: "Erreur",
    claim_condition: 'Raison',
    highRiskCustomer:
      "Comme cette réclamation est payée pour un client à haut risque, veuillez confirmer que le cas a été vérifié et qu'il n'est pas frauduleux.",
    highRiskCustomerApproveBtn:
      "Je confirme que cette demande n'est pas frauduleuse",
    highRiskCustomerRejectBtn: 'Annuler et revenir à réclamer',
    paginationNextText: 'suivant',
    paginationPreviousText: 'précédent',
    updateClaimDetails: 'Mettre à jour les détails de la réclamation',
    smsBatchBeingProcessed: 'Le lot de SMS est en cours de traitement: #',
    noDelimiter: "Le délimiteur n'est pas défini.",
    noMsisdnColumn: "La colonne de numéro de téléphone n'est pas définie.",
    noTextColumnOrDefault: "La colonne ou le message texte n'est pas défini.",
    msisdnColumnHelp:
      'Tapez le numéro de colonne correspondant aux numeros de telephone.\r\nSi le nom de la colonne est alphabétique, tapez le numéro d’alphabet correspondant, par exemple: pour A tapez 1.',
    textColumnHelp:
      'Tapez le numéro de colonne correspondant aux messages textes.\r\nSi le nom de la colonne est alphabétique, tapez le numéro d’alphabet correspondant, par exemple: pour A tapez 1.',
    cellContentHelp:
      'Chaque valeur dans un fichier CSV / TXT doit être entourée de "".\r\n',
    batchNumber: 'Numéro de lot',
    totalSms: 'Total SMS',
    invalidSms: 'Invalides SMS',
    completeAt: 'Terminer à',
    getBatchSumary: 'Obtenir le résumé',
    delimiter: 'Délimiteur',
    useDefaultText: 'Utiliser le texte par défaut',
    textColumn: 'Colonne de texte',
    textContent: 'Contenu du texte',
    import: 'Importer',
    importSms: 'Importer des SMS',
    previousBatches: 'Lost Précédents',
    revertExpiredClaim: 'Réactiver la réclamation expirée',
    languages: 'Langages',
    code: 'Code',
    name: 'Nom',
    country: 'Pays',
    partnerName: 'Nom',
    createPartner: 'Créer un partenaire',
    partnerCode: 'Code',
    selectCountry: 'Sélectionnez un pays',
    defaultLanguage: 'Langue par défaut',
    insurer: 'Assureur',
    serviceName: 'Non du service',
    ussdShortCode: 'Code USSD',
    whatsAppNumber: 'Numéro de WhatsApp',
    callCentreNumber: "Numéro du centre d'appel",
    ok: 'Ok',
    productCode: 'Code du produit',
    productType: 'Type de produit',
    productCoverType: 'Type de couverture',
    amountHospitalBenefit: 'Somme du benefice hospitalier',
    fixedIndemnityAmountBenefit: "Somme fixe d'indémmisation",
    minimumAge: 'Age Minimal',
    maximumAge: 'Age Maximal',
    coolOffPeriodHours: 'Période de récupération (Heure)',
    waitingPeriodDays: "Période d'attente (Jour)",
    maximumHospitalBenefitDays: 'Jours maximales de benefices hospitalier',
    minimumHospitalBenefitDays: 'Jours minimales de benefices hospitalier',
    eligibilityThreshold: "Seuil d'éligibilité",
    debitGraceDays: 'Jours de grâce',
    createProduct: 'Créer un produit',
    selectProductType: 'Sélectionnez le type de produit',
    selectProductCoverType: 'Sélectionnez le type de couverture',
    selectGranularity: 'Sélectionnez la granularité du produit',
    cardinality: 'Cardinalité',
    paymentMethod: 'Mode de paiement',
    selectPaymentMethod: 'Sélectionnez le mode de paiement',
    products: 'Produits',
    selectPartner: 'Sélectionnez un partenaire',
    premiumCount: 'Nombre de primes',
    viewProductPremium: 'Consulter une prime',
    granularity: 'Granularité',
    to: 'pour',
    coolOffPremium: 'Premium en période de refroidissement',
    coolOffPremiumText: "Une prime ne peut être remboursée qu'après",
    canReplyTo: 'Reponse expectée',
    full_hours: 'heures',
    dispatchAt: 'À expédier à',
    sent: 'Envoyées',
    onGoingBlastSummary:
      "Remarque: certains messages ont déjà été traités pour l'envoi." +
      ' Un nombre {alreadySent} de messages sera envoyé aux clients.' +
      ' {notSent} nombre de messages sera arrêté',
    addRemittances: 'Ajouter des envois de fonds',
    uploadRemittancesCsv: 'Télécharger le fichier CSV',
    titleRemittances: 'Envois de fonds',
    fromFile: "D'un fichier",
    allRegisteredCustomer: 'Clients enregistrés',
    activeCustomersWithLoyalty: 'Clients fidèles',
    activeCustomersWithUpSell: 'Clients UpSell',
    customersWithNotTakenUp: "Preneurs d'assurance non souscrits",
    suspendedCustomers: 'Clients suspendus',
    currentUnregisteredLoyalty: 'Clients fidèles non-enregistrés ce mois',
    importSmsBlast: 'Envoyer Sms en vrac',
    confirmImportSmsBlast:
      "Vous etez sur le point d'initialiser un envoie de SMS.\n Etes vous sur de vouloir continuer",
    cashback: 'Remise en argent',
    paymentStatus: 'Statut',
    paid: 'Payé',
    notPaid: 'Impayé',
    paymentFailureReason: "Raison de l'échec de paiement",
    home: 'Acceuil',
    welcomeToAspin: 'Bienvenue sur ASPin',
  },
};
