import React, { useState, useEffect } from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AlertDialog from '../../../../../components/AlertDialog/index.es6';
import { CircularProgress } from 'material-ui';
import { TextValidator, SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
import MenuItem from '@mui/material/MenuItem';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import PriceChangeOutlinedIcon from '@mui/icons-material/PriceChangeOutlined';
import PropTypes from 'prop-types';
import ChangePolicy from './ChangePolicy';
import Autocomplete from '@mui/material/Autocomplete';
import { getPartnerGuid, getUserEmail, getUserFullname, getUserGuid } from '../../../../../lib/access.es6';
import { RELATIONSHIP_POOL } from '../../../../../containers/NewProductList/PoductConstants';
import { BankData } from '../../../lib/AccessBankBranches';
import posthog from 'posthog-js';
import { find } from 'lodash';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <section sx={{ p: 3 }}>
          <div>{children}</div>
        </section>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const Buypolicy = (props) => {

  const [beneficiaryName, setBeneficiaryName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [beneficiaryDateOfBirth, setBeneficiaryDateOfBirth] = useState('')
  const [beneficiaryRelationship, setBeneficiaryRelationship] = useState('')
  const [applicationDate, setApplicationDate] = useState('')
  const [selectedAgent, setSelectedAgent] = useState(null);

  const [openBranches, setOpenBranches] = useState(false);
  const [inputBranchValue, setInputBranchValue] = useState('');
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedBranchError, setSelectedBranchError] = useState(false);

  const [tabvalue, setTabValue] = useState(props.quoteToReCreate ? 1 : 0);

  const handleSetTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleBranchInputChange = (event, newValue) => {
    setSelectedBranch(newValue)
    setSelectedBranchError(!newValue)
    setInputBranchValue(newValue);
  };


  const getBeneficiary = () => {
    
    if(props.createdQuote && props.createdQuote.product_name === 'Family Clan Cover'){
      const policyEntranteries = props.createdQuote.policy_schedule_entries;
      const principal = find(policyEntranteries, (entry) => entry.dependant.relationship === "Principal");
  
      if (principal) {
        setBeneficiaryName(principal.dependant.first_name + " " + principal.dependant.middle_name + " " + principal.dependant.last_name);
        setPhoneNumber(principal.dependant.msisdn);
        setBeneficiaryRelationship(principal.dependant.relationship);
        setBeneficiaryDateOfBirth(principal.dependant.date_of_birth)
      }
    
    }else if(props.createdQuote && props.createdQuote.product_name === 'Motshelo Group Cover'){
      const policyEntranteries = props.createdQuote.policy_schedule_entries;
      const principal = find(policyEntranteries, (entry) => entry.dependant.relationship === "Principal");
  
      if (principal) {
        setBeneficiaryName(principal.dependant.first_name + " " + principal.dependant.middle_name + " " + principal.dependant.last_name);
      }
    
    } else {
      const policyEntranteries = props.createdQuote.policy_schedule_entries;

      for (let item of policyEntranteries) {
        const dependant = item.dependant
        if (dependant.relationship === "Wife" || dependant.relationship === "Spouse") {
          setBeneficiaryName(dependant.first_name + " " + dependant.middle_name + " " + dependant.last_name);
          setPhoneNumber(dependant.msisdn);
          setBeneficiaryRelationship(dependant.relationship);
          setBeneficiaryDateOfBirth(dependant.date_of_birth)
          return dependant
        }
      }
      return null

    }


  }

  useEffect(() => {
    getBeneficiary();
  }, [props.createdQuote]);


  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "beneficiaryName") {
      setBeneficiaryName(value)
    }

    if (name === "phonenumber") {
      setPhoneNumber(value)
    }
    if (name === "beneficiary_date_of_birth") {
      setBeneficiaryDateOfBirth(value)
    }
    if (name === "beneficiaryRelationship") {
      setBeneficiaryRelationship(value)
    }
    if (name === "applicationDate") {
      setApplicationDate(value)
    }

  }

  const classes = useStyle();

  const reset = () => {
    setBeneficiaryName('');
    setPhoneNumber('');
  }

  const web_agent_guid = getUserGuid();

  const handleBuyPolicy = () => {

    if (!selectedBranch) {
      selectedBranchError(true)
      return
    }

    const motsheloPayload = {
      "customer_guid": props.createdQuote.customer_guid,
      "quotation_guid": props.createdQuote.guid,
      "partner": props.createdQuote.partner,
      "beneficiary_name": beneficiaryName,
      "web_agent_guid": web_agent_guid,
      "debit": true,
      "payment_method": "Mobile_Money",
      "additional_details": {
        "application_date": applicationDate,
        "user_agent": selectedAgent ? selectedAgent.firstName + " " + selectedAgent.lastName : 'n/a',
        "agent": selectedAgent ? selectedAgent : 'n/a',
        "branch": selectedBranch,
        "maker_email": getUserEmail(),
      }
    }

    const payload = {
      "customer_guid": props.createdQuote.customer_guid,
      "quotation_guid": props.createdQuote.guid,
      "partner": props.createdQuote.partner,
      "beneficiary_name": beneficiaryName,
      "beneficiary_msisdn": phoneNumber,
      "web_agent_guid": web_agent_guid,
      "debit": true,
      "beneficiary_date_of_birth": beneficiaryDateOfBirth ? beneficiaryDateOfBirth : null,
      "payment_method": "Mobile_Money",
      "additional_details": {
        "application_date": applicationDate,
        "beneficiary_relationship": beneficiaryRelationship,
        "user_agent": selectedAgent ? selectedAgent.firstName + " " + selectedAgent.lastName : 'n/a',
        "agent": selectedAgent ? selectedAgent : 'n/a',
        "branch": selectedBranch,
        "maker_email": getUserEmail(),
      }
    }
    if(props.createdQuote && props.createdQuote.product_name === 'Motshelo Group Cover'){
      props.buyPolicy(motsheloPayload);
    }else {
      props.buyPolicy(payload);
    }
    reset();

    posthog.capture('buy_policy', {
      email: `${getUserEmail()}`,
      name: `${getUserFullname()}`,
      partner: `${getPartnerGuid()}`,
      timestamp: new Date().toLocaleString(),
    });
  }

  const handleBuyPolicySuccessfully = () => {
    props.resetBuyPolicySuccessAlert();
    posthog.capture('buy_policy_success', {
      email: `${getUserEmail()}`,
      name: `${getUserFullname()}`,
      partner: `${getPartnerGuid()}`,
      timestamp: new Date().toLocaleString(),
    });
  }

  const handlenextStep = () => {
    props.handleNext();
  }

  const handleBuyPolicyError = () => {
    props.resetBuyPolicyErrorAlert()
    posthog.capture('buy_policy_error', {
      email: `${getUserEmail()}`,
      name: `${getUserFullname()}`,
      partner: `${getPartnerGuid()}`,
      timestamp: new Date().toLocaleString(),
    });
  }



  return (
    <>

      <AlertDialog
        custom
        show={props.showBuyPolicyProgressAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2> Purchasing Policy.. </h2>
      </AlertDialog>

      <AlertDialog
        success
        show={props.showBuyPolicySuccessAlert}
        size="sm"
        title={'Purchase  Successfuly Made'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handleBuyPolicySuccessfully() }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        confirmBtnCssClass
      >
        <div >
          <Button variant="contained" onClick={() => { handlenextStep() }} style={{ background: 'green', }}>Add Bank Details</Button>
        </div>
      </AlertDialog>

      <AlertDialog
        show={props.showBuyPolicyErrorAlert}
        danger
        title={'Error Purchasing Policy'}
        onConfirm={() => handleBuyPolicyError()}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {props.buyPolicyError ? props.buyPolicyError.message : ''}
      </AlertDialog>
      <section className={classes.buypolicywrapper}>
        <Tabs
          value={tabvalue}
          onChange={handleSetTabChange}
          aria-label="buy or change quote"
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          className={classes.buypolicytabs}
        >

          <Tab icon={<ShoppingCartOutlinedIcon />} iconPosition="start" label="Buy Policy" {...a11yProps(0)} style={{
            background: "#032A37",
            borderRadius: "8px",
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "15px",
            boxShadow: "none",
            padding: "10px",
            marginRight: "20px",
            textTransform: "capitalize",
            color: "#fff",
            height: "0",
            minHeight: "45px"
          }} />
          <Tab icon={<PriceChangeOutlinedIcon />} iconPosition="start" label="Change Quote" {...a11yProps(1)} style={{
            background: "#032A37",
            borderRadius: "8px",
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "15px",
            boxShadow: "none",
            padding: "10px",
            marginRight: "20px",
            textTransform: "capitalize",
            color: "#fff",
            height: "0",
            minHeight: "45px"
          }} />
        </Tabs>

        <TabPanel value={tabvalue} index={0}>
          <ValidatorForm onSubmit={() => handleBuyPolicy()} >
            <Grid container spacing={2} style={{ marginTop: "10px" }}>

              { props.createdQuote && props.createdQuote.product_name !== 'Motshelo Group Cover' && 
              <>
              <Grid item xs={12} sm={6} md={6}>
                <TextValidator
                  id="Fullname"
                  onChange={handleChange}
                  name="beneficiaryName"
                  fullWidth
                  label="Beneficiary Name"
                  value={beneficiaryName}
                  variant="outlined"
                  validators={['required']}
                  errorMessages={['Please enter full name']}
                  style={{ width: "100%" }}
                />


              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  id="beneficiary_date_of_birth"
                  onChange={handleChange}
                  name="beneficiary_date_of_birth"
                  type='date'
                  fullWidth
                  label="Beneficiary Date Of Birth"
                  value={beneficiaryDateOfBirth}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextValidator
                  id="phonenumber"
                  onChange={handleChange}
                  name="phonenumber"
                  fullWidth
                  label="Phone Number"
                  value={phoneNumber}
                  variant="outlined"
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>

                <SelectValidator
                  labelId="select-relationship"
                  id="select-relationship"
                  value={beneficiaryRelationship}
                  name='beneficiaryRelationship'
                  type="text"
                  label="Beneficiary Relationship"
                  onChange={handleChange}
                  variant="outlined"
                  validators={['required']}
                  errorMessages={['Please select beneficiary relationship']}
                  style={{ width: "100%" }}
                  sx={{
                    fontFamily: "Open Sans",
                    fontStyle: "normal",
                    fontWeight: 300,
                    fontSize: "12px",
                    lineHeight: "16px",
                    letterSpacing: "0.005em",
                    color: "#000000",
                  }}
                >
                  {Object.entries(RELATIONSHIP_POOL).map(([key, value]) => (
                    <MenuItem
                      key={key}
                      value={value}
                      sx={{
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        fontWeight: 300,
                        fontSize: "12px",
                        lineHeight: "16px",
                        letterSpacing: "0.005em",
                        color: "#000000",
                      }}
                    >
                      {value}
                    </MenuItem>
                  ))}
                </SelectValidator>

              </Grid></>}


              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  id="applicationDate"
                  onChange={handleChange}
                  name="applicationDate"
                  type='date'
                  fullWidth
                  label="Application Date"
                  value={applicationDate}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} md={6} sm={6}>
              <Autocomplete
                options={props.agentUsersData}
                getOptionLabel={(user) => `${user.firstName} ${user.lastName}`}
                getOptionSelected={(option, value) => option.id === value.id}
                onChange={(event, value) => {
                  setSelectedAgent(value)
                }}
                value={selectedAgent}
                renderInput={(params) => (
                  <TextField {...params} label="Select Agent" variant="outlined" style={{ width: "100%"}} />
                )}

              />
            </Grid>

            <Grid item xs={12} >
                  <Autocomplete
                    open={openBranches}
                    onOpen={() => {
                      setOpenBranches(true);
                    }}
                    onClose={() => {
                      setOpenBranches(false);
                    }}
                    ListboxProps={{
                      sx: {
                        "& li": {
                          fontFamily: 'Open Sans',
                          fontStyle: "normal",
                          fontSize: "12px",
                          lineHeight: "25px",
                          color: "#000000",
                        },
                      }
                    }}
                    id="branch"
                    name='branch'
                    options={BankData}
                    //getOptionLabel={(option) => `${option.name} (${BankData.find(bank => bank.branch_details.includes(option)).bank_name})`}
                    inputValue={inputBranchValue}
                    onInputChange={handleBranchInputChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Branch"
                        variant="outlined"
                        error={selectedBranchError}
                        helperText={selectedBranchError ? 'Please select a branch' : ''}
                      />
                    )}
                  />
                </Grid>

            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <div className={classes.buypolicybtn}>
                  <Button
                    variant="contained"
                    size="large"
                    type='submit'
                  >
                    Buy Policy
                  </Button>
                </div>
              </Grid>
            </Grid>
          </ValidatorForm>
        </TabPanel>

        <TabPanel value={tabvalue} index={1} >
          <ChangePolicy
            changeQuote={props.changeQuote}
            createdQuote={props.createdQuote}
            policies={props.policies}
            changeQuoteError={props.changeQuoteError}
            changeQuoteProgressAlert={props.changeQuoteProgressAlert}
            changeQuoteSuccessAlert={props.changeQuoteSuccessAlert}
            changeQuoteErrorAlert={props.changeQuoteErrorAlert}
            resetChangeQuoteSuccessAlert={props.resetChangeQuoteSuccessAlert}
            resetChangeQuoteErrorAlert={props.resetChangeQuoteErrorAlert}
            quoteToReCreate={props.quoteToReCreate}
          />
        </TabPanel>


      </section>
    </>
  )
}

export default Buypolicy