import React from 'react'
import { useStyle } from "./Style";
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ButtonInc from '../ButtonInc';
import CustomerDetails from './Components/CustomerDetails/CustomerDetails';
import Policies from './Components/Policies/Policies'
import Payments from './Components/Payments/Payments';
import Claims from './Components/Claims/Claims';

import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SourceOutlinedIcon from '@mui/icons-material/SourceOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';


const pages = [
  {
    name: "CustomerDetails",
    label: "Customer Details",
    url: "admin/customer_status"
  },

  {
    name: "Policies",
    label: "Policies",
    url: "admin/policies"
  },
  {
    name: "Payments",
    label: "Payments",
    url: "admin/payments"
  },
  {
    name: "Claims",
    label: "Claims",
    url: "admin/claims"
  },

]

const actions = [
  { icon: <InventoryOutlinedIcon sx={{color: "#fff"}} fontSize='10px' />, name: 'Make a Claim'  },
  { icon: <SourceOutlinedIcon sx={{color: "#fff"}} fontSize='10px' />, name: 'Buy a Policy' },
];

const MobileCustomerProfileInc = (props) => {
  const classes = useStyle();


  const [pageSelect, setSelectPage] = React.useState('CustomerDetails');
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const handleChange = (event) => {
    setSelectPage(event.target.value);

  };


  const renderContent = () => {
    switch (pageSelect) {
      case 'CustomerDetails':
        return <CustomerDetails {...props} />;
      case 'Policies':
        return <Policies {...props} />;
      case 'Payments':
        return <Payments {...props} />;
      case 'Claims':
        return <Claims {...props} />;
      default:
        return <div>Customer details default</div>;
    }
  }
  const renderContentButton = () => {
    switch (pageSelect) {
      case 'CustomerDetails':
        return (<ButtonInc>Register customer</ButtonInc>);
      case 'Policies':
        return (<ButtonInc style={{ height: "50px" }}>Buy a  Policy</ButtonInc>);
      case 'Payments':
        return (<ButtonInc style={{ height: "50px" }}>Buy Policy</ButtonInc>);
      case 'Claims':
        return (<ButtonInc style={{ height: "50px" }}>Make Claim</ButtonInc>);
      default:
        return null;
    }
  }

  return (
    <>
      <section className={classes.navigationWrapper}>
        <Grid container spacing={2} >
          <Grid item xs={7} >

            <Box className={classes.navigation}>
              <FormControl fullWidth >
                <InputLabel id="select-page">Select Page</InputLabel>
                <Select
                  labelId="select-page"
                  id="select-page"
                  value={pageSelect}
                  label="Select Page"
                  onChange={handleChange}
                  sx={{
                    height: "50px",
                    fontFamily: "Open Sans",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "15px",
                    color: "#000000",
                  }}
                >
                  {
                    pages.map((page, index) => (<MenuItem style={{
                      fontFamily: "Open Sans",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "15px",
                      color: "#000000",
                    }} key={index} value={page.name}>{page.label}</MenuItem>))
                  }
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={5}>
            {
              pageSelect && renderContentButton()
            }
          </Grid>
        </Grid>
      </section>

      {
        pageSelect && renderContent()
      }

      <Box sx={{ transform: 'translateZ(0px)', flexGrow: 1 }} className={classes.actionFab}>
        <SpeedDial
          ariaLabel="SpeedDial tooltip example"
          sx={{ position: 'fixed', bottom: 16, right: 16, }}
          icon={<SpeedDialIcon  />}
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              tooltipOpen
              onClick={handleClose}
         
            />
          ))}
        </SpeedDial>
      </Box>


    </>
  )
}

export default MobileCustomerProfileInc