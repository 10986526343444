import React from "react";
import { connect } from "react-redux";
import * as actions from "../../Redux/actions.js"
import { ClaimStatusEnum } from "../../enum";
import find from 'lodash/find';
import { getPartnerGuid, hasAuthourity } from "../../../../../lib/access.es6";
import ClaimsHeader from "../../../../../containers/ClaimDashboardv2/Components/ClaimsHeader/ClaimsHeader";
import ClaimsWrapper from "../ClaimsWrapper/ClaimsWrapper.js"
import { partnerHasClaimTags } from "../../../../../lib/utils.es6.js";
import { getInitialStatus } from "../../helpers.js";


class ClaimDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  componentWillMount() {
    const isFinanceUser = hasAuthourity('BULK_PAY_APPROVE_CLAIMS');
    const payload = {
      "partner": getPartnerGuid(),
      "statuses": isFinanceUser ? ClaimStatusEnum.Approved : getInitialStatus(this.props.location.query.status)
    }
    if(partnerHasClaimTags()){
      payload['tag']='reimbursement'
      payload['page']=1
      payload['size']=10
    }
    this.props.dispatch(actions.getClaimsDcpRequest(payload))
  }

  getClaims(payload) {
    if(partnerHasClaimTags()){
      payload['tag'] ='reimbursement'
      payload['page'] = payload['page'] || 1
      payload['size'] = payload['size'] || 10
    }
    
    this.props.dispatch(actions.getClaimsDcpRequest(payload
    ))
  }

  handleBulkApproval(payload) {
    this.props.dispatch(actions.bulkApproveRequest(payload))
  }

  filterHospitalClaim(claims) {
    return claims.filter(claim => claim.preauthorization_number !== null)
  }

  isDeathClaim(claim) {
    const hasDeathBenefit = !!find(claim.benefits, { type: "DEATH BENEFIT" })
    const hasNoLou = claim.preauthorization_number === null;

    return hasDeathBenefit && hasNoLou
  }

  isReimbusermentClaim(claim) {
    const hasDeathBenefit = !!find(claim.benefits, { type: "DEATH BENEFIT" })
    const hasNoLou = claim.preauthorization_number === null;

    return !hasDeathBenefit && hasNoLou
  }

  filterDeathClaim(claims) {
    return claims.filter(claim => this.isDeathClaim(claim))
  }
  filterReimbursement(claims){
    return claims.filter(claim => this.isReimbusermentClaim(claim))
  }
  render() {
    const claims = this.props.bulkApprovalData.claims_dcp || { data: [], page: 1, size: 6, total: 0}

    if (!hasAuthourity('VIEW_CLAIM')) {
      return <p>You are unauthorized to view claims.</p>
    }

    return (
      <>
        <ClaimsHeader
          title="Reimbursement Claims Dashboard"
        />

        <ClaimsWrapper
          isFinanceUser={hasAuthourity('BULK_PAY_APPROVE_CLAIMS')}
          claimsData={claims.data}
          type="reimbursement"
          hasAlerts={true}
          hasBulkApprove={true}
          getClaims={this.getClaims.bind(this)}
          loading={this.props.bulkApprovalData.get_claims_dcp_loader}
          handleBulkApproval={this.handleBulkApproval.bind(this)}

          showBulkApproveProgressAlert={this.props.bulkApprovalData.bulkApprovalClaimLoader}
          showBulkApproveSuccessAlert={this.props.bulkApprovalData.bulkApprovalClaimSuccess}
          showBulkApproveErrorAlert={!!this.props.bulkApprovalData.bulkApprovalClaimError}
          bulkApproveError={this.props.bulkApprovalData.bulkApprovalClaimError}

          resetBulkApproveSuccessAlert={() => { this.props.dispatch(actions.resetbulkApprovalSuccess()) }}
          resetBulkApproveErrorAlert={() => { this.props.dispatch(actions.resetbulkApprovalFailure()) }}
          pagingData={{  data: claims.data, page: claims.page, size: claims.size, total: claims.total }}
          queryParamsStatus={this.props.location.query.status}
        />
      </>
    )

  }

}
export default connect((state) => ({
  customerData: state.currentCustomer,
  claimDashboardData: state.claimDashboardData,
  bulkApprovalData: state.bulkApprovalData,
  globalData: state.global,
}))(ClaimDashboard);