import React, { useState } from 'react'
import ModalInc from '../../../../../shared-ui/ModalInc'
import ButtonInc from '../../../../../shared-ui/ButtonInc'
import { TextValidator, SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
import MenuItem from '@mui/material/MenuItem';
import AlertDialog from '../../../../../components/AlertDialog/index.es6';
import { CircularProgress } from '@mui/material';
import { useStyle } from "./Style";
import { Grid } from '@mui/material'
import { loanTypesEnum } from '../../../lib/enum';

const AddDeathClaimExpense = (props) => {
  const classes = useStyle();

  const [expenseType, setExpenseType] = useState('')
  const [amountInCents, setAmountInCents] = useState('');
  const [amountPayableDisable, setAmountPayableDisable] = useState(true)




  // if (props.claim.claim_details.additional_details.loan_type === "Working Capital Loan") {

  //   const numberMonthsOfBetweenEventDateAndMaturityDate = (loanMaturityDateStr, eventDateStr) => {
  //     const loanMaturityDate = new Date(loanMaturityDateStr);
  //     const eventDate = new Date(eventDateStr);

  //     if (isNaN(loanMaturityDate) || isNaN(eventDate)) {
  //       console.error("Invalid date format");
  //       return null;
  //     }

  //     const yearsDiff = loanMaturityDate.getFullYear() - eventDate.getFullYear();
  //     const monthsDiff = loanMaturityDate.getMonth() - eventDate.getMonth();
  //     const totalMonthsDiff = yearsDiff * 12 + monthsDiff;

  //     return Math.abs(totalMonthsDiff);
  //   }


  //   const monthsDifference = numberMonthsOfBetweenEventDateAndMaturityDate(
  //     props.claim.claim_details.additional_details.loan_maturity_date,
  //     props.claim.event_date
  //   );




  //   const numberOfMonthsBetweenLoanDisbursementDateAndEventDate = (loanDisbursementDateStr, eventDateStr) => {
  //     const loanDisbursementDate = new Date(loanDisbursementDateStr);
  //     const eventDate = new Date(eventDateStr);

  //     if (isNaN(loanDisbursementDate) || isNaN(eventDate)) {
  //       console.error("Invalid date format");
  //       return null;
  //     }

  //     const yearsDiff = loanDisbursementDate.getFullYear() - eventDate.getFullYear();
  //     const monthsDiff = loanDisbursementDate.getMonth() - eventDate.getMonth();
  //     const totalMonthsDiff = yearsDiff * 12 + monthsDiff;

  //     return Math.abs(totalMonthsDiff);
  //   }

  //   const monthsPaid = numberOfMonthsBetweenLoanDisbursementDateAndEventDate(
  //     props.claim.claim_details.additional_details.loan_disbursement_date,
  //     props.claim.event_date
  //   );




  //   const loan_amount = parseFloat(props.claim.claim_details.additional_details.loan_amount);
  //   const monthly_interest_rate = parseFloat(props.claim.claim_details.additional_details.loan_interest_rate);
  //   const months_paid = parseInt(monthsPaid);  //  the number of months between loan_disbursement_date and event date
  //   const monthly_loan_installment = parseFloat(props.claim.claim_details.additional_details.monthly_installment_amount);

  //   console.log("detailscalc",
  //     {
  //       "loan_amount": loan_amount,
  //       "monthly_interest_rate": monthly_interest_rate,
  //       "months_paid": months_paid,
  //       "monthly_loan_installment": monthly_loan_installment
  //     }
  //   )

  //   // Calculate remaining balance
  //   const remaining_balance = loan_amount * Math.pow((1 + monthly_interest_rate), months_paid) -
  //     (monthly_loan_installment * (Math.pow((1 + monthly_interest_rate), months_paid) - 1) / monthly_interest_rate);


  //   console.log("loan_amount * loan_duration", loan_amount / props.claim.claim_details.additional_details.loan_duration)




  //   const MonthlyIntrest = (remaining_balance / monthsDifference) - (loan_amount / props.claim.claim_details.additional_details.loan_duration)

  //   console.log("MonthlyIntrest", MonthlyIntrest)


  //   const outStandingAmount = (loan_amount * monthsDifference) / props.claim.claim_details.additional_details.loan_duration

  //   console.log("outStandingAmount", outStandingAmount)


  //   const finalPayableAmount = MonthlyIntrest + outStandingAmount


  //   console.log("finalPayableAmount", finalPayableAmount)

  // } else {
  //   console.log("not working capital")
  // }


// Function to calculate the number of months between two dates
const numberMonthsOfBetweenEventDateAndMaturityDate = (loanMaturityDateStr, eventDateStr) => {
  const loanMaturityDate = new Date(loanMaturityDateStr);
  const eventDate = new Date(eventDateStr);

  if (isNaN(loanMaturityDate) || isNaN(eventDate)) {
    console.error("Invalid date format");
    return null;
  }

  const yearsDiff = loanMaturityDate.getFullYear() - eventDate.getFullYear();
  const monthsDiff = loanMaturityDate.getMonth() - eventDate.getMonth();
  const totalMonthsDiff = yearsDiff * 12 + monthsDiff;

  return Math.abs(totalMonthsDiff);
};

// Function to calculate the number of months between the loan disbursement date and the event date
const numberOfMonthsBetweenLoanDisbursementDateAndEventDate = (loanDisbursementDateStr, eventDateStr) => {
  const loanDisbursementDate = new Date(loanDisbursementDateStr);
  const eventDate = new Date(eventDateStr);

  if (isNaN(loanDisbursementDate) || isNaN(eventDate)) {
    console.error("Invalid date format");
    return null;
  }

  const yearsDiff = loanDisbursementDate.getFullYear() - eventDate.getFullYear();
  const monthsDiff = loanDisbursementDate.getMonth() - eventDate.getMonth();
  const totalMonthsDiff = yearsDiff * 12 + monthsDiff;

  return Math.abs(totalMonthsDiff);
};

// Initialize variables
let MonthlyIntrest, finalPayableAmount, outStandingAmount;


//on loan type use the product code 

const loanType = props.claim.claim_details.additional_details.product_code;


switch (loanType) {
  case loanTypesEnum.businessLoans: // businessLoans
  // if(props.claim.claim_details.relationship_to_policyholder === "Principal"){
  //    console.log("the principal")
  // }else{
  //   console.log(" not the principal")
  // }
    const monthsDifferenceWC = numberMonthsOfBetweenEventDateAndMaturityDate(
      props.claim.claim_details.additional_details.loan_maturity_date,
      props.claim.event_date
    );

    const monthsPaidWC = numberOfMonthsBetweenLoanDisbursementDateAndEventDate(
      props.claim.claim_details.additional_details.loan_disbursement_date,
      props.claim.event_date
    );
    if (props.claim.claim_details.relationship_to_policyholder === "Principal"){
      const loan_amountWC = parseFloat(props.claim.claim_details.additional_details.loan_amount);
      const monthly_interest_rateWC = parseFloat(props.claim.claim_details.additional_details.monthly_interest_rate) / 100;
      const monthly_loan_installmentWC = parseFloat(props.claim.claim_details.additional_details.monthly_installment_amount);
  
      console.log("loan_details", {
        loan_amountWC,
        monthly_interest_rateWC,
        monthly_loan_installmentWC
      })
  
      const remaining_balanceWC = loan_amountWC * Math.pow((1 + monthly_interest_rateWC), monthsPaidWC) -
        (monthly_loan_installmentWC * (Math.pow((1 + monthly_interest_rateWC), monthsPaidWC) - 1) / monthly_interest_rateWC);
  
      MonthlyIntrest = (remaining_balanceWC / monthsDifferenceWC) - (loan_amountWC / props.claim.claim_details.additional_details.loan_duration);
      outStandingAmount = (loan_amountWC * monthsDifferenceWC) / props.claim.claim_details.additional_details.loan_duration;
      finalPayableAmount = MonthlyIntrest + outStandingAmount;
  
      console.log("Working Capital Loan - finalPayableAmount:", finalPayableAmount);
    }else{
      finalPayableAmount =  props.claim.claim_details.additional_details.monthly_installment_amount

    }


    break;

  case  loanTypesEnum.MobileShortLoans: // MobileShortLoans
    const monthsDifferenceMSL = numberMonthsOfBetweenEventDateAndMaturityDate(
      props.claim.claim_details.additional_details.loan_maturity_date,
      props.claim.event_date
    );

    const monthsPaidMSL = numberOfMonthsBetweenLoanDisbursementDateAndEventDate(
      props.claim.claim_details.additional_details.loan_disbursement_date,
      props.claim.event_date
    );

    const loan_amountMSL = parseFloat(props.claim.claim_details.additional_details.loan_amount);
    const monthly_interest_rateMSL = parseFloat(props.claim.claim_details.additional_details.monthly_interest_rate) / 100;
    const monthly_loan_installmentMSL = parseFloat(props.claim.claim_details.additional_details.monthly_installment_amount);

    const remaining_balanceMSL = loan_amountMSL * Math.pow((1 + monthly_interest_rateMSL), monthsPaidMSL) -
      (monthly_loan_installmentMSL * (Math.pow((1 + monthly_interest_rateMSL), monthsPaidMSL) - 1) / monthly_interest_rateMSL);

    MonthlyIntrest = (remaining_balanceMSL / monthsDifferenceMSL) - (loan_amountMSL / props.claim.claim_details.additional_details.loan_duration);
    outStandingAmount = (loan_amountMSL * monthsDifferenceMSL) / props.claim.claim_details.additional_details.loan_duration;
    finalPayableAmount =  outStandingAmount;

    console.log("Mobile/Short Loans - finalPayableAmount:", finalPayableAmount);
    break;

  case  loanTypesEnum.StaffLoans: // StaffLoans
    const monthsDifferenceSRP = numberMonthsOfBetweenEventDateAndMaturityDate(
      props.claim.claim_details.additional_details.loan_maturity_date,
      props.claim.event_date
    );

    const monthsPaidSRP = numberOfMonthsBetweenLoanDisbursementDateAndEventDate(
      props.claim.claim_details.additional_details.loan_disbursement_date,
      props.claim.event_date
    );

    const loan_amountSRP = parseFloat(props.claim.claim_details.additional_details.loan_amount);
    const monthly_interest_rateSRP = parseFloat(props.claim.claim_details.additional_details.monthly_interest_rate) / 100;
    const monthly_loan_installmentSRP = parseFloat(props.claim.claim_details.additional_details.monthly_installment_amount);

    const remaining_balanceSRP = loan_amountSRP * Math.pow((1 + monthly_interest_rateSRP), monthsPaidSRP) -
      (monthly_loan_installmentSRP * (Math.pow((1 + monthly_interest_rateSRP), monthsPaidSRP) - 1) / monthly_interest_rateSRP);

    MonthlyIntrest = (remaining_balanceSRP / monthsDifferenceSRP) - (loan_amountSRP / props.claim.claim_details.additional_details.loan_duration);
    outStandingAmount = (loan_amountSRP * monthsDifferenceSRP) / props.claim.claim_details.additional_details.loan_duration;
    finalPayableAmount =  outStandingAmount;

    console.log("Staff Real Pesa - finalPayableAmount:", finalPayableAmount);
    break;

  default:
    console.log("Unknown loan type");
    break;
}





  const reset = () => {
    setAmountInCents('');
    setExpenseType('');
  }
  const handleClaimExpense = () => {
    const payload = {
      "benefit_type": expenseType,
      // "invoice_amount_in_cents": Number(invoice_amount_in_cents),
      "benefit_amount_in_cents": Math.floor(Number(amountInCents) * 100),
      "benefit_usage_count": 0,
      // "invoice_number": invoiceNumber,
      "external_unique_identifier": null,
      "claim_guid": props.claim.guid

    }
    props.handleClaimExpense(payload)
    console.log("Death payload", payload)
    reset()
  }
  const handleExpenseCreatedSuccessfully = () => {
    props.resetCreateExpenseSuccessAlert();
    props.toggleClaimExpenseDialog();
    window.location.reload();
  }
  const handleExpenseCreateError = () => {
    props.resetCreateExpenseErrorAlert();
  }



  const handleChange = (event) => {
    const { name, value } = event.target

    if (name === "amountInCents") {
      setAmountInCents(value)
    }
    if (name === "selectexpensetype") {
      setExpenseType(value);
      const selectedBenefit = props.claim.benefits.find(benefit => benefit.type === value);
      if (selectedBenefit) {
        if (selectedBenefit.type === "Death") {
          setAmountPayableDisable(false)
          setAmountInCents(  props.claim.claim_details.relationship_to_policyholder === "Principal" ?  finalPayableAmount : props.claim.claim_details.additional_details.monthly_installment_amount );
        } else {
          // setAmountInCents(selectedBenefit.max_benefit_amount_in_cents / 100);
          setAmountPayableDisable(true)
        }
      } else {
        setAmountInCents('');
      }
    }
  }
  return (
    <>
      <AlertDialog
        custom
        show={props.showCreateExpenseProgressAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2> Creating Claim Expense...</h2>
      </AlertDialog>

      <AlertDialog
        success
        show={props.showCreateExpenseSuccessAlert}
        size="sm"
        title={'Claim expense created'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handleExpenseCreatedSuccessfully() }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={true}
        cancelBtnText={'cancel'}
        confirmBtnCssClass
      >

      </AlertDialog>

      <AlertDialog
        show={props.showCreateExpenseErrorAlert}
        danger
        title={'Error creating claim expense'}
        onConfirm={() => handleExpenseCreateError()}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >

        {props.CreateExpenseError ? props.CreateExpenseError.message : ''}
      </AlertDialog>
      <ModalInc
        modalTitle="Input  Details"
        subTitle="Fill the below fields to add details"
        open={props.openClaimExpenseDialog}
        onClose={props.toggleClaimExpenseDialog}
        fullWidth

      >
        <ValidatorForm onSubmit={() => handleClaimExpense()} >
          <Grid container spacing={2} className={classes.editClaimDialog}>
            <Grid item xs={12} sm={12} md={12}>
              <SelectValidator
                labelId="select-expense-type"
                id="select-expense-type"
                value={expenseType}
                name='selectexpensetype'
                type="text"
                label="Select  Type"
                onChange={handleChange}
                variant="outlined"
                validators={['required']}
                errorMessages={['Please select type']}
                style={{ width: "100%" }}
                sx={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 300,
                  fontSize: "12px",
                  lineHeight: "16px",
                  letterSpacing: "0.005em",
                  color: "#000000",
                }}
              >

                {
                  props.claim.benefits.map((benefit, index) => (
                    <MenuItem
                      key={index}
                      sx={{
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        fontWeight: 300,
                        fontSize: "12px",
                        lineHeight: "16px",
                        letterSpacing: "0.005em",
                        color: "#000000",
                      }}
                      value={benefit.type}>{benefit.type}</MenuItem>
                  ))
                }

              </SelectValidator>
            </Grid>



            <Grid item xs={12} sm={12} md={12}>
              <TextValidator
                name='amountInCents'
                id="amountInCents"
                label="Amount Payable"
                disabled={amountPayableDisable}
                type="number"
                value={amountInCents}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                validators={['required']}
                errorMessages={['Please enter Amount']}
              />
            </Grid>

          </Grid>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={6} md={6}>

            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8} md={8}>
                  <ButtonInc
                    variant="contained"
                    color="primary"
                    size="large"
                    type='submit'
                    style={{ marginTop: "20px", float: "right" }}
                  >
                    Save details
                  </ButtonInc>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <ButtonInc
                    variant="contained"
                    color="primary"
                    size="large"
                    style={{ marginTop: "20px" }}
                    onClick={props.toggleClaimExpenseDialog}
                  >
                    Cancel
                  </ButtonInc>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ValidatorForm>
      </ModalInc>

    </>
  )
}

export default AddDeathClaimExpense