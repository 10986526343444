import React from 'react'
import { Grid } from "@mui/material";
import './MediaQueries.css'
import ResultsBar from '../ResultsBar/ResultsBar';
import ResultsCards from '../ResultsCards';
import SidebarFilter from '../SidebarFilter/SidebarFilter';
import PaginationResults from '../PaginationResults/PaginationResults';
const DesktopResults = (props) => {
  return (
    <div
      className='desktop'
    >
      <Grid container spacing={2}>
        <Grid xs={12} sm={12} md={12}>
          <ResultsBar
            resultsShowing={props.resultsShowing}
            query={props.query}
          />
        </Grid>

      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8} sm={8} style={{
          borderRight: "1px solid #ccc",
          marginTop: "20px",
        }}>
          <div style={{
            maxHeight: "700px",  // Adjust this value to fit 4 items
            overflowY: "auto",
          }}>
            < ResultsCards
              searchResults={props.searchResults}
              loader={props.loader}
            />

          </div>
        </Grid>
        <Grid item xs={12} md={4} sm={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} sm={12}>
              <SidebarFilter
                document_type={props.document_type}
                filterByDocType={props.filterByDocType}
                clearFilter={props.clearFilter}
              />
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <PaginationResults
                total_pages={props.total_pages}
                getResultsByPage={props.getResultsByPage}
                page={props.page}
              />
            </Grid>
          </Grid>

        </Grid>

      </Grid>
    </div>
  )
}

export default DesktopResults