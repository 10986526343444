import React from 'react'
import { hashHistory} from 'react-router';
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import TableInc from '../../../../../shared-ui/TableInc';
import ButtonInc from '../../../../../shared-ui/ButtonInc';
import { formatDateLocale } from '../../../../../lib/utils.es6';


const Claims = (props) => {
  const classes = useStyle();
  const customerClaims = props.customerClaimsDcp || [];


  return (
    <section >
      <Grid container spacing={2} >
        <Grid item xs={12} md={12} sm={12}>
          <TableInc
            columns={[
              { title: 'Claim Number', field: 'claim_number' },
              { title: 'Created At', field: 'created_at', render: rowData =>
                <>
                
                  {formatDateLocale(rowData.created_at) || "N/A"}
                
                </>
               },
              { title: 'Status', field: 'status' },
              { title: 'Message', field: 'system_message' },
              { title: 'Claim Type', field: 'type' },
              {
                title: 'action', field: 'claimaction', render: rowData => {
                  return <div className={classes.actionBtn}>
                    <ButtonInc variant="contained"  onClick={()=> hashHistory.push(`admin/claim-processing-v2/${rowData.guid}`)} >View Claim</ButtonInc>
                  </div>
                }
              },
            ]}

            data={
              customerClaims
            }
            options={{
              pageSize: customerClaims.length < 5
              ? customerClaims.length
              : 5,
              showTitle: false,
            }}

          />
        </Grid>
      </Grid>
    </section>



  )
}

export default Claims