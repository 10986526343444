import React from 'react'
import { useStyle } from "./Style";
import { Grid, Box, Button } from '@mui/material';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import SkeletonWrapper from '../../../../containers/NewProductList/components/SkeletonWrapper/SkeletonWrapper';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { formatDateLocale } from '../../../../lib/utils.es6';

const Claims = (props) => {
  const classes = useStyle();

 
  if (!props.claims) {
    return
  }
  const [expanded, setExpanded] = React.useState(false)



  const dataForDisplay = expanded ? props.claims : props.claims.slice(0, 2)

  if (props.claimsLoader) {
    return <SkeletonWrapper loading={props.claimsLoader} />
  }


  if (dataForDisplay.length > 0) {
    return (
      <section className={classes.paymentOuter}>
        {
          dataForDisplay.map((claim, index) => (
            <Grid key={index} container spacing={2} className={classes.policyWrapper}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Grid container spacing={2} className={classes.policyHeader}>
                      <Grid item xs={2} className={classes.policyIconWrapper}   >
                        <Box className={classes.policyIcons}>
                          <NoteAddOutlinedIcon fontSize="14px" sx={{ color: "#ffffff" }} />
                        </Box>
                      </Grid>
                      <Grid item xs={10} className={classes.policyHeaderContent}>
                      <h6>{claim.type  || "N/A" }</h6>
                        <p> Claim  Type</p>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4} className={classes.policyActiveStatusWrapper}>
                    <Box display="flex" justifyContent="flex-end">
                      <ul>
                        {/* <li className={classes.activePill}> <span>Active</span></li> */}
                        <li className={claim.open === true ? classes.activePill : classes.inactivePill}> <span>{claim.open === true ? `Open` : `Closed` }</span></li>

                      </ul>
                    </Box>
                  </Grid>
                  <div className={classes.policyHeaderBorder}></div>
                </Grid>
                <Grid container spacing={2} className={classes.policyContentWrapper}>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={6}>
                    <p>Received Time</p>
                    <h6> {formatDateLocale(claim.received_time) || "N/A"}</h6>
                  </Grid>
                  <Grid item xs={5}>
                    <p> Relation To Main Member </p>
                    <h6>{claim.relation_to_main_member || "N/A"}
                    </h6>
                  </Grid>
                  <div className={classes.policyHeaderBorder}></div>
                </Grid>
                <Grid container spacing={2} className={classes.policyContentWrapper}>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={6}>
                    <p> Full Name</p>
                    <h6>{ claim.customer_full_name|| "N/A"}</h6>
                  </Grid>
                  <Grid item xs={5}>
                    <p> Event Date </p>
                    <h6>{ claim.event_date  || "N/A"}</h6>
                  </Grid>
                  <div className={classes.policyHeaderBorder}></div>
                </Grid>
                <Grid container spacing={2} className={classes.policyContentWrapper}>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={6}>
                    <p>Status</p>
                    <h6>{ claim.status || "N/A"}</h6>
                  </Grid>
                  <Grid item xs={5}>
                    <p> Feedback </p>
                    <h6>{claim.feedback || "N/A"}</h6>
                  </Grid>
                  <div className={classes.policyHeaderBorder}></div>
                </Grid>
                <Grid container spacing={2} className={classes.policyContentWrapper}>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={6}>
                    <p>Created At</p>
                    <h6> {formatDateLocale(claim.created_at.$date) || "N/A"}</h6>
                  </Grid>

                </Grid>
              </Grid>

            </Grid>
          ))
        }

        <Grid container spacing={2} className={classes.showMoreBtn}>
          <Grid item xs={12} >
            <Button variant="outlined" endIcon={expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />} onClick={() => setExpanded(!expanded)}>
              {expanded ? `Show Less Claims` : `Show More Claims`}
            </Button>

          </Grid>
        </Grid>

      </section>
    )
  } else {
    return (<>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <h1 style={{
            color: "#000000",
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
            textAlign: "center",
            marginTop: "100px",
          }}> No claims found </h1>

        </Grid>
      </Grid>

    </>)
  }
}


export default Claims