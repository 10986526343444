import React from 'react'
import { useStyle } from "./Style";
import { Grid, Box, Button } from '@mui/material';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import SkeletonWrapper from '../../../../containers/NewProductList/components/SkeletonWrapper/SkeletonWrapper';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { formatDateLocale } from '../../../../lib/utils.es6';
import { getPartner } from '../../../../lib/access.es6';
const Payments = (props) => {
  const classes = useStyle();

  React.useEffect(() => {
    props.getPayments()
  }, [])
  if (!props.payments) {
    return
  }
  const [expanded, setExpanded] = React.useState(false)



  const dataForDisplay = expanded ? props.payments : props.payments.slice(0, 2)

  if (props.paymentsLoader) {
    return <SkeletonWrapper loading={props.paymentsLoader} />
  }

  const currencyCode = getPartner().country.currency_code

 
if (dataForDisplay.length > 0) {
  return (
    <section className={classes.paymentOuter}>
      {
        dataForDisplay.map((payment, index) => (
          <Grid key={index} container spacing={2} className={classes.policyWrapper}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Grid container spacing={2} className={classes.policyHeader}>
                    <Grid item xs={2} className={classes.policyIconWrapper}   >
                      <Box className={classes.policyIcons}>
                        <NoteAddOutlinedIcon fontSize="14px" sx={{ color: "#ffffff" }} />
                      </Box>
                    </Grid>
                    <Grid item xs={10} className={classes.policyHeaderContent}>
                      <h6>{payment.product_name || "N/A"}</h6>
                      <p> Product Name</p>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4} className={classes.policyActiveStatusWrapper}>
                  <Box display="flex" justifyContent="flex-end">
                    <ul>
                      {/* <li className={classes.activePill}> <span>Active</span></li> */}
                      <li className={payment.status === "Succeeded" ? classes.activePill : classes.inactivePill}> <span>{payment.status === "Succeeded" ? `${"Succeeded"}` : `${"Failed"}` }</span></li>

                    </ul>
                  </Box>
                </Grid>
                <div className={classes.policyHeaderBorder}></div>
              </Grid>
              <Grid container spacing={2} className={classes.policyContentWrapper}>
                <Grid item xs={1}></Grid>
                <Grid item xs={6}>
                  <p>Created At</p>
                  <h6> {formatDateLocale(payment.created_at) || "N/A"}</h6>
                </Grid>
                <Grid item xs={5}>
                  <p> Reference </p> 
                  <h6>{payment.reference || "N/A"}
                  </h6>
                </Grid>
                <div className={classes.policyHeaderBorder}></div>
              </Grid>
              <Grid container spacing={2} className={classes.policyContentWrapper}>
                <Grid item xs={1}></Grid>
                <Grid item xs={6}>
                  <p>Payment Failure Reason</p>
                  <h6>{ payment.payment_failure_reason|| "N/A"}</h6>
                </Grid>
                <Grid item xs={5}>
                  <p> Effected At</p>
                  <h6>{ formatDateLocale(payment.effected_at) || "N/A"}</h6>
                </Grid>
                <div className={classes.policyHeaderBorder}></div>
              </Grid>
              <Grid container spacing={2} className={classes.policyContentWrapper}>
                <Grid item xs={1}></Grid>
                <Grid item xs={6}>
                  <p>Policy Number</p>
                  <h6>{ payment.policy_number || "N/A"}</h6>
                </Grid>
                <Grid item xs={5}>
                  <p> Channel </p>
                  <h6>{payment.channel || "N/A"}</h6>
                </Grid>
                <div className={classes.policyHeaderBorder}></div>
              </Grid>
              <Grid container spacing={2} className={classes.policyContentWrapper}>
                <Grid item xs={1}></Grid>
                <Grid item xs={6}>
                  <p>Amount</p>
                  <h6>{currencyCode} {payment.amount_in_cents / 100 || "N/A"}</h6>
                </Grid>

              </Grid>
            </Grid>

          </Grid>
        ))
      }

      <Grid container spacing={2} className={classes.showMoreBtn}>
        <Grid item xs={12} >
          <Button variant="outlined"  endIcon={expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />} onClick={() => setExpanded(!expanded)}>
            {expanded ? `Show Less Payments` : `Show More Payments`}
          </Button>

        </Grid>
      </Grid>
       
    </section>
  )
} else {
  return (<>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        <h1 style={{
          color: "#000000",
          fontFamily: "Lato",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          textAlign: "center",
          marginTop: "100px",
        }}> No payments found </h1>

      </Grid>
    </Grid>

  </>)
}
}

export default Payments