import React from 'react'
import AddHospitalClaimExpense from './AddHospitalClaimExpense'
import AddDeathClaimExpense from './AddDeathClaimExpense';
import AddFuneralClaimExpense from './AddFuneralClaimExpense';
import AddDeviceDamageClaimExpense from './AddDeviceDamageClaimExpense';
import AddDisabilityClaimExpense from './AddDisabilityClaimExpense'
import AddDeviceTheftClaimExpense from './AddDeviceTheftClaimExpense'
import AddLastExpenseClaimExpense from './AddLastExpenseClaimExpense';
import AddPropertyClaimExpense from './AddPropertyClaimExpense'
const AddClaimExpense = (props) => {

  switch (true) {
    case props.hasDeathBenefit:
      return <AddDeathClaimExpense {...props} />;
    case props.hasHospitalBenefit:
      return <AddHospitalClaimExpense {...props} />;
    case props.hasFuneralBenefit:
      return <AddFuneralClaimExpense {...props} />;
    case props.hasDeviceDamageBenefit:
      return <AddDeviceDamageClaimExpense {...props} />;
    case props.hasDisabilityBenefit:
    case props.hasTemporaryDisabilityBenefit:
      return <AddDisabilityClaimExpense {...props} />;
    case props.hasDeviceTheftBenefit:
      return <AddDeviceTheftClaimExpense {...props} />;
    case props.hasLastExpenseBenefit:
      return <AddLastExpenseClaimExpense {...props} />;

    case props.hasPropertyBenefit:
      return <AddPropertyClaimExpense {...props} />;
    default:
      return <div>No applicable benefit type found</div>;
  }


}

export default AddClaimExpense