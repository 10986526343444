import { makeStyles } from "@material-ui/styles";


export const useStyle = makeStyles(() => ({

  paymentOuter:{
    padding: "5px 10px 30px 10px",
    marginTop: "25px !important",
    height: "auto"
  },
  policyWrapper: {
    background: "#FFFFFF",
    border: "1px solid #D9D9D9",
    boxShadow: "0px 8px 24px rgba(149, 157, 165, 0.2)",
    borderRadius: "4px",
    marginTop: "30px !important"
  },
  policyHeaderBorder: {
    borderBottom: "1px solid #D9D9D9",
    width: "100%"
  },
  policyIconWrapper: {
    padding: "10px",
  },
  policyIcons: {
    width: "30px",
    height: "30px",
    background: "#032a37",
    borderRadius: "100%",
    textAlign: "center",
    paddingTop: "5px",
    marginLeft: "auto"
  },
  policyHeaderContent: {
    paddingTop: "10px",
    "& h6": {
      fontFamily: 'Lato',
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "20px",
      letterSpacing: "0.001em",
      color: "#000000",
      margin: "0 !important"
    },
    "& p": {
      fontFamily: 'Open Sans',
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.005em",
      color: "#A9A9A9"
    }
  },
  activePill: {
    width: "63px",
    height: "21px",
    background: "#CCFFDD",
    borderRadius: "10px",
  },
  inactivePill:{
    width: "63px",
    height: "21px",
    background: "#DAE3E7",
    borderRadius: "10px",
    "& span":{
      color: "#8D8D8C !important"
    }
  },
  policyActiveStatusWrapper: {
    padding: "14px 30px",
    "& ul": {
      listStyle: "none",
      display: "contents",
      marginLeft: "20px",
      marginTop: "10px",
      "& li": {
        textAlign: "center",
        "& span": {
          display: "block",
          marginTop: 0,
          fontFamily: 'Open Sans',
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "10px",
          lineHeight: "20px",
          letterSpacing: "0.001em",
          color: "#2D8D4D",
        }
      }
    }
  },
  actionbtnpolicy:{
    marginTop: "-10px"
  },
  policyContentBorder:{
    borderBottom: "1px solid #D9D9D9",
    width: "100%",
    marginTop: "20px"
  },
  policyContentWrapper:{
    paddingTop: "20px",
    "& h6": {
      fontFamily: 'Lato',
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "20px",
      letterSpacing: "0.001em",
      color: "#000000",
      margin: "0 !important"
    },
    "& p": {
      fontFamily: 'Open Sans',
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.005em",
      color: "#A9A9A9"
    }
  },
  policyFooterWrapper:{
    paddingTop: "20px",
    paddingBottom: "20px",
    "& h6": {
      fontFamily: 'Lato',
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "20px",
      letterSpacing: "0.001em",
      color: "#000000",
      margin: "0 !important"
    },
    "& p": {
      fontFamily: 'Open Sans',
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.005em",
      color: "#A9A9A9"
    }
  },

  policyBalance:{
    textAlign: "end",
    marginRight: "40px",
    "& h6":{
      color: "#2D8D4D",
    }
  },
  showMoreBtn: {
    "& button": {
      background: "#FFFFFF",
      border: "1px solid #CCCCCC",
      borderRadius: "4px",
      fontFamily: 'Open Sans',
      fontStyle: "normal",
      fontWeight: 100,
      fontSize: "14px",
      lineHeight: "16px",
      letterSpacing: "0.005em",
      color: " #060606",
      margin: "20px auto",
      display: "flex",
      "&:hover":{
        border: "1px solid #CCCCCC",
      }
    }
  }
}));