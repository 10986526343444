import React, { useState } from 'react'
import { hashHistory } from 'react-router';
import Grid from "@material-ui/core/Grid";
import { useStyle } from "./Style";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Claims from '../CustomerClaimsTable/Claims';



const ClaimInitiationWrapper = (props) => {
  const steps =  ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5']

  const tabIndex = {
      selectPolicy: 0,
      selectMember: 1,
      AdditionalDetails: 2,
      ClaimLogging: 3,
      claimDetails: 4,
    }

  const classes = useStyle();
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
        // find the first step that has been completed
        steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  
  const getLabel =()=> {
    if(completedSteps() === totalSteps() - 1) return "All Done";

    if(activeStep === tabIndex.ClaimLogging) return 'Submit Claim ';
    return "Next";
  }

  const isDisabled =()=> {
    if(activeStep === tabIndex.selectPolicy && !props.eventQuote) return true
    if(activeStep === tabIndex.selectMember && !props.eventQuote) return true
    return false
  }

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);

    if(activeStep === tabIndex.ClaimLogging) {
      props.handleInitiateClaim();
      // return
    }

    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const getClaimComponents = () => {

    if (activeStep === tabIndex.selectPolicy) return {
      component: props.components.SelectPolicy
    }
    if (activeStep === tabIndex.selectMember) return {
      component: props.components.SelectMember
    }
    if (activeStep === tabIndex.ClaimLogging) return {
      component: props.components.ClaimLoggingDetails
    }

    if (activeStep === tabIndex.AdditionalDetails) return {
      component: props.components.AdditionalDetails
    }

    if (activeStep === tabIndex.claimDetails) return {
      component: props.components.ClaimDetails
    }
  }

  const renderClaimComponent = () => {
    const claimComponent = getClaimComponents();
    return <div>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <>
          {claimComponent.component}
        </>
      </Box>
    </div>
  }
  return (
    <>
      <Grid item xs={12} md={9} sm={9}>
        <section className={classes.ClaimInitiationWrapper}>
          <Box sx={{ width: '100%' }}>
            <div className={classes.stepperlabelwrapper}>
              <Stepper  activeStep={activeStep}>
                {steps.map((label, index) => (
                  <Step key={label} completed={completed[index]} sx={{
                    '& .MuiStepLabel-root .Mui-completed': {
                      color: '#75A85E', // circle color (COMPLETED)
                    },
                    '& .MuiStepLabel-root .Mui-active': {
                      color: '#032a37', // circle color (ACTIVE)
                    },
                    '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                      fill: '#fff', // circle's number (ACTIVE)
                    },
                  }}>
                    <StepButton color="inherit" onClick={handleStep(index)}>
                      {label}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
            </div>

            <div>
              {allStepsCompleted() ? (
                <>
                  <div className={classes.claimnavigationbtn} style={{ textAlign: 'center' }}>
                    <p >
                      What would you like to do next
                    </p>
                    <br/>
                    <br/>
                    <Button
                      variant="contained"
                      size="large"
                      onClick={handleReset}
                    >Generate Another Claim</Button>
                    <Button
                      variant="contained"
                      size="large"
                      onClick={()=> hashHistory.push(`admin/claim-processing-v2/${props.createdClaim.guid}`)}
                    // className={classes.viewquote}
                    >View Claim</Button>
                  </div>
                </>
              ) : (
                <>
                  <div className={classes.claimsinitiatewrapper}>

                    {renderClaimComponent()}
                  </div>
                  <div className={classes.claimnavigationbtn}>
                    <Button
                      disabled={true}
                      onClick={handleBack}
                      className={classes.backbtn}
                      variant="contained"
                      color="primary"
                      size="large"
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={handleComplete}
                      disabled={isDisabled()}
                    >
                      { getLabel()}
                    </Button>
                  </div>
                </>
              )}
            </div>


          </Box>
        </section>
        <section>
          <Claims customerClaimsDcp={props.customerClaimsDcp}/>
       
        </section>
      </Grid>
    </>
  )
}

export default ClaimInitiationWrapper