import React from "react";
import LocalizedStrings from 'react-localization';
import { connect } from "react-redux";
import { localisedText } from "../../lib/localisation.es6";
import { getElasticSearchRequest } from "./Redux/actions";
import { ThreeDots } from 'react-loader-spinner'
import DesktopResults from "./Components/DesktopResuts/DesktopResults";
import MobileResults from "./Components/MobileResults/MobileResults";

class ElasticSearch extends React.Component {

  constructor(props) {
    const localizedStrings = new LocalizedStrings(localisedText);
    super(props);
    this.state = {
      labels: localizedStrings,
      query: '',
      document_type: '',
      page: 0,
    };

  }


  componentDidMount() {
    this.parseQueryParams();
    window.addEventListener('hashchange', this.handleHashChange);
    this.updateLanguage();
  }

  componentWillUnmount() {
    window.removeEventListener('hashchange', this.handleHashChange);
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if query state has changed to avoid redundant dispatches
    if (prevState.query !== this.state.query || prevState.document_type !== this.state.document_type || prevState.page !== this.state.page) {
      this.getSearchResults();
    }

    this.updateLanguage();

  }

  getSearchResults() {
    this.props.dispatch(getElasticSearchRequest({
      query: this.state.query,
      size: "10",
      page: this.state.page,
      document_type: this.state.document_type
    }));
  }

  updateLanguage() {
    const language = this.props.globalData.language;
    let resetLanguage = false;
    if (!language) {
      resetLanguage = true;
    }
    const labels = this.state.labels;
    if (resetLanguage || labels.getLanguage() !== language) {
      labels.setLanguage(language);
      this.setState({});
    }
  }

  handleHashChange = () => {
    this.parseQueryParams();
  };

  parseQueryParams() {
    // Extract query parameters from the hash
    const hash = window.location.hash;
    const queryParams = new URLSearchParams(hash.split('?')[1]);
    const query = queryParams.get('q') || '';
    this.setState({ query });
  }


  filterByDocType = (document_type) => {
    this.setState({ document_type });
  };

  getResultsByPage = (page) => {
    this.setState({ page });
  }


  clearFilter = () => {
    this.setState({ document_type: '' }, () => {
      this.getSearchResults();
    });
  };
  render() {

    if (this.props.elasticSearchData.get_elastic_search_loader) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh"
          }}
        >
          <ThreeDots
            visible={true}
            height="80"
            width="80"
            color="#4fa94d"
            radius="9"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>

      )
    }
    if (this.props.elasticSearchData.elastic_search_results) {
      return (
        <>
          <DesktopResults
            resultsShowing={this.props.elasticSearchData.elastic_search_results.total}
            query={this.state.query}
            searchResults={this.props.elasticSearchData.elastic_search_results}
            loader={this.props.elasticSearchData.get_elastic_search_loader}
            document_type={this.state.document_type}
            filterByDocType={this.filterByDocType.bind(this)}
            clearFilter={this.clearFilter.bind(this)}
            total_pages={this.props.elasticSearchData.elastic_search_results.total_pages}
            getResultsByPage={this.getResultsByPage.bind(this)}
            page={this.state.page}

          />
          <MobileResults
            resultsShowing={this.props.elasticSearchData.elastic_search_results.total}
            query={this.state.query}
            filterByDocType={this.filterByDocType.bind(this)}
            document_type={this.state.document_type}
            searchResults={this.props.elasticSearchData.elastic_search_results}
            loader={this.props.elasticSearchData.get_elastic_search_loader}
            clearFilter={this.clearFilter.bind(this)}
            total_pages={this.props.elasticSearchData.elastic_search_results.total_pages}
            getResultsByPage={this.getResultsByPage.bind(this)}
            page={this.state.page}
          />
        </>
      )
    } else {
      return (
        <>No Results found</>
      )
    }



  }

}

export default connect((state) => ({
  globalData: state.global,
  elasticSearchData: state.elasticSearchData
}))(ElasticSearch);