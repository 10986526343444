
import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './SelectPlan.css'
import { white } from 'material-ui/styles/colors';
import { getPartner } from '../../../../../../lib/access.es6';
import { COMPONENT_TYPES, motsheloCoverPremiumsRates } from '../../../helpers/index';
import { hasSameProduct } from '../../../../lib/helpers';
import AlertDialog from '../../../../../../components/AlertDialog/index.es6';
import ButtonInc from '../../../../../../shared-ui/ButtonInc';
import { CircularProgress } from '@mui/material';
import { getCustomerPolicies } from '../../../../../../lib/requests.es6';
import { hashHistory } from 'react-router';

export default function SelectPlan({ setPlan, setCurrentComponent, customerGuid }) {
 const [selected, setSelected] = useState(null);
 const [showGettingActivePolicies, setShowGettingActivePolicies] = useState(false)
 const [showActivePolicyFoundErrorAlert, setShowActivePolicyFoundErrorAlert] = useState(false)
 const [showContent, setShowContent] = useState(false)



 const fetchActivePolicies = async () => {
   console.log('fetching active policies');
   
   setShowGettingActivePolicies(true)
   const response = await getCustomerPolicies({guid: customerGuid})
   const activePolicies = response.data.filter(policy => policy.active )
   if(activePolicies.length > 0 ){
     const newPolicy = { product_name: 'Motshelo' }
     for(let currentPolicy of activePolicies){
       if(hasSameProduct(currentPolicy, newPolicy)){
         setShowGettingActivePolicies(false)
         console.log('found duplicate active policy: ', currentPolicy.product_name, newPolicy.product_name);
         
         return setShowActivePolicyFoundErrorAlert(true)
     }
   }
   }
   
   setShowGettingActivePolicies(false)
   setShowContent(true)

   return response
 }

 useEffect(() => {
   fetchActivePolicies()

 }, [])

  const handleSelect = (value) => {
    setPlan(value);
    setSelected(value)
    setCurrentComponent(COMPONENT_TYPES.ADD_DEPENDANT)
  }


  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2
  };



  const currencyCode = getPartner().country.currency_code


  return (
    <div style={{ background: 'light grey', padding: '2%' }}>
      <AlertDialog
        show={showActivePolicyFoundErrorAlert}
        danger
        title={'Can not have two active Policies'}
        onConfirm={() => {}}
        confirmBtnText={'view active policy'}
        confirmBtnCssClass
        showCancel={false}
        showConfirm={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        <p>There is an activie policy for this same product,</p>
        <p>before approving this policy and making it active</p>
        <p>please cancel the previous active policy</p>
        <ButtonInc onClick={() => hashHistory.push('admin/customer_status/' + customerGuid)} >View active policy</ButtonInc>
      </AlertDialog>

      <AlertDialog
        custom
        show={showGettingActivePolicies}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress/>
        <h2>Checking for active policies of the same product type</h2>
      </AlertDialog>
        <h2 style={{
            fontFamily: 'Open Sans',
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "20px",
            lineHeight: "15px",
            textAlign: "center"
          }}>Select Motshelo Group Cover </h2>
      {
        showContent &&  <>
          <Slider {...settings}>
            {motsheloCoverPremiumsRates.map((cover, coverIndex) => {
              const firstPremiumValue = cover.premiums[0].premium_amount;

              return (

                <div key={coverIndex}>
                  <div
                    onClick={() => handleSelect(cover)}
                    style={{
                      color: (selected && selected.cover_amount === cover.cover_amount) ? white : '#bdbdbd',
                      fontSize: 20,
                      transition: 'all 0.3s ease-in-out',
                      cursor: 'pointer',
                      margin: '8%',
                      paddingBottom: "10px",
                      textAlign: 'center',
                      background: (selected && selected.cover_amount === cover.cover_amount) ? '#FBA92D' : '#032A37',
                      boxShadow: "0px 2px 8px rgba(99, 99, 99, 0.2)",
                      borderRadius: "8px",
                    }}
                  >
                    <div style={{
                      textTransform: 'uppercase',
                      fontFamily: 'Open Sans',
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "20px",
                      lineHeight: "15px",
                      color: "#FFFFFF",
                      padding: "10px"
                    }}>
                      <h3>{cover.option} </h3>
                      <h4 style={{ marginTop: '1%' }}> {currencyCode }{cover.cover_amount} </h4></div>
                    <hr style={{ margin: '0px' }} />
                    <div style={{
                      fontFamily: 'Open Sans',
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "25px",
                      color: "#FFFFFF",
                      padding: "8px"
                    }}>
                      premium starting from   
                      <h4>{currencyCode} {firstPremiumValue}</h4> </div>

                  </div>
                  <hr />
                </div>
              );
            })}
          </Slider>
          </>}
    </div>
  )
}
