import React from 'react'
import { useStyle } from "./Style";
import { Grid, Box, Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import SkeletonWrapper from '../../../../containers/NewProductList/components/SkeletonWrapper/SkeletonWrapper';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { formatDateLocale } from '../../../../lib/utils.es6';
import { getPartner } from '../../../../lib/access.es6';

const ITEM_HEIGHT = 48;

const Policies = (props) => {

 React.useEffect(() => {
    props.getPolicies()
  }, [])


  if (!props.policies) {
    return
  }
  const classes = useStyle();
  const [expanded, setExpanded] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dataForDisplay = expanded ? props.policies : props.policies.slice(0, 2)

  if (props.policiesLoader) {
    return <SkeletonWrapper loading={props.policiesLoader} />
  }

  const currencyCode = getPartner().country.currency_code



  if (dataForDisplay.length > 0) {
    return (
      <section className={classes.policyOuter}>
        {
          dataForDisplay.map((policy, index) => (
            <Grid key={index} container spacing={2} className={classes.policyWrapper}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Grid container spacing={2} className={classes.policyHeader}>
                      <Grid item xs={2} className={classes.policyIconWrapper}   >
                        <Box className={classes.policyIcons}>
                          <NoteAddOutlinedIcon fontSize="14px" sx={{ color: "#ffffff" }} />
                        </Box>
                      </Grid>
                      <Grid item xs={10} className={classes.policyHeaderContent}>
                        <h6>{policy.policy_number || "N/A"}</h6>
                        <p> Policy Number</p>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4} className={classes.policyActiveStatusWrapper}>
                    <Box display="flex" justifyContent="flex-end">
                      <ul>
                        {/* <li className={classes.activePill}> <span>Active</span></li> */}
                        <li className={policy.active === true ? classes.activePill : classes.inactivePill}> <span>{policy.active === true ? `Active` : `Inactive`}</span></li>
                        <li className={classes.actionbtnpolicy}>
                          <div>
                            <IconButton
                              aria-label="more"
                              id="long-button"
                              aria-controls={open ? 'long-menu' : undefined}
                              aria-expanded={open ? 'true' : undefined}
                              aria-haspopup="true"
                              onClick={handleClick}
                            >
                              <MoreVertIcon />
                            </IconButton>
                            <Menu
                              id="long-menu"
                              MenuListProps={{
                                'aria-labelledby': 'long-button',
                              }}
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleClose}
                              slotProps={{
                                paper: {
                                  style: {
                                    maxHeight: ITEM_HEIGHT * 4.5,
                                    width: '20ch',
                                  },
                                },
                              }}
                            >

                              <MenuItem style={{
                                cursor: "pointer",
                                fontFamily: "Open Sans",
                                fontStyle: "normal",
                                fontWeight: 400,
                                fontSize: "12px",
                                lineHeight: "15px",
                                color: "#000000",
                              }} onClick={handleClose}>
                                Download Policy Document
                              </MenuItem>
                              <MenuItem style={{
                                cursor: "pointer",
                                fontFamily: "Open Sans",
                                fontStyle: "normal",
                                fontWeight: 400,
                                fontSize: "12px",
                                lineHeight: "15px",
                                color: "#000000",
                              }} onClick={handleClose}>
                                Initiate Payment
                              </MenuItem>

                            </Menu>
                          </div>
                        </li>
                      </ul>
                    </Box>
                  </Grid>
                  <div className={classes.policyHeaderBorder}></div>
                </Grid>
                <Grid container spacing={2} className={classes.policyContentWrapper}>
                  <Grid item xs={1}></Grid>
                  {/* <Grid item xs={6}>
                    <p>Product Name</p>
                    <h6> {policy.product_name || "N/A"}</h6>
                  </Grid> */}
                  <Grid item xs={11}>
                    <p> Benefits </p>
                    <h6>{props.getBenefits(policy) || "N/A"}
                    </h6>
                  </Grid>
                  <div className={classes.policyHeaderBorder}></div>
                </Grid>
                <Grid container spacing={2} className={classes.policyContentWrapper}>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={6}>
                    <p>Start Date</p>
                    <h6> {formatDateLocale(policy.start_date) || "N/A"}</h6>
                  </Grid>
                  <Grid item xs={5}>
                    <p> Valid Until</p>
                    <h6>{policy.end_date || "N/A"}
                    </h6>
                  </Grid>
                  <div className={classes.policyHeaderBorder}></div>
                </Grid>
                <Grid container spacing={2} className={classes.policyContentWrapper}>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={6}>
                    <p>Status</p>
                    <h6> {policy.status || "N/A"}</h6>
                  </Grid>
                  <Grid item xs={5}>
                    <p> Created At</p>
                    <h6>{formatDateLocale(policy.created_at) || "N/A"}
                    </h6>
                  </Grid>
                  <div className={classes.policyHeaderBorder}></div>
                </Grid>
                <Grid container spacing={2} className={classes.policyContentWrapper}>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={6}>
                    <p>Balance</p>
                    <h6> {currencyCode} {policy.balance_in_cents || "N/A"}</h6>
                  </Grid>
                      <Grid item xs={5}>
                    <p>Product Name</p>
                    <h6> {policy.product_name || "N/A"}</h6>
                  </Grid>

                </Grid>
              </Grid>

            </Grid>
          ))
        }

        <Grid container spacing={2} className={classes.showMoreBtn}>
          <Grid item xs={12} >
            <Button variant="outlined" endIcon={expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />} onClick={() => setExpanded(!expanded)}>
              {expanded ? `Show Less Policies` : `Show More Policies`}
            </Button>

          </Grid>
        </Grid>
        {/* <Grid container spacing={2} className={classes.showMoreBtn}>
        <Grid item xs={12}>
          <Button variant="outlined" endIcon={<ExpandMoreOutlinedIcon />}>
            Show More
          </Button>
        </Grid>
      </Grid> */}
      </section>
    )
  } else {
    return (<>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <h1 style={{
            color: "#000000",
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
            textAlign: "center",
            marginTop: "100px",
          }}> No policies found please buy a policy</h1>

        </Grid>
      </Grid>

    </>)
  }


}

export default Policies