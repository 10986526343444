import React, { useState, useEffect, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid';
import { TextValidator, SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Grid from "@material-ui/core/Grid";
import { useStyle } from "./Style";
import MenuItem from '@mui/material/MenuItem';
import Button from "@mui/material/Button";
import { CircularProgress } from '@mui/material';
import find from 'lodash/find';
import { getLocalNumberLength, getPartner, isVAlidBotswanaNationalId } from '../../../../../../lib/access.es6';
import AlertDialog from '../../../../../../components/AlertDialog/index.es6';
import moment from 'moment';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import {  motsheloRelationships } from '../../../helpers';
import { getPolicyDependents } from '../../../../../../lib/requests.es6';

const Adddependant = (props) => {
  const classes = useStyle();
  const [firstname, setFirstname] = useState('');
  const [middlename, setMiddleName] = useState('');
  const [lastname, setLastname] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [phonenumber, setPhoneNumber] = useState('');
  const [nationalid, setNationalId] = useState('');
  const [passport, setPassport] = useState('');
  const [gender, setGender] = useState('');
  const [postalAddress, setpostalAddress] = useState('');
  const [residentialAddress, setresidentialAddress] = useState('');
  const [relationship, setRelationship] = useState(props.currentRelationShip);
  const [southAfricanIdCheckError, setSouthAfricanIdCheckError] = useState(null)
  const [isCitizenCheck, setIsCitizenCheck] = useState(false)

  const [duplicateMembers, setDuplicateMembers] = useState(null)
  const [checkingDuplicateMembers, setCheckingDuplicateMembers] = useState(false)
  const [duplicateMemberFound, setDuplicateMemberFound] = useState(false)
  const [memberAdded, setMemberAdded] = useState(false)

  const [ageRanges, setAgeRanges] = useState(null)
  const [, setExtendedMemberPremiumNotFound] = useState(false)


  const [ageAttained, setAgeAttained] = useState('')
  const [NominatedBeneficiary, setNominetedBeneficiary] = useState('');


  const formRef = useRef(null);

  const fetchDuplicateMembers = async (national_id) => {
    if (!national_id || props.suspendDuplicateIdCheck) {
      addDependant()
      return
    }
    setDuplicateMemberFound(false)
    setCheckingDuplicateMembers(true);
    try {
      const duplicate = find(props.dependants, { national_id: nationalid })
      if (duplicate) {
        setDuplicateMembers({ active: true, local: true, duplicate });
        setDuplicateMemberFound(true)
        setCheckingDuplicateMembers(false);
        return
      }

      const response = await getPolicyDependents(national_id);
      setDuplicateMembers({ ...response.data, local: false });

      if (response.data.active) {
        setDuplicateMemberFound(true)
      } else {
        setDuplicateMemberFound(false)
        addDependant()
      }
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
    setCheckingDuplicateMembers(false);
  };





  useEffect(() => {

    ValidatorForm.addValidationRule('isValidPhoneNumber', value => {
      return value.length === getLocalNumberLength();
    });

    return function cleanup() {
      ValidatorForm.removeValidationRule('isValidPhoneNumber');
    };
  }, [])


  useEffect(() => {
    ValidatorForm.addValidationRule('isValidNationalId', (value) => {
      if (['Child', 'Parent', 'Parent_In_Law', 'Extended'].includes(relationship) && value === '') {
        return true;
      }

      if (props.memberRelationshipType === 'extended_members' && value === '') {
        return true
      }



      const result = isVAlidBotswanaNationalId(value)
      if (result.isValid) {
        setGender(result.gender)
        setSouthAfricanIdCheckError(result.error)
        return true
      } else {
        setSouthAfricanIdCheckError(result.error)
        setGender('')
        return false
      }

    });

    ValidatorForm.addValidationRule('isNationalIdRequired', (value) => {
      if (relationship === 'Child' && value === '') {
        return true;
      }
      return false;
    });




    ValidatorForm.addValidationRule('isValidDateOfBirth', (dobValue) => {
      if (!dateOfBirth) return true;
      const maximum_age = 75
      const minimum_age = 18

      const selectedDateObj = new Date(dobValue);
      const currentDate = new Date();

      const ageDifference = currentDate.getFullYear() - selectedDateObj.getFullYear();

      setAgeRanges({ minimum_age, maximum_age })
      return ageDifference >= minimum_age && ageDifference <= maximum_age

    });

    ValidatorForm.addValidationRule('hasMaximumMemberCount', (relationship) => {
      const memberConfig = props.getMemberConfig(relationship)
      if (!memberConfig) return true

      const maximumMembersAllowed = memberConfig.max_count
      const totalMemberTypeAdded = props.countRelationships(relationship)

      if (totalMemberTypeAdded === maximumMembersAllowed) return false
      return true
    });


    ValidatorForm.addValidationRule('shouldAddPrincipalFirst', (value) => {
      const principalNotAdded = !find(props.dependants, { relationship: "Principal" })
      if (value !== "Principal" && principalNotAdded) {
        return false
      } else {
        return true
      }

    });


    ValidatorForm.addValidationRule('hasDuplicatePrincipal', (value) => {
      const principalAdded = !!find(props.dependants, { relationship: "Principal" })
      if (value === "Principal" && principalAdded) {
        return false
      } else {
        return true
      }

    });

    ValidatorForm.addValidationRule('hasDuplicateAlternateMember', (value) => {
      const alternateMemberAdded = !!find(props.dependants, { relationship: "Alternate_Member" })
      if (value === "Alternate_Member" && alternateMemberAdded) {
        return false
      } else {
        return true
      }

    });

    return function cleanup() {
      ValidatorForm.removeValidationRule('isValidDateOfBirth');
      ValidatorForm.removeValidationRule('isValidNationalId');
      ValidatorForm.removeValidationRule('isNationalIdRequired');
      ValidatorForm.removeValidationRule('shouldAddPrincipalFirst');
      ValidatorForm.removeValidationRule('hasMaximumMemberCount');
      ValidatorForm.removeValidationRule('hasDuplicatePrincipal');
      ValidatorForm.removeValidationRule('hasDuplicateAlternateMember');
    };

  }, [relationship, dateOfBirth])


  useEffect(() => {

    const startingIndex = internationalCallingCode.length
    const endingIndex = props.principal.msisdn.length
    const phone = props.principal.msisdn.substring(startingIndex, endingIndex)

    if (props.autoFillPrincipal) {
      setFirstname(props.principal.first_name);
      setMiddleName('');
      setLastname(props.principal.surname);
      setDateOfBirth(props.principal.date_of_birth);
      setPhoneNumber(phone);
      setNationalId(props.principal.national_id);
      setGender(props.principal.gender);
      setpostalAddress('');
      setresidentialAddress(props.principal.address || '');
       setRelationship('Principal');
      props.toggleAutoFillPrincipal()
    }
  }, [props.autoFillPrincipal]);


  useEffect(() => {
    const getAgeAttained = getAge(dateOfBirth)
    setAgeAttained(getAgeAttained)
  }, [dateOfBirth])
  






  const handleIsCitizenChange = (event) => {
    setIsCitizenCheck(event.target.checked);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'firstname') {
      setFirstname(value)
    }
    if (name === 'middlename') {
      setMiddleName(value)
    }
    if (name === 'lastname') {
      setLastname(value)
    }
    if (name === 'dateOfBirth') {
      setDateOfBirth(value)
      setExtendedMemberPremiumNotFound(false)
      const getAgeAttained = getAge(value)
      setAgeAttained(getAgeAttained)
      console.log("age", getAgeAttained)

    }
    if (name === 'phonenumber') {
      setPhoneNumber(value)
    }
    if (name === 'nationalid') {
      setNationalId(value)
    }
    if (name === 'gender') {
      setGender(value)
    }
    if (name === 'postalAddress') {
      setpostalAddress(value)
    }
    if (name === 'residentialAddress') {
      setresidentialAddress(value)
    }
    if (name === 'relationships') {
      setRelationship(value)
    }
    if (name === 'passport') {
      setPassport(value)
    }

    if (name === 'ageAttained') {
      setAgeAttained(value)
    }
    if (name === 'NominatedBeneficiary') {
      setNominetedBeneficiary(value)
    }

  };
  const reset = () => {
    setFirstname('');
    setMiddleName('');
    setLastname('');
    setDateOfBirth('');
    setPhoneNumber('');
    setNationalId('');
    setGender('');
    setpostalAddress('');
    setresidentialAddress('');
    setRelationship('');
    props.setLastSelectedExtendedRelationship(null)
  }

  const getAge = (dateOfBirth) => {
    if (!dateOfBirth) return ""
    const age = moment().diff(dateOfBirth, 'years');
    return age;
  }



  const addDependant = () => {

    const internationalCallingCode = getPartner().country.international_calling_code


    const extended_uuid = uuidv4();
    const extended_uuid_merapelo = uuidv4();

   const coverAmount =  props.selectedPlan ? props.selectedPlan.cover_amount : null
    const payload = {
      "first_name": firstname,
      "middle_name": middlename,
      "last_name": lastname,
      "msisdn": internationalCallingCode + phonenumber,
      "date_of_birth": dateOfBirth,
      "gender": gender,
      "national_id": nationalid || "N/A",
      "passport": passport || "N/A",
      "postal_address": postalAddress,
      "relationship": relationship,
      "residential_address": residentialAddress,
      "benefits": [
        {
          type: "MOTSHELO BENEFIT",
          cover_amount_in_cents: coverAmount * 100,
          waiting_period_in_days: 30,
          cover_usage_count: 0,
          benefit_period_cardinality: 1,
          benefit_period_granuality: "year",
          premium_amount: 0,
          uuid: extended_uuid_merapelo,
          is_initialized: true,
          additional_details: {
            uuid: extended_uuid_merapelo,
          }

        },
      ],
      uuid: extended_uuid,
      additional_details: {
        uuid: extended_uuid,
        ageAttained: ageAttained,
        NominatedBeneficiary: NominatedBeneficiary
      }

    }


    const tempDependants = props.dependants;
    tempDependants.push(payload);
    props.setDependants(tempDependants);
    setMemberAdded(true)
    reset()



  }


  const handleDependantCreatedSuccessfully = () => {
    setMemberAdded(false)
  }

  //country calling code
  const internationalCallingCode = getPartner().country.international_calling_code

  const minimum_age = ageRanges ? ageRanges.minimum_age : ''
  const maximum_age = ageRanges ? ageRanges.maximum_age : ''
  const dobErrorMessage = `Age ranges for the selected relationship are:  ${minimum_age} to ${maximum_age}, current age is ${getAge(dateOfBirth)} years`


  return (
    <>

      <AlertDialog
        custom
        show={checkingDuplicateMembers}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2> Checking for duplicate member with this ID....</h2>
      </AlertDialog>


      <AlertDialog
        show={duplicateMemberFound}
        danger
        title={'member is already registered'}
        onConfirm={() => setDuplicateMemberFound(false)}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >

        {
          duplicateMembers && duplicateMembers.local &&
          <>
            <p>We found another member with same ID {nationalid} on this quotation.</p>
            <Button onClick={() => {
              props.viewMembers()
              setDuplicateMembers(null)
              setDuplicateMemberFound(false)
            }}> view members</Button>
          </>


        }
        {
          duplicateMembers &&
          duplicateMembers.policy &&
          duplicateMembers.policy.policy_number && <p>We found another member with same ID {nationalid} on an existing policy {duplicateMembers.policy.policy_number}</p>
        }

      </AlertDialog>

      <AlertDialog
        success
        show={memberAdded}
        size="sm"
        title={'member added'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handleDependantCreatedSuccessfully() }}
        onCancel={() => { handleDependantCreatedSuccessfully() }}
        confirmBtnText={'Ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        confirmBtnCssClass
      >
        <div >
          <Button variant="contained" onClick={() => {
            handleDependantCreatedSuccessfully()
            props.viewMembers()
            setDuplicateMembers(null)
            setDuplicateMemberFound(false)
          }} style={{ background: 'green', }}>view members</Button>
        </div>
      </AlertDialog>

      <AlertDialog
        info
        show={props.depAlert}
        size="sm"
        title={'member  removed'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { props.setDepAlert(false) }}
        confirmBtnText={'ok'}
        showCancel={false}
        cancelBtnText={'cancel'}
        confirmBtnCssClass
      >
      </AlertDialog>

      <ValidatorForm onSubmit={() => fetchDuplicateMembers(nationalid)} ref={formRef} >
        <section className={classes.quoteinputwrapper}>

          <Grid container spacing={2} >
            <Grid item xs={12} sm={12} md={12}>
              <div className={classes.detailstitle}>
                {
                  props.currentRelationShip === 'Principal'
                    ? <h3>Lets add Main member first</h3>
                    : <h3>Add a {props.currentRelationShip} member</h3>

                }
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <div className={classes.checkconditionCitizenship}>
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    checked={isCitizenCheck}
                    onChange={handleIsCitizenChange}
                    control={<Checkbox size="small" />}
                    label="Is Non Citizen?"
                    labelPlacement="start"
                  />
                </FormGroup>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={4} md={4}>
              <TextValidator
                id="First-Name"
                fullWidth
                label="First Name"
                name='firstname'
                value={firstname}
                onChange={handleChange}
                className={classes.quoteinputs}
                variant="outlined"
                validators={['required']}
                errorMessages={['Please enter first name']}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <TextValidator
                id="Middle-Name"
                fullWidth
                label="Middle Name"
                name='middlename'
                value={middlename}
                onChange={handleChange}
                className={classes.quoteinputs}
                variant="outlined"
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <TextValidator
                id="Last-Name"
                fullWidth
                label="Last Name"
                name='lastname'
                value={lastname}
                onChange={handleChange}
                className={classes.quoteinputs}
                variant="outlined"
                validators={['required']}
                errorMessages={['Please enter Last name']}
                style={{ width: "100%" }}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              {
                isCitizenCheck ? (<>

                  <TextValidator
                    name='passport'
                    id="passport"
                    label="Passport No"
                    value={passport}
                    onChange={handleChange}
                    fullWidth
                    variant="outlined"
                    validators={['required']}
                    errorMessages={['Please enter a valid passport number']}
                    style={{ width: "100%" }}
                  />
                </>) : (<>

                  <TextValidator
                    id="National Id"
                    fullWidth
                    type='number'
                    label="Omang Number"
                    name='nationalid'
                    value={nationalid}
                    onChange={handleChange}
                    className={classes.quoteinputs}
                    variant="outlined"
                    validators={
                      relationship === 'Child'
                        ? ['isValidNationalId']
                        : props.memberRelationshipType === 'parent_members'
                          ? []
                          : props.memberRelationshipType === 'extended_members'
                            ? []
                            : ['required', 'isValidNationalId']
                    }

                    errorMessages={['Please enter a valid national id', southAfricanIdCheckError ? southAfricanIdCheckError : 'Invalid national Id']}
                    style={{ width: "100%" }}
                  />
                </>)
              }

            </Grid>

            <Grid item xs={12} sm={4} md={4}>

              <SelectValidator
                labelId="select-gender"
                id="select-gender"
                value={gender}
                name='gender'
                type="text"
                label="Gender"
                onChange={handleChange}
                variant="outlined"
                validators={['required']}
                errorMessages={['Please select gender']}
                style={{ width: "100%" }}
                sx={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 300,
                  fontSize: "12px",
                  lineHeight: "16px",
                  letterSpacing: "0.005em",
                  color: "#000000",
                }}
              >
                <MenuItem sx={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 300,
                  fontSize: "12px",
                  lineHeight: "16px",
                  letterSpacing: "0.005em",
                  color: "#000000",
                }} value="Male">Male</MenuItem>
                <MenuItem sx={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 300,
                  fontSize: "12px",
                  lineHeight: "16px",
                  letterSpacing: "0.005em",
                  color: "#000000",
                }} value="Female">Female</MenuItem>

              </SelectValidator>
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <TextValidator
                id="Date-of-birth"
                label="Date of birth"
                name='dateOfBirth'
                value={dateOfBirth}
                onChange={handleChange}
                fullWidth
                type='date'
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                validators={["required", "isValidDateOfBirth"]}
                errorMessages={["Date of birth is required", dobErrorMessage]}
                style={{ width: "100%" }}
              />


            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <TextValidator
                id="ageAttained"
                fullWidth
                disabled
                label="Age Attained"
                name='ageAttained'
                value={ageAttained}
                onChange={handleChange}
                className={classes.quoteinputs}
                variant="outlined"
              
                style={{ width: "100%" }}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <TextValidator
                id="NominatedBeneficiary"
                fullWidth
                label="Nominated Beneficiary"
                name='NominatedBeneficiary'
                value={NominatedBeneficiary}
                onChange={handleChange}
                className={classes.quoteinputs}
                variant="outlined"
                validators={['required']}
                errorMessages={['Please enter nominated beneficiary ']}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <SelectValidator
                labelId="select-relationships"
                id="select-relationships"
                value={relationship}
                name='relationships'
                type="text"
                label="Relationship"
                onChange={handleChange}
                variant="outlined"
                validators={['required','hasDuplicatePrincipal','hasDuplicateAlternateMember']}
                errorMessages={['Please select relationship','Principal already added. Not allowed to add two principals','Alternate member already added. Not allowed to add two alternate members']}
                style={{ width: "100%" }}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                {

                  ( gender === 'Male' 
                    ? motsheloRelationships.filter(relationship => relationship.gender !== 'Female')
                    : gender === 'Female' ? motsheloRelationships.filter(relationship => relationship.gender !== 'Male')
                    : motsheloRelationships).map((clanrelationship, index) => (
                    <MenuItem
                      key={index}
                      sx={{
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        fontWeight: 300,
                        fontSize: "12px",
                        lineHeight: "16px",
                        letterSpacing: "0.005em",
                        color: "#000000",
                      }} value={clanrelationship.value}>{clanrelationship.value}</MenuItem>
                  ))
                }

              </SelectValidator>
            </Grid>

          </Grid>
        </section>


        <section className={classes.benefitswrapper}>



          <hr />


          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <div className={classes.quotesbtns}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  type='submit'
                //onClick={addDependant}
                >
                  Add Member
                </Button>

                <Button variant="contained" onClick={() => {
                  props.viewMembers()
                }} style={{ background: 'green', }}>Cancel</Button>

              </div>
            </Grid>
          </Grid>
        </section>
      </ValidatorForm >
    </>
  )
}

export default Adddependant