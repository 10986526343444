import { makeStyles } from "@material-ui/styles";

export const useStyle = makeStyles(() => ({

  totalResults: {
  
      fontFamily: 'Lato',
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "24px",
      letterSpacing: "0.0015em",
      color: "#000;"
    
  },
  filterWrapper:{
    display: "flex",
    listStyle: "none",
    float: "inline-end",
    paddingTop: "8px",
    "& li": {
      marginLeft: "3px",
      fontFamily: 'Lato',
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "24px",
      letterSpacing: "0.0015em",
    }
  },
  filterOption:{
    padding: "15px",
    borderRadius: "10px",
    background: "#FFFFFF",

    "& span": {
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "12px",
      color: "#000000",
    }, 
  },
  searchWrapper: {
    height: "auto",
    background: "#FAFAF9;",
    boxShadow: " 0px 1px 3px rgba(0, 0, 0, 0.02), 0px 0px 0px 1px rgba(27, 31, 35, 0.15);",
    borderRadius: "5px",
    marginTop: "40px",
    "& h2": {
      fontFamily: 'Open Sans',
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
      marginLeft: "15px",
      lineHeight: "22px",
      letterSpacing: "0.001em",
      color: "#000000"
    },
    "& button": {
      width: "auto",
      height: "auto",
      marginTop: "6px",
      padding: "5px",
      background: "#FFFFFF",
      border: "1px solid #CCCCCC",
      borderRadius: "4px",
      fontFamily: 'Open Sans',
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "15px",
      color: "#383838"
    },
    "& h3": {
      fontFamily: 'Open Sans',
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.005em",
      color: "#C1C1C1",
      marginLeft: "15px",
    },
    "& p":{
      fontFamily: 'Open Sans',
      fontStyle: "normal",
      fontWeight: 100,
      fontSize: "12px",
      lineHeight: "22px",
      letterSpacing: "0.001em",
      color: "#000000",
      marginLeft: "15px",
      wordBreak: "break-all"
    }
  }

}));